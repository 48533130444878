import { Layout } from 'antd';
import NavBar from "../../components/NavBar";
import BranchItemDetail from "../../components/branch/BranchItemDetail";

const { Content } = Layout;

function BranchItem() {
   return (
      <Layout className="container main-layout">
         <NavBar />
         <Layout className="bg-gray">  
            <Content className="layout-content">
               <BranchItemDetail/>
            </Content>
         </Layout>
      </Layout>
   );
}

export default BranchItem;
