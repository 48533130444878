import { Layout } from "antd";
import AddBranchCard from "../../components/branch/AddBranchCard";
const { Content } = Layout;

function AddBranch(props) {
  return (
    <Layout className="container">
      <Content>
        <AddBranchCard />
      </Content>
    </Layout>
  );
}

export default AddBranch;
