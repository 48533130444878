import { Layout } from "antd";
import AddCustomerCheck from "../../components/customer/AddCustomerCheck";

const { Content } = Layout;

function AddCustomer(props) {
  return (
    <Layout className="container main-layout">
      <Content className="layout-content">
        <AddCustomerCheck />
      </Content>
    </Layout>
  );
}

export default AddCustomer;
