import React, { useState, useContext, useEffect } from "react";
import { Form, Input, Button, Checkbox } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { StoreContext } from "../../store"
import { requestRegisterDetail } from "../../actions"

export default function RegisterCheckCard({registerId}) {
  const { state: { userRegister: { newRegister } }, dispatch } = useContext(StoreContext);
    const [form] = Form.useForm();
    const history = useHistory();
    const Register = () => {
      history.push("/register");
    };
    const RegisterSend = () => {
      history.push("/registerfeedback");
    };

    useEffect(() => {
      requestRegisterDetail(dispatch, registerId)
    }, [registerId])
    
  return (
    <div className="main-layout">
      <div className="addcustomer-wrapper">
        <img src="/images/ad_2_img.png" className="ad_1"/>
        <img src="/images/back_btn.png" className="back_btn" onClick={Register}/>
      </div>
      <div className="addcustomer-form">
        <div className="nexmowlogo">
          <img src="/images/brand_logo_img.png" />
        </div>
        <p className="customerckeck-title">Welcome to be our partner!</p> 
        <div className="customerckeck">
            <p className="ckeckform-title">Please check again, before submit.</p>
            <p className="ckeckform-detail">Company name:  {newRegister.register_name}</p> 
            <p className="ckeckform-detail">Email address:  {newRegister.register_email}</p> 
            <p className="ckeckform-detail">Phone number:  {newRegister.register_phone}</p> 
            <p className="ckeckform-detail">Zip code:  {newRegister.register_zip}</p> 
            <p className="ckeckform-detail">Address:  {newRegister.register_address}</p>  
        </div>
        <Button
            htmlType="submit"
            className="customersent_btn"
            onClick={RegisterSend}
        >
            Submitted
        </Button>
      </div>
    </div>
  );
};