import React, { useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Text, Row, Col } from 'antd';
import { StoreContext } from "../../store"
import AccountItem from "../account/AccountItem";
import firebase from 'firebase/app'

const AccountDetail = () => {
  const { state:{ userSignin: { userInfo, loading, error, remember } }, dispatch } = useContext(StoreContext);
  const [dataToShow, setData] = useState([]);
  
  const [g_accounts, setGaccounts] = useState([]);
  const [order, setOrder] = useState("AESC");

  const history = useHistory();
  const AddAccount = () => {
    history.push("/addaccount");
  };
  function refreshPage() {
    window.location.reload(false);
  }
  const account = [];
  const searchFilterFunction = (text) => {
    const newData = account.filter(account => {
        return account.name.indexOf(text) > -1;
    });

    this.setState({ data: newData });
  }
  useEffect(() => {
    console.log("userinfo", userInfo);
    const docRef = firebase.firestore().collection('users').where("company", "==", userInfo[1].claims.company)
      docRef.get().then((querySnapshot) => {
        const account = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            //account.push({company : doc.data().company, name : (doc.data().name)?doc.data().name:"", address: doc.data().address, 
            //  phone: doc.data().phone, job: doc.data().job, id : doc.id, email: doc.data().email, address_zip: doc.data().address_zip, admin: doc.data().admin})
        
            let company =  (doc.data().company)?doc.data().company:"";
            let name = (doc.data().name)?doc.data().name:"";
            let address = (doc.data().address)?doc.data().address:"";
            let phone = (doc.data().phone)?doc.data().phone:"";
            let job = (doc.data().job)?doc.data().job:"";
            let id = doc.id;
            let email = (doc.data().email)?doc.data().email:"";
            let address_zip = (doc.data().address_zip)?doc.data().address_zip:"";
            let admin = (doc.data().admin)?doc.data().admin:false;
            account.push({company: company, name: name, address: address, phone: phone, job: job, id: id, email: email, address_zip: address_zip, admin: admin});
            
        });

        setGaccounts(JSON.parse(JSON.stringify(account)));
        var sorted_account = sort_accounts(account, order);

        setData(sorted_account);
        //return doc.data()
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });
    }, []);

    
  function search() {
    var input = document.getElementById("search_field").value;
    var accounts = JSON.parse(JSON.stringify(g_accounts));
    var a = accounts.filter(i => i.name.includes(input) || i.company.includes(input) || i.email.includes(input)|| i.address.includes(input)|| i.phone.includes(input)|| i.job.includes(input));
    var sorted_account = sort_accounts(a, order);
    setData(sorted_account);
  }

  function sort_accounts(accounts, order){
    function desc( a, b ) {
      if ( a.name.toUpperCase() < b.name.toUpperCase() ){
        return 1;
      }
      if ( a.name.toUpperCase() > b.name.toUpperCase() ){
        return -1;
      }
      return 0;
    }
    function aesc( a, b ) {
        if ( a.name.toUpperCase() < b.name.toUpperCase() ){
          return -1;
        }
        if ( a.name.toUpperCase() > b.name.toUpperCase() ){
          return 1;
        }
        return 0;
      }
    if(order == "AESC"){
      accounts.sort(aesc)
    }else{
      accounts.sort(desc)
    }
    return accounts;
  }

  function sort() {
    var or = (order == "DESC"? "AESC":"DESC");
    setOrder(or);
    var accounts = sort_accounts(dataToShow, or);
    setData(accounts);
    //return doc.data()
  }

    return (
      <div className="layout_limit">
        <div className="mainform-wrapper">
          <p className="main-title">Account Management({g_accounts.length})</p> 
            <Button
              htmlType="submit"className="addcustomer__button" onClick={AddAccount}
            >
              + Add new account
            </Button> 
        </div>
        <hr className="hr-line" />
        <div className="set-wrapper">
          <Input className="searchbar" placeholder="Search" id="search_field"  onChange={search} prefix={<img src="/images/search.png" className="search_icon"/>} />
          <img src="/images/reflash_normal_btn.png" className="de_search_icon" onClick={search}/>
          <img src="/images/sorting_normal_btn.png" className="de_search_icon" onClick={sort}/>
        </div>
        <div className="list-wrapper">
          <div className="list_item">
            <p className="account_company list_title">Company</p> 
            <p className="acc_name list_title">Name</p> 
            <p className="account_email list_title">Email</p> 
            <p className="account_job list_title">Job</p> 
            <p className="account_phone list_title">Phone</p> 
            <p className="account_address list_title">Address</p>
            <p className="account_address list_title">Admin</p>
          </div>
            <Row gutter={[0,8]}>
              {dataToShow.map(account => (
                  <Col 
                  key={account.id} 
                  sm={{ span: 24 }} 
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  xxl={{ span: 24 }}
                  >
                  <AccountItem account={account}/>
                  </Col>
              ))}
            </Row>
        </div>
      </div>
    );
};
export default AccountDetail;