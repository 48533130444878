import { Layout } from 'antd';
import NavBar from "../../components/NavBar";
import AccountDetail from "../../components/account/AccountDetail";

const { Content } = Layout;

function AccountMag({ match }) {
   return (
      <Layout className="container main-layout">
         <NavBar />
         <Layout className="bg-gray">
            <Content className="layout-content">
               <AccountDetail/>
            </Content>
         </Layout>
      </Layout>
   );
}

export default AccountMag;
