import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button, Checkbox } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { StoreContext } from "../../store";
import { requestCustomerDetail, updateUserInfo } from "../../actions"
import { createUser_funcs, registerWithEmailPassword_customer_funcs } from "../../Api"
import firebase from 'firebase/app'
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { saveNewCustomer } from "../../actions"
import { dp_log } from "../../Api"

import 'firebase/auth';
import 'firebase/firestore';

export default function AddCustomerCheck({customerId}) {
  const { state: { customerDetail: { newCustomer }, userSignin: { userInfo } } , dispatch } = useContext(StoreContext);
  const history = useHistory();
  const AddCustomer = () => {
    history.push("/addcustomer");
  };
  const dealercheckbox_visible = (userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1")?true:false;
  
  const [customer, setNewCustomer] = useState({...newCustomer});
  const [emailExist, setEmailExist] = useState({exist:false});
  const [compExist, setCompExist] = useState({exist:false});
  let e_Exist = {exist:false};
  let c_Exist = {exist:false};

  
  const addCompany = async (uid, newCustomer) => {
    console.log("addCompany:", uid, newCustomer);
    let setWithMerge =  await firebase.firestore().collection("companies").doc(newCustomer['company_name']).set({
      name: newCustomer['company_name'],
      admin: newCustomer['customer_email'],
      role: newCustomer['role'],
      admin_uid: uid,
      dealer: firebase.firestore.FieldValue.arrayUnion(...newCustomer.dealer)
    }, { merge: true });
  }
  const updateCompany = async ( newCustomer) => {
    console.log("updateCompany:", newCustomer);
    let setWithMerge =  await firebase.firestore().collection("companies").doc(newCustomer['company_name']).set({
      dealer: firebase.firestore.FieldValue.arrayUnion(...newCustomer.dealer)
    }, { merge: true });
  }
  const addUser = async (uid, newCustomer) => {
    console.log("addUser:", uid, newCustomer);
    //console.log(...newCustomer.dealer);
    //console.log(firebase.firestore.FieldValue.arrayUnion(...newCustomer.dealer));
    
    let setWithMerge =  await firebase.firestore().collection("users").doc(uid).set({
      address:newCustomer['customer_address'],
      address_zip:newCustomer['customer_zip'],   
      company:newCustomer['company_name'],
      name:newCustomer['customer_name'],
      dealer: firebase.firestore.FieldValue.arrayUnion(...newCustomer.dealer),
      email:newCustomer['customer_email'],
      job:newCustomer['customer_job'],
      phone:newCustomer['customer_phone'],
      role:newCustomer['role'],
      admin: true,
      contact: true,
      uid:uid
    }, { merge: true });
  }
  const updateUser = async ( uid, newCustomer) => {
    console.log("updateUser:", uid, newCustomer);
    let setWithMerge =  await firebase.firestore().collection("users").doc(uid).set({
      dealer: firebase.firestore.FieldValue.arrayUnion(...newCustomer.dealer)
    }, { merge: true });
  }  

  const SubmitCustomer = async (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    console.log("SubmitCustomer", newCustomer);
    console.log("Registraion email Check", emailExist);
    console.log("Registraion company Check", compExist);

    let uid;
    let result;
    if(emailExist.exist){ 
      uid = emailExist.user.uid;
      updateUser(uid, newCustomer);
    }else{
      let res = await createUser_funcs({email:newCustomer.customer_email, password: newCustomer.customer_password, role:newCustomer.role, company_name:newCustomer.company_name, admin: true} );
      uid = res.data;
      result = await addUser(uid, customer);
    }

    if(compExist.exist){
      result = await updateCompany(newCustomer);
    }else{
      result = await addCompany(uid, newCustomer);
    }
    dp_log({user:userInfo, action: "add_Customer", item: newCustomer.customer_email, meta: JSON.stringify({newCustomer:newCustomer, emailExist:emailExist,compExist:compExist})});
    saveNewCustomer(dispatch, "");
    history.push("/customermag");
  };

  const appendDealerStack = (originDealer) => {
    console.log("appendDealerStack: inputs",originDealer);
    let dealer = [...originDealer]; //原本的dealer stack
    dealer.push(...userInfo[2]); //加上目前dealer之dealer stack
    dealer.push({role: userInfo[1].claims.role, company: userInfo[1].claims.company}); //加上目前dealer
    console.log("appendDealerStack: outputs", dealer);
    return dealer;
  }

  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
  };
  useEffect(() => {
    const email = newCustomer.customer_email;
    const company = newCustomer.company_name;
    console.log("email/company:", email, company);
    
    //appendDealerStack();
    

    const emailRef =  firebase.firestore().collection("users").where("email", "==", email);
    emailRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        e_Exist = {exist:true, user:doc.data()};
        setEmailExist(e_Exist);
      });

      let doc = firebase.firestore().collection("companies").doc(company);
      doc.get().then( (doc) => {
        if (doc.exists){
          c_Exist = {exist: true, company:doc.data()};
          setCompExist(c_Exist);
        }
        console.log("Registraion email Check", e_Exist);
        console.log("Registraion company Check", c_Exist);
        //case1 and 2, email exist => correct his company name
        if(e_Exist.exist){
          if(!window.confirm(`Email "${email}" has been registered as company "${e_Exist.user.company}", do you want to continue to add it as your customer?`)){
            saveNewCustomer(dispatch, "");
            history.push("/customermag");
            return;
          }else{
            alert("We'll keep user's password unchanged!");
            newCustomer.company_name = e_Exist.user.company;
            newCustomer.role = e_Exist.user.role;
            newCustomer.customer_dealer = (newCustomer.role!=="L3")? true:false;
            delete newCustomer.customer_password;
            newCustomer.dealer = appendDealerStack(e_Exist.user.dealer);
            setNewCustomer(newCustomer);
          }
        }
        //case3 and 4, email not exist 
        if(!e_Exist.exist){
          if(c_Exist.exist){
            if(!window.confirm(`Company "${newCustomer.company_name}" already exist, do you want to continue to add it as your customer?`)){
              saveNewCustomer(dispatch, "");
              history.push("/customermag");
              return;
            }
            newCustomer.dealer = appendDealerStack(c_Exist.company.dealer);
            newCustomer.role = c_Exist.company.role;
            newCustomer.customer_dealer = (newCustomer.role!=="L3")? true:false;
          }else{
            newCustomer.dealer = appendDealerStack([]);
          }
          console.log(newCustomer);
          setNewCustomer(newCustomer);
        }
      })
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

  }, []);
  
    return (
    <div className="main-layout">
      <div className="addcustomer-wrapper">
        <img src="/images/ad_2_img.png" className="ad_1"/>
            <img src="/images/back_btn.png" className="back_btn" onClick={AddCustomer}/>
      </div>
      <div className="addcustomer-form">
        <div>
            <img src="/images/brand_logo_img.png" className="nexmowlogo"/>
        </div>
        <p className="customerckeck-title">Welcome to be our partner!</p> 
        <div className="customerckeck">
            <p className="ckeckform-title">Please check again, before submit.</p>
            <p className="ckeckform-detail">Company name:  {customer.company_name}</p> 
            <p className="ckeckform-detail">Customer name:  {customer.customer_name}</p> 
            <p className="ckeckform-detail">Email address:  {customer.customer_email} </p> 
            {(customer.customer_password)?<p className="ckeckform-detail">Password:  {customer.customer_password} </p>:null}
            <p className="ckeckform-detail">Job title:  {customer.customer_job}</p> 
            <p className="ckeckform-detail">Phone number:  {customer.customer_phone}</p> 
            <p className="ckeckform-detail">Zip code:  {customer.customer_zip} </p> 
            <p className="ckeckform-detail">Address:  {customer.customer_address}</p>  
            {dealercheckbox_visible?
            <p className="ckeckform-detail">Dealer:  {customer.customer_dealer ? 'Yes':'No'}</p>
                :null}
        </div>
        <Button
            htmlType="submit"
            className="customersent_btn"
            onClick={() =>SubmitCustomer(0)}
            loading={loadings[0]}
        >
            Submitted
        </Button>
      </div>
    </div>
  );
};