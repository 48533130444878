import React, { useContext, useEffect,useState } from "react";
import { useHistory,Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Text, Row, Col} from 'antd';
//import { StoreContext } from "../../store"
import TransferRobotItem from "../robot/TransferRobotItem";
import robots from "../../json/robots.json";
import firebase from 'firebase/app'
import { StoreContext } from "../../store"
import {saveSelectedRobots} from "../../actions/index.js"

const ActivateRobotDetail = () => {
  const [dataToShow, setData] = useState([]);
  const { state:{ userSignin: { userInfo}}, dispatch } = useContext(StoreContext);
  const [g_robots, setGrobots] = useState([]);
  const [order, setOrder] = useState("AESC");
  const history = useHistory();
  const AddRobotPSN = () => {
    var selected = [];
    Object.entries(userData).forEach( (element) => {
      //console.log(element[0],":", element[1]);
      if(element[1]){
        selected.push(element[0]);
      }
    });
    console.log("AddPSNnum selected: ", selected);

    if(selected.length > 0){
      saveSelectedRobots(dispatch, selected);
      history.push("/activaterobotpsn");
    }else{
      alert("Please select one or more robots to activate");
    }
  };
  function refreshPage() {
    window.location.reload(false);
  }

  const [userData, updateFormUserData] = useState({});
  const handleChange = (event) => {
    //console.log(`userData.admin: ${userData.admin}`)
    updateFormUserData({
      ...userData,
      [event.target.name]: event.target.checked
    });
    console.log("ActivateRobotDetail userData:" ,userData);
    //console.log(`${event.target.name}: ${event.target.checked}`)
  };


  useEffect(() => {

    var company = userInfo[1].claims.company;
    var role = userInfo[1].claims.role;

    const docRef = firebase.firestore().collection('robots').where("company", "==", company);
    //Workaround - able to activate customer's robots - 20230428
    //const docRef = firebase.firestore().collection('robots').where("dealer", "array-contains", {role:role, company:company})
    docRef.get().then((querySnapshot) => {
      let robots = [];
      querySnapshot.forEach((robot) => {
        console.log(robot.id, "=> ", robot.data());
          if(robot.data().warranty && robot.data().warranty.activate===false){
            let psn = (robot.data().warranty && robot.data().warranty.psn)?robot.data().warranty.psn:"";
            let model = (robot.data().model)?robot.data().model:"";
            let company = (robot.data().company)?robot.data().company:"";
            let id =  robot.id;
            robots.push({psn: psn, model: model, company: company, warranty: "In stock" ,id: id})
          }
      });
      if(robots.length==0){
        alert(`No in-stock robots in your company to activate!`);
        history.push("robotmag");
      }
      setGrobots(robots)
      setData(robots);
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
  }, []);

  //搜尋與排序
  function search() {
    var input = document.getElementById("search_field").value;
    var robots = JSON.parse(JSON.stringify(g_robots));
    var r = robots.filter(i => i.psn.includes(input) || i.model.includes(input) || i.company.includes(input));
    var sorted_robots = sort_robots(r, order);
    setData(sorted_robots);
  }

  function sort_robots(robots, order){
    function desc( a, b ) {
      if ( a.psn.toUpperCase() < b.psn.toUpperCase() ){
        return 1;
      }
      if ( a.psn.toUpperCase() > b.psn.toUpperCase() ){
        return -1;
      }
      return 0;
    }
    function aesc( a, b ) {
        if ( a.psn.toUpperCase() < b.psn.toUpperCase() ){
          return -1;
        }
        if ( a.psn.toUpperCase() > b.psn.toUpperCase() ){
          return 1;
        }
        return 0;
      }
    if(order == "AESC"){
      robots.sort(aesc)
    }else{
      robots.sort(desc)
    }
    return robots;
  }

  function sort() {
    var or = (order == "DESC"? "AESC":"DESC");
    setOrder(or);
    var robots = sort_robots(dataToShow, or);
    setData(robots);
    //return doc.data()
  }

  return (
    <div className="layout_limit"> 
      <div className="mainform-wrapper">
        <Link to="/robotmag">
          <img src="/images/menu_back_btn.png" className="back_s_btn"/>
        </Link>
        <p className="main-title">Activate robots</p> 
          <Button
            htmlType="submit"className="update_btn" onClick={AddRobotPSN}
          >
            Next
          </Button>
      </div>
      <hr className="hr-line" />
      
      { <div className="set-wrapper">
        <Input className="searchbar" placeholder="Search" id="search_field" onChange={search} prefix={<img src="/images/search.png" className="search_icon"/>} />
         </div> }
      <div className="list-wrapper">
      <div className="list_item">
            <p className="robot_psn list_title">PSN Number</p>
            <p className="robot_name list_title">Model</p>
            <p className="robot_company list_title">Company</p>
            <p className="robot_select list_title">Select</p> 
            
          </div>
        <Row gutter={[0,8]}>
            {dataToShow.map(robot => (
                <Col 
                key={robot.id} 
                sm={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                >
                <TransferRobotItem robot={{rob:robot, callback:handleChange}}/>
                </Col>
            ))}
          </Row>
      </div>
    </div>
  );
};
export default ActivateRobotDetail;