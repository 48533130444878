import React, { useState, useContext } from "react";
import { Form, Input, Button, Modal } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { saveNewBranch } from "../../actions"
import { StoreContext } from "../../store";

export default function AddBranchCard() {
  const { state: { branchDetail:{ newBranch }}, dispatch } = useContext(StoreContext);
  const [form] = Form.useForm();
  const history = useHistory();
  
  const AddBranchCheck = (values) => {
    console.log('AddBranchCheck Received values of form: ', values);
    if(!values.branch_admin)
      values.branch_admin = false;
    Object.keys(values).forEach(function(key, index) {
      if (this[key] == null) this[key] = "";
    }, values);  
    saveNewBranch(dispatch, values)
    history.push("/addbranchcheck");
  };
    
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    history.push("/branchmag");
    setIsModalVisible(false);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };
    
    return (
      <div className="main-layout">
        <div className="addcustomer-wrapper">
          <img src="/images/ad_2_img.png" className="add_pic"/>
          <img src="/images/back_btn.png" className="back_btn" onClick={showModal}/>
          <Modal title="NEXMOW management system" visible={isModalVisible}
            onOk={handleOk} onCancel={handleCancel}>
            <p>Are you sure to exit without save?</p>
          </Modal>
        </div>
        <div className="addcustomer-form">
          <div className="addcustomer">
              <p className="addcustomer-title">Add new branch</p> 
              <hr className="hr-addcustomerline" />
          </div>
          <Form
            className="accountform_list"
            name="add_branch_form"
            form={form}
            layout="vertical"
            initialValues={newBranch}
            onFinish={AddBranchCheck}
          >
          <Form.Item 
            label="Branch Name" required 
            name="branch_name"
            className="addcustomer_form"
            rules={[
              {
                type: "string",
              },
              {
                required: true,
                message: "Please input name",
              },
            ]}
            hasFeedback
            >
              <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item 
            label="Email address" required 
            name="branch_email"
            className="addcustomer_form"
            rules={[
              {
                type: "string",
              },
              {
                required: true,
                message: "Please input email address",
              },
            ]}
            hasFeedback
            >
              <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item 
            label="Password" required 
            name="branch_password"
            className="addcustomer_form"
            rules={[
              {
                type: "string",
              },
              {
                required: true,
                message: "Please input password",
              },
            ]}
            hasFeedback
            >
              <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item 
            label="Retype password" required 
            name="branch_password_check"
            className="addcustomer_form"
            rules={[
               ({getFieldValue})=>({
                    validator(rule,value){
                      if(!value || getFieldValue('branch_password') === value){
                        return Promise.resolve()
                      }
                        return Promise.reject("Incorrect password") 
                      }
                    }),
                    {
                      required: true,
                      message: "Please input password again",
              },
            ]}
            hasFeedback
            >
              <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
              label="Job title (optional)"
              name="branch_job"
              className="addcustomer_form"
              hasFeedback
              >
              <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Phone number (optional)"
            name="branch_phone"
            className="addcustomer_form"
            hasFeedback
            >
              <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="zip (optional)"
            name="branch_zip"
            className="addcustomer_form"
            hasFeedback
            >
              <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="address (optional)"
            name="branch_address"
            className="addcustomer_form"
            hasFeedback
            >
              <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Adminstrator?"
            name="branch_admin"
            valuePropName="checked"
            className="addcustomer_form"
            hasFeedback
          >
          <input type="checkbox" value="admin"/>
          </Form.Item>
          <Form.Item className="login-enter">
            <Button
              htmlType="submit"
              className="addcustomer_btn"
            >
              Save
            </Button>
          </Form.Item>
          </Form>
        </div>
      </div>
    )
  
}