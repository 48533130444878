import { Layout } from 'antd';
import NavBar from "../components/NavBar";
import TutorialPage from "../components/TutorialPage";

const { Content } = Layout;

function Tutorial({ match }) {
   // const product = products.find(
   //    x => x.id === match.params.productId
   // );
   return (
      <Layout className="container main-layout">
         <NavBar />
         <Layout className="bg-gray">
            <Content className="layout-content">
               <TutorialPage />
            </Content>
         </Layout>
      </Layout>
   );
}

export default Tutorial;