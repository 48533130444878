import { useContext } from "react";
import { Card, Checkbox, Input, Col, Row } from "antd"
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import { setCustomerDetail } from "../../actions";

export default function TransferRobotItemPSN( props ) {
    const { dispatch } = useContext(StoreContext);
    
    console.log("TransferRobotItemPSN", props);
    const robot = props.robot.rob;
    const callback = props.robot.callback;

    const handleChange = (event) => {
        console.log("onchange", event);
        console.log(`${event.target.name}: ${event.target.value}`)

        if(robot.psn.slice(-4).toUpperCase() === event.target.value.toUpperCase()){
            console.log(robot.psn, ",same", event.target.value);
            robot.digitsCorrectness = true;
            callback(robot);
        }else{
            console.log(robot.psn, ",diff", event.target.value);
            robot.digitsCorrectness = false;
            console.log("robot:", robot);
            callback(robot);
        }    
    };

    console.log("TransferRobotItemPSN:", robot);
    return (
        <div className="list_item robot_list_item">
            <p className="robot_model">{robot.model}</p> 
            <p className="robot_sn_psn">{robot.psn}</p> 
            <Input className="psn_de" placeholder={"Repeat last 4 digits to confirm -> "+robot.psn.slice(-4)} autoFocus onChange={handleChange} maxLength="4"/>
            <p className="robot_sn_psn_compare">{(robot.digitsCorrectness)?"Matched!":"Mismatch"}</p> 
        </div>
    );
}
