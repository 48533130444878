import { useState, useContext, useEffect } from "react";
import { Drawer, Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import NavItem from "./NavItem";
import { logoutFromFirebase, setUserInfo } from "../actions";
import { StoreContext } from "../store";
import UserInfo from "./UserInfo";

export default function NavBar() {
    const {state: {userSignin: { userInfo }},dispatch} = useContext(StoreContext);
    const [isOnTouch, setIsOnTouch] = useState(false);
    const handleCloseDrawer = () => setIsOnTouch(false);
    const [dataToShow, setData] = useState([]);

    const [visible, setVisible] = useState(false);

    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false); //確認登出modal
    const Logout = () => {
        logoutFromFirebase(dispatch);
        history.push("/login");
    };
    
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = (handler) => {
        setIsModalVisible(false);
    };

    useEffect(()=>{
        console.log("navbar:", userInfo[1].claims.role);
        if(userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1" || userInfo[1].claims.role==="L2"){
            setVisible(true);  //Show customer management page
        }else{
            setVisible(false);
        }   
    },[]);

    return (
        <div>
            {/* <HamMenu
                onClick={() => setIsOnTouch(!isOnTouch)}
                isOnTouch={isOnTouch}
            /> */}
            <div className="nav-bar" >
                <div className="account">
                    <img src="/images/account.png" className="accountpic"/>
                    <UserInfo />
                </div>
                {visible? <NavItem to="/customermag" className="nav-item" activeClassName="nav-item--active">
                    Customer Management
                </NavItem>:null}
                <NavItem to="/robotmag" className="nav-item" activeClassName="nav-item--active">
                    Robot Management
                </NavItem>
                <NavItem to="/accountmag" className="nav-item" activeClassName="nav-item--active">
                    Account Management
                </NavItem>
                <NavItem to="/mapmag" className="nav-item" activeClassName="nav-item--active">
                    Map Management
                </NavItem>
                <NavItem to="/taskmag" className="nav-item" activeClassName="nav-item--active">
                    Task Management
                </NavItem>
                <NavItem to="/reportmag" className="nav-item" activeClassName="nav-item--active">
                    Report Management
                </NavItem>
                {visible? <NavItem to="/tutorial" className="nav-item" activeClassName="nav-item--active">
                    Tutorial
                </NavItem>:null}
                <Button
                    htmlType="submit"
                    className="logout_btn"
                    onClick={showModal}
                >
                    LOGOUT
                </Button>
                <Modal title="Dealer Portal" visible={isModalVisible} onOk={Logout} onCancel={handleCancel}>
                    <p>Are you sure to logout without save?</p>
                </Modal>
            </div>
            <Drawer
                sticky="left"
                title=" "
                placement={"left"}
                closable={false}
                onClose={handleCloseDrawer}
                visible={isOnTouch}
                key={"left"}
                width={372}
                zIndex={99}
                bodyStyle={{ backgroundColor: "#004ea8" }}
                headerStyle={{ backgroundColor: "#004ea8", color: "#fff" }}
            >
            </Drawer>
        </div>
    );
}