import { Layout } from "antd";
import AddCustomerCard from "../../components/customer/AddCustomerCard";
const { Content } = Layout;

function AddCustomer(props) {
  return (
    <Layout className="container main-layout">
      <Content>
        <AddCustomerCard />
      </Content>
    </Layout>
  );
}

export default AddCustomer;
