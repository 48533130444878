import { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import firebase from 'firebase/app'
import { setRobotDetail } from "../../actions"

export default function RobotItem({ robot }) {
    const { dispatch } = useContext(StoreContext);
    
    useEffect(() => {
    }, []);

    return (
        <Link to={`/robotitem/${robot.id}`}
            onClick={() => {
                console.log('onClick enter:', robot);
                setRobotDetail(dispatch, robot);
            }}
        >
        <div className="list_item">
            <p className="robot_psn">{robot.psn}</p>
            <p className="robot_name">{(robot.model)?robot.model.split("-")[0]:""}</p>
            <p className="robot_name">{robot.model}</p> 
            <p className="robot_date">{robot.warranty}</p>
            <p className="robot_date ">{robot.subscription}</p>
            <p className="robot_company">{robot.company}</p> 
        </div>
        </Link>
    );
}