import React, { useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Text, Row, Col } from 'antd';
import { StoreContext } from "../../store"
import BranchItem from "../branch/BranchItem";
import firebase from 'firebase/app'

const BranchDetail = () => {
  const { state:{ userSignin: { userInfo } } } = useContext(StoreContext);
  const [dataToShow, setData] = useState([]);
  const [g_branchs, setGbranchs] = useState([]);
  const [order, setOrder] = useState("AESC");
  
  const history = useHistory();
  const AddBranch = () => {
    history.push("/addbranch");
  };

  useEffect(() => {
    //console.log("userinfo", userInfo);
    const docRef = firebase.firestore().collection('users').where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company})
      docRef.get().then((querySnapshot) => {
        const branch = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            branch.push({company : doc.data().company,name : doc.data().name, address: doc.data().address, 
              phone: doc.data().phone, job: doc.data().job, id : doc.id, email: doc.data().email, address_zip: doc.data().address_zip, admin: doc.data().admin})
        
        });

        setGbranchs(JSON.parse(JSON.stringify(branch)));
        var sorted_branch = sort_branchs(branch, order);

        setData(sorted_branch);
        //return doc.data()
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });
    }, []);


  function search() {
    var input = document.getElementById("search_field").value;
    var branchs = JSON.parse(JSON.stringify(g_branchs));
    var a = branchs.filter(i => i.name.includes(input) || i.company.includes(input) || i.email.includes(input)|| i.address.includes(input)|| i.phone.includes(input)|| i.job.includes(input));
    setData(a);
  }
  
  function sort_branchs(branchs, order){
    function desc( a, b ) {
      if ( a.name.toUpperCase() < b.name.toUpperCase() ){
        return 1;
      }
      if ( a.name.toUpperCase() > b.name.toUpperCase() ){
        return -1;
      }
      return 0;
    }
    function aesc( a, b ) {
        if ( a.name.toUpperCase() < b.name.toUpperCase() ){
          return -1;
        }
        if ( a.name.toUpperCase() > b.name.toUpperCase() ){
          return 1;
        }
        return 0;
      }
    if(order == "AESC"){
      branchs.sort(aesc)
    }else{
      branchs.sort(desc)
    }
    return branchs;
  }
  
  function sort() {
    var or = (order == "DESC"? "AESC":"DESC");
    setOrder(or);
    var branchs = sort_branchs(dataToShow, or);
    setData(branchs);
    //return doc.data()
  }

  return (
    <div className="layout_limit">
      <div className="mainform-wrapper">
        <p className="main-title">Branch Management</p> 
          <Button
            htmlType="submit"className="addcustomer__button" onClick={AddBranch}
          >
            + Add new branch
          </Button> 
      </div>
      <hr className="hr-line" />
      <div className="set-wrapper">
        <Input className="searchbar" placeholder="Search" id="search_field" prefix={<img src="/images/search.png" className="search_icon"/>} />
        <img src="/images/reflash_normal_btn.png" className="de_search_icon" onClick={search}/>
        <img src="/images/sorting_normal_btn.png" className="de_search_icon" onClick={sort}/>
      </div>
      <div className="list-wrapper">
        <div className="list_item">
          <p className="account_company list_title">Company</p> 
          <p className="acc_name list_title">Name</p> 
          <p className="account_email list_title">Email</p> 
          <p className="account_job list_title">Job</p> 
          <p className="account_phone list_title">Phone</p> 
          <p className="account_address list_title">Address</p> 
        </div>
        <Row gutter={[0,8]}>
          {dataToShow.map(branch => (
              <Col 
              key={branch.id} 
              sm={{ span: 24 }} 
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              >
              <BranchItem branch={branch}/>
              </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default BranchDetail;