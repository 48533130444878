import React, { useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Text, notification, Modal } from 'antd';
import { StoreContext } from "../../store";
import firebase from 'firebase/app'
import { mdiAccountSchool } from "@mdi/js";
import { setUserInfoApi, setAccountDetail, updateUserInfo } from "../../actions";
import { setClaim_funcs, dp_log } from "../../Api"
import 'firebase/firestore';

const AccountItemDetail = () => {
  const [dataToShow, setData] = useState([]);
  const { state:{ accountDetail: { account }, userSignin: { userInfo } }, dispatch } = useContext(StoreContext);
  const history = useHistory();
  const [form] = Form.useForm();
 
  const AccountMag = () => {
    history.push("/accountmag");
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = async (event) => {
    console.log("handleUpdate:" ,userData);
    setIsModalVisible(false);
    
    if(userData.id !== "undefined"){
        let setWithMerge =  await firebase.firestore().collection("users").doc(userData.id).set({
            address:userData['address'],
            address_zip:userData['address_zip'],   
            name:userData['name'],
            admin: userData['admin'],
            job:userData['job'],
            phone:userData['phone']
        }, { merge: true });
        
        let result = setClaim_funcs({id: userData.id, admin: userData.admin, role:userInfo[1].claims.role, company_name:userInfo[1].claims.company});
        dp_log({user:userInfo, action: "update_Account", item: userData.id, meta: JSON.stringify({user: userData})});
    }
    AccountMag();
  };

  const [userData, updateFormUserData] = useState(account);
  const handleChange = (event) => {
    //console.log(`userData.admin: ${userData.admin}`)
    updateFormUserData({
      ...userData,
      [event.target.name]: event.target.value.trim()
    });

    if(event.target.name === 'admin'){
      updateFormUserData({
        ...userData,
        [event.target.name]: event.target.checked
      });
    }
    //console.log("userData:" ,userData);
    //console.log(`${event.target.name}: ${event.target.checked}`)
  };

  console.log("AccountItemDetail Enter: ", account);
  //console.log("AccountItemDetail Enter: ", userData);
  useEffect(() => {
    console.log("useEffect:" ,userData);
    const accounts = [];
    accounts.push(account);
    setData(accounts);
    localStorage.setItem("account", JSON.stringify(account)); //存取個別account data
  /*  
  const docRef = firebase.firestore().collection('users').where("email", "==", "m680@urs.dev")
    docRef.get().then((querySnapshot) => {
      const account = [];
      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          //account.push({name: doc.data().email, company: doc.data().company})
          account.push({company : doc.data().company,name : doc.data().name, address: doc.data().address, 
            phone: doc.data().phone, job: doc.data().job, id : doc.id, email: doc.data().email, address_zip: doc.data().address_zip})
      
      });
      console.log("account: ", account);
      setData(account);
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
    */
  }, [account]);

    return (
      <div>
       {dataToShow.map(account => {
          return (
            <div className="layout_limit" key={account.id} >
              <div className="accform-wrapper">
              <img src="/images/menu_back_btn.png" className="back_s_btn" onClick={AccountMag}/>
                <p className="acc-title">{account.name}</p> 
                  <Button
                    htmlType="submit"className="account_remove__button acc_btn" onClick={showModal}
                  >
                    Update
                  </Button> 
                  <Modal title="NEXMOW management system" visible={isModalVisible} 
                    onOk={handleUpdate} onCancel={handleCancel}>
                    <p>Are you sure to update this account?</p>
                  </Modal>
              </div>

              <div className="accform-wrapper">
                <Form
                    name="normal_login"
                    className="login-form_list"
                    form={form}
                    layout="vertical"
                    onChange={handleChange}
                    initialValues={account}
                  >
                  <Form.Item 
                      label="Company" required 
                      className="account_detail_form">
                      <p className="account_company">{account.company}</p> 
                  </Form.Item>
                  <Form.Item 
                      label="Email address" required 
                      className="account_detail_form">
                      <p className="account_email">{account.email}</p> 
                  </Form.Item>
                  <Form.Item 
                      label="Name" required 
                      className="account_detail_form">
                      <Input placeholder={account.name} name="name"/>
                  </Form.Item>
                  <Form.Item 
                      label="Administrator" required 
                      className="account_detail_form ">
                      <input className="robot_select" type="checkbox" value="admin" name="admin" checked={userData.admin} onChange={handleChange}/>
                  </Form.Item>
                </Form>
                <Form
                    name="normal_login"
                    //className="login-form_list"
                    form={form}
                    layout="vertical"
                    onChange={handleChange}
                    initialValues={account}
                  >
                  <Form.Item
                      label="Phone number (optional)"
                      className="account_detail_form"
                  >
                      <Input placeholder={account.phone} name="phone"/>
                  </Form.Item>
                  <Form.Item
                      label="Address (optional)"
                      className="account_detail_form"
                  >
                      <Input placeholder={account.address} name="address"/>
                  </Form.Item>
                  <Form.Item
                      label="Zip code (optional)"
                      className="account_detail_form"
                  >
                      <Input placeholder={account.address_zip} name="address_zip"/>
                  </Form.Item>
                  <Form.Item
                      label="Job title (optional)"
                      className="account_detail_form"
                  >
                      <Input placeholder={account.job} name="job"/>
                  </Form.Item>
                </Form>
              </div>
            </div>
          );
        })}
      </div>
    );
};
export default AccountItemDetail;