import { useContext } from "react";
import { Link } from "react-router-dom";
import { SET_PAGE_TITLE, SET_PAGE_CONTENT, SET_NAVBAR_ACTIVEITEM } from "../utils/constants"
import { StoreContext } from "../store"

export default function NavItem(props) {
   const { children, to, className, activeClassName } = props
   const { state, dispatch } = useContext(StoreContext);

   const onClick = () => {
      console.log(children)
      dispatch({
         type: SET_PAGE_TITLE, 
         payload: children,
      });
      dispatch({
         type: SET_PAGE_CONTENT,
      });
      dispatch({
         type: SET_NAVBAR_ACTIVEITEM, 
         payload: to,
      });
   };
   return (
      <Link to={to}>
         <div
            onClick={onClick}
            className={`
            ${className} 
            ${state.navBar.activeItem === to ? activeClassName : ""}`}
         >
            {children}
         </div>
      </Link>

   );
}