import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Modal } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { saveNewRegister } from "../../actions"
import { StoreContext } from "../../store"

export default function AddAccountCard() {
  const { state: { userRegister: { newRegister } }, dispatch } = useContext(StoreContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const RegisterCheck = (values) => {
    saveNewRegister(dispatch, values)
    history.push("/registercheck");
  };
  // const onFinish = async (values) => {
  //   console.log('Received values of form: ', values);
  //   await registerToFirebase(dispatch, values);
  // };

  // useEffect(() => {
  //   if (userInfo) history.push(redirect);
  // }, [userInfo]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="main-layout">
      <div className="addcustomer-wrapper">
        <img src="/images/ad_2_img.png" className="ad_1"/>
      </div>
      <div className="addcustomer-form">
        <div className="nexmowlogo">
          <img src="/images/brand_logo_img.png" />
        </div>
        <p className="customerckeck-title">Welcome to be our partner!</p>
        <p className="register_word">This process may take for few minutes; please fill in the following form for the product registration. After completed, we’ll send the account and password for login.  </p>
        <Form
          name="normal_login"
          //className="login-form_list"
          form={form}
          layout="vertical"
          initialValues={newRegister}
          onFinish={RegisterCheck}
        >
        <Form.Item 
            label="Company name" required 
            name="register_name"
            className="addcustomer_form ant-input1">
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item 
            label="Email address" required 
            name="register_email"
            className="addcustomer_form">
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
            label="Phone number (optional)"
            name="register_phone"
            className="addcustomer_form"
        >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
            label="Zip code (optional)"
            name = "register_zip"
            className="addcustomer_form"
        >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
            label="Address (optional)"
            name="register_address"
            className="addcustomer_form"
        >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item className="login-enter">
          <Button
            htmlType="submit"
            className="addcustomer_btn"
            onClick={RegisterCheck}
          >
            Next
          </Button>
        </Form.Item>
        </Form>
      </div>
    </div>
  );
};