import { Layout } from 'antd';
import NavBar from "../../components/NavBar";
import ReportItemDetail from "../../components/report/ReportItemDetail";

const { Content } = Layout;

function ReportItem() {
   return (
      <Layout className="container main-layout">
         <NavBar />
         <Layout className="bg-gray">  
            <Content className="layout-content">
               <ReportItemDetail/>
            </Content>
         </Layout>
      </Layout>
   );
}

export default ReportItem;
