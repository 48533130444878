import React, { useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Text, Row, Col, Modal } from 'antd';
import { StoreContext } from "../../store"
import MapItem from "../map/MapItem";
import firebase from 'firebase/app'
import { lockMap } from "../../Api"

const MapDetail = () => {
  const { state:{ userSignin: { userInfo } } } = useContext(StoreContext);
  const [dataToShow, setData] = useState([]);
  const [g_maps, setGmaps] = useState([]);
  const [order, setOrder] = useState("AESC");
  
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {

    if(Object.keys(userData).length===0){
      alert("There is no modification to apply!");
      return;
    }
    setIsModalVisible(true);
  };

  const isL0L1 = () => {
    return (userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1");
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = async (event) => {
    console.log("handleUpdate:" ,userData);
    setIsModalVisible(false);
    const result = await lockMap(userInfo,userData);
    updateFormUserData({});
    alert("Modifications are saved successfully!");
  };

  const [userData, updateFormUserData] = useState({});
  const handleChange = (event) => {
    //console.log("userData:" ,userData);
    //console.log(`${event.target.name}: ${event.target.checked}`)
    updateFormUserData({
      ...userData,
      [event.target.name]: event.target.checked
    });
  };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h' : 'h') : "";
    var mDisplay = m > 0 ? m + (m == 1 ? 'm' : 'm') : "";
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : "";
    // return hDisplay + mDisplay + sDisplay; 

    if ((h <= 0) && (m <= 0 )) {
        return "1 min";
    }
    return hDisplay + " " + mDisplay;
}

 function getTravelTime(waypointTraveltime, totalDevices = 1) {

    let totalSeconds = waypointTraveltime.reduce((s, f) => s + f.travel_time, 0)
    let avgSeconds = totalSeconds / totalDevices;
    return secondsToHms(avgSeconds);
}
  useEffect(() => {
    //console.log("userinfo", userInfo);
    const account = [];
    const zones = [];
    const docRef = firebase.firestore().collection('users').where("company", "==", userInfo[1].claims.company);
      docRef.get().then((querySnapshot) => {
        
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            account.push({name : doc.data().name, uid: doc.data().uid});
            const zoneRef = firebase.firestore().collection('zones').where("uid", "==", doc.data().uid);
            zoneRef.get().then((querySnapshot) => {
              querySnapshot.forEach((zone) => {
                //console.log("uid,  => ", doc.data().uid);
                //console.log("zone,  => ", zone.data());
                let id = zone.id;
                let name = (zone.data().name)?zone.data().name:"";
                let acc_name = (doc.data().email)?doc.data().email:"";
                let uid = (doc.data().uid)?doc.data().uid:"";
                let travel_time = (zone.data().path_traveltime)?getTravelTime(zone.data().path_traveltime):"unknown";
                let home_base = (zone.data().config && zone.data().config.rally_point && Object.keys(zone.data().config.rally_point).length > 0)?"Set":"N/A";
                let lock = (zone.data().lock)? zone.data().lock : false;
                let zone_area = (zone.data().zone_area)? zone.data().zone_area : 0;
                
                zones.push({id: id, name: name, acc_name: acc_name, uid: uid, travel_time: travel_time, home_base: home_base, lock: lock, zone_area: zone_area*1.1});
              });
              //console.log("zone list,  => ", zones);
              setGmaps(JSON.parse(JSON.stringify(zones)));
              var sorted_map = sort_maps(zones, order);
              setData(sorted_map);
            })
        });
        console.log("account list,  => ", account);
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });
    }, []);

  function search() {
    var input = document.getElementById("search_field").value;
    var maps = JSON.parse(JSON.stringify(g_maps));
    var a = maps.filter(i => i.name.includes(input) || i.acc_name.includes(input));
    setData(a);
  }
  
  function sort_maps(maps, order){
    function desc( a, b ) {
      if ( a.name.toUpperCase() < b.name.toUpperCase() ){
        return 1;
      }
      if ( a.name.toUpperCase() > b.name.toUpperCase() ){
        return -1;
      }
      return 0;
    }
    function aesc( a, b ) {
        if ( a.name.toUpperCase() < b.name.toUpperCase() ){
          return -1;
        }
        if ( a.name.toUpperCase() > b.name.toUpperCase() ){
          return 1;
        }
        return 0;
      }
    if(order == "AESC"){
      maps.sort(aesc)
    }else{
      maps.sort(desc)
    }
    return maps;
  }
  
  function sort() {
    var or = (order == "DESC"? "AESC":"DESC");
    setOrder(or);
    var maps = sort_maps(dataToShow, or);
    setData(maps);
    //return doc.data()
  }
  return (
    <div className="layout_limit">
      <div className="mainform-wrapper">
        <p className="main-title">Map Management({g_maps.length})</p> 
          <Button
            htmlType="submit"className="account_remove__button acc_btn" onClick={showModal}
          >
            Update
          </Button> 
          <Modal title="NEXMOW management system" visible={isModalVisible} 
            onOk={handleUpdate} onCancel={handleCancel}>
            <p>Are you sure to update ?</p>
          </Modal>
      </div>
      <hr className="hr-line" />
      <div className="set-wrapper">
        <Input className="searchbar" placeholder="Search" id="search_field" onChange={search} prefix={<img src="/images/search.png" className="search_icon"/>} />
        <img src="/images/reflash_normal_btn.png" className="de_search_icon" onClick={search}/>
        <img src="/images/sorting_normal_btn.png" className="de_search_icon" onClick={sort}/>
      </div>
      <div className="list-wrapper">
        <div className="list_item">
          {/* <p className="account_company list_title">Company</p>  */}
          <p className="acc_name list_title">Name</p> 
          <p className="map_mowing list_title">Mowing Time</p> 
          <p className="map_mowing list_title">Area Size<br/>(acre/sqft)</p> 
          <p className="account_job list_title">Homebase</p>
          <p className="account_phone list_title">Created By</p> 
          <p className="account_phone list_title map_lock">Lock</p>
          { !isL0L1()?null:
          <p className="account_phone list_title">Concave Path</p>
          }
        </div>
        <Row gutter={[0,8]}>
          {dataToShow.map(map => (
              <Col 
              key={map.id} 
              sm={{ span: 24 }} 
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              >
              <MapItem map={{map:map, callback:handleChange}}/>
              </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default MapDetail;