import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, notification, Modal, Row, Col } from 'antd';
import Icon from '@mdi/react'
import { mdiAccessPointNetwork } from '@mdi/js';
import { StoreContext } from "../../store";
import { setUserInfoApi, setCustomerDetail, updateUserInfo } from "../../actions";
import RobotItem from "../robot/RobotItem";
import firebase from 'firebase/app'
import { dp_log } from "../../Api"

const RobotItemDetail = () => {
  const { state: {robotDetail:{ robot }, userSignin: { userInfo }}, dispatch } = useContext(StoreContext);

  const history = useHistory();
  const [form] = Form.useForm();
  const RobotMag = () => {
    history.push("/robotmag");
  };

  const handleChange = (event) => {
  };

  useEffect(() => {
    console.log('robotItemDetail useEffect1 enter:', robot);
    console.log('robotItemDetail useEffect1 enter:', robot.model);
  }, []);

    const { TextArea } = Input;
    return (
      <div>
            <div className="layout_limit" key={robot.id} >
              <div className="accform-wrapper">
              <img src="/images/menu_back_btn.png" onClick={RobotMag}/>
                <p className="acc-title">{robot.model}</p>     
              </div>
                
              <div className="accform-wrapper">
                <Form
                  name="normal_login"
                  className="login-form_list"
                  form={form}
                  layout="vertical"
                  onChange = {handleChange}
                  initialValues={robot.model}
                  
                >
                  <Form.Item 
                      label="PSN" 
                      className="account_detail_form"
                      hasFeedback>
                      <p className="user_company">{robot.psn}</p> 
                  </Form.Item>
                  <Form.Item 
                      label="Company name" 
                      className="account_detail_form"
                      hasFeedback>
                      <p className="user_company">{robot.company}</p> 
                  </Form.Item>
                  <Form.Item 
                      label="IMSI" 
                      className="account_detail_form"
                      hasFeedback>
                      <p className="user_email">{robot.imsi}</p> 
                  </Form.Item>
                  <Form.Item 
                      label="SW Version" required 
                      className="account_detail_form"
                      hasFeedback>
                      <p className="user_email">{robot.swVer}</p> 
                  </Form.Item>
                </Form>
                <Form
                  name="normal_login"
                  //className="login-form_list"
                  form={form}
                  layout="vertical"
                  //onFinish={handleUpdate}
                  //onChange={val => setCustomerDetail(dispatch)}
                  onChange = {handleChange}
                  initialValues={robot.model}
                >
                  <Form.Item
                      label="Last online time"
                      className="account_detail_form"
                      hasFeedback
                  >
                      <p className="user_email">{robot.lastOnline}</p> 
                  </Form.Item>
                  <Form.Item
                      label="Usage miles"
                      className="account_detail_form"
                      hasFeedback
                  >
                      <p className="user_email">{robot.miles}</p> 
                  </Form.Item>
                  <Form.Item
                      label="Usage time(Hour)"
                      className="account_detail_form"
                      hasFeedback
                  >
                      <p className="user_email">{robot.runningTime}</p> 
                  </Form.Item>
                  <Form.Item
                      label="Lift belt lifetime"
                      className="account_detail_form"
                      hasFeedback
                  >
                      <p className="user_email">{robot.liftTimes}</p> 
                  </Form.Item>
                  {/* <Form.Item>
                    <Button
                      htmlType="submit"className="update_btn space_btn" 
                    >
                      Update
                    </Button>
                  </Form.Item> */}
                </Form>
              </div>
              {/*
              <Form
                  name="normal_login"
                  form={form}
                  layout="vertical"
                  onChange = {handleChange}
                  initialValues={robot.model}
              >
                <Form.Item
                    label="Memo"
                    className="detail_input_form"
                    hasFeedback
                >
                    <TextArea defaultValue={robot.model} rows={10} name="memo" />
                </Form.Item>
                </Form>*/}
              </div>
      </div>
    );
};
export default RobotItemDetail;