import { useContext, useState } from "react";
import { Card, Row, Col } from "antd"
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import { setCustomerDetail } from "../../actions";
import firebase from 'firebase/app'

export default function CustomerItem({user}) {
    const { dispatch } = useContext(StoreContext);
    //console.log('CustomerItem enter:', user);
/*
    const [dataToShow, setData] = useState([]);

    const docRef =  firebase.firestore().collection("users");
    docRef.get().then((querySnapshot) => {
        const user = [];
        // querySnapshot.forEach(doc => {
        //     // doc.data() is never undefined for query doc snapshots
        //     console.log(doc.id, " => ", doc.data());
        //     user.push({name : doc.data().company, address: doc.data().address, phone: doc.data().phone, job: doc.data().job})
        // });
        setData(user);
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
      
    });*/  

    return (
        <Link to={`/customeritem/${user.name}`}
            onClick={() => {
                //console.log('onClick enter:', user);
                setCustomerDetail(dispatch, user);
            }}
        >
            <div className="list_item">
                <p className="custom_company">{(user.role === "L3")?"Landscaping":"Dealer"}</p> 
                <p className="custom_company">{user.company}</p> 
                <p className="custom_name">{user.name}</p> 
                <p className="custom_email">{user.email}</p> 
                <p className="custom_job">{user.job}</p> 
                <p className="custom_phone">{user.phone}</p> 
                <p className="custom_address">{user.address}</p> 
            </div>
            {/* <div className="list_item">
                {dataToShow.map(data  => (<p>{data.name}</p>))}
                <p className="custom_name">{users.name}</p> 
                <p className="custom_address">{users.address}</p> 
                <p className="custom_phone">{users.phone}</p> 
                <p className="custom_robot">{users.robots} Robots</p> 
            </div> */}
        </Link>
    );
}
