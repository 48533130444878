import React, { memo, useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Text, Row, Col, Modal, Alert, message } from 'antd';
import { StoreContext } from "../../store"
import ReportItem from "../../components/report/ReportItem";
import firebase from 'firebase/app'
import ModalImage from "react-modal-image";
import { setReports, setReportDetail } from "../../actions";
import { Space, Table, Spin, Tooltip, Tag, Typography,  Drawer, Descriptions } from 'antd';
import { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, Popover } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs'
import moment from 'moment';
import ErrorItem from "../../components/report/ErrorItem";
import { CSVLink, CSVDownload } from "react-csv";
import { Timestamp } from 'firebase/firestore';

import { forwardRef, useImperativeHandle } from "react"

import { stopTheTaskAsync, stopTheTaskAsync_funcs, docBackup } from "../../Api"


const TaskDetail = () => {
  const { state:{ reports:{ reports, reportDates, reportcompFilter, reportareaFilter, reporterrTagsFilter }, userSignin: { userInfo } } , dispatch} = useContext(StoreContext);
  const [data, setData] = useState(reports);
  
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentDataSource, setCurrentDataSource] = useState(reports);
  const [isLoading, setIsLoading] = useState(false);
  const [compFilter, setCompFilter] = useState(reportcompFilter);
  const [areaFilter, setAreaFilter] = useState(reportareaFilter);
  const [errTagsFilter, setErrTagsFilter] = useState(reporterrTagsFilter);
  const [status, setStatus] = useState("idle");
  const [dates, setDates] = useState(reportDates);
  const [task, setLocalTask] = useState({});
  const searchInput = useRef(null);

  const { RangePicker } = DatePicker;
  const { Text } = Typography;
  const { TextArea } = Input;

  //const { token } = Theme.useToken();
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const ref = useRef(null);

  const isL0 = () => {
    return (userInfo[1].claims.role === "L0");
  }
  
  const [dataCSV, setDataCSV] = useState([]);

  
  const showDrawer = () => {
    setOpen(true);
  };  
  const onClose = () => {
    setOpen(false);
    ref.current.reset();
  };



  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //selectedKeys[0] search string
    //dataIndex: the column name
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    confirm();  //Henry: Add
    setSearchedColumn(dataIndex);//Henry: Add
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters,selectedKeys, confirm, dataIndex) }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true })
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      //timeStr(record[dataIndex]).toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Start",
      dataIndex: 'timestamp',
      key: 'timestamp',
      //width: '30%',
      //...getColumnSearchProps('timestamp'),
      sorter: (a, b) => (a.timestamp.getTime()>b.timestamp.getTime())?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder:'descend',
      render: (date) => timeStr(date)
      //render: (text) => <ModalImage medium="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/2880px-Google_2015_logo.svg.png" alt={text}/>,
    },
    
    {
      title: "Company",
      dataIndex: 'company',
      key: 'company',
      //width: '30%',
      //...getColumnSearchProps('company'),
      sorter: (a, b) => a.company.localeCompare(b.company),//a.company.toUpperCase() > b.company.toUpperCase(),
      sortDirections: ['ascend', 'descend', 'ascend'],
      filters: compFilter,
      onFilter: (value, record) => record.company===value,
    },
    {
      title: 'User',
      dataIndex: 'user_name',
      key: 'user_name',
      //width: '30%',
      ...getColumnSearchProps('user_name'),
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      //width: '30%',
      ...getColumnSearchProps('type'),
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: "Area",
      dataIndex: 'zone_name',
      key: 'zone_name',
      //width: '30%',
      //...getColumnSearchProps('zone_name'),
      sorter: (a, b) => a.zone_name.localeCompare(b.zone_name),
      sortDirections: ['ascend', 'descend', 'ascend'],
      filterSearch: true,
      filters: areaFilter,
      onFilter: (value, record) => record.zone_name===value,
    },
    {
      title: "Robots",
      dataIndex: 'robots_model',
      key: 'robots_model',
      //width: '30%',
      ...getColumnSearchProps('robots_model'),
      sorter: (a, b) => (a.robots_model.length>b.robots_model.length)?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (_, { robots_model }) => (
        <> 
          {robots_model.map((robot) => {
            let color = 'geekblue';
            return (
              <Tag color={color} key={Math.floor(Math.random() * 9999)}>
                {robot}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: <div> Blade Height&nbsp;
      <Tooltip placement='bottom' title="inch">
      <InfoCircleOutlined />
      </Tooltip>
    </div>,
      dataIndex: 'blade_height',
      key: 'blade_height',
      //width: '30%',
      //...getColumnSearchProps('company'),
      sorter: (a, b) => (a.blade_height>b.blade_height)?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      //filters: [{text:"<10min", value: {min:0, max:60*10-1}},{text:"10min-60min", value: {min:60*10, max:60*60}},{text:">1hour", value: {min:60*60, max:60*60*9999}}],
      //filters: [{text:"<10min", value: [0,60*10-1]},{text:"10min-60min", value: [60*10,60*60-1]},{text:"1h-6h", value: [60*60,60*60*6-1]},{text:">6hour", value: [60*60*6,60*60*9999]}], 
      //onFilter: (value, record) => _.inRange(record.duration, value[0], value[1]),
      //render: (seconds) => secondsToHms(seconds)
    },
    {
      title: <div> Mode&nbsp;
      <Tooltip placement='bottom' title="Mowing mode">
      <InfoCircleOutlined />
      </Tooltip>
    </div>,
      dataIndex: 'mode',
      key: 'mode',
      //width: '30%',
      //...getColumnSearchProps('company'),
      //sorter: (a, b) => a.company.toUpperCase() > b.company.toUpperCase(),
      //sortDirections: ['ascend', 'descend', 'ascend'],
      filters: [{text:"Fast", value: "fast"},{text:"Regular", value: "regular"},{text:"Traction", value: "traction"}],
      onFilter: (value, record) => record.mode===value,
    },
    {
      title: <div> Sonar&nbsp;
      <Tooltip placement='bottom' title="Sonar On/Off">
      <InfoCircleOutlined />
      </Tooltip>
    </div>,
      dataIndex: 'sonar_on',
      key: 'sonar_on',
      //width: '30%',
      //...getColumnSearchProps('company'),
      //sorter: (a, b) => a.company.toUpperCase() > b.company.toUpperCase(),
      //sortDirections: ['ascend', 'descend', 'ascend'],
      filters: [{text:"On", value: "On"},{text:"Off", value: "Off"}],
      onFilter: (value, record) => record.sonar_on===value,
    },
    {
      title: 'Warnings',
      key: 'errors_tag',
      dataIndex: 'errors',
      sorter: (a, b) => (a.errors.length>b.errors.length)?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      filters: errTagsFilter,
      onFilter: (value, record) => record.errTags.includes(value),
      render: (_, { errors }) => (
        <>
          {errors.map((tag) => {
            let color = tag.notification_slug.length > 5 ? 'geekblue' : 'green';
            if (tag.notification_slug === 'BUMPER') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={Math.floor(Math.random() * 9999)}>
                {tag.notification_slug.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];
  function secondsToHms(d) {

    if(d===0) return "0min";

    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h' : 'h') : "";
    var mDisplay = m > 0 ? m + (m == 1 ? 'm' : 'm') : "";
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : "";
    // return hDisplay + mDisplay + sDisplay; 

    if ((h <= 0) && (m <= 0 )) {
        return "1min";
    }
    return hDisplay + " " + mDisplay;
  }

  function timeStr(date){
    return new Intl.DateTimeFormat('en-us', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }).format(date);
  }

  function timeShortStr(date){
    return new Intl.DateTimeFormat('en-us', {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

  function asyncGetRobots(task) {

    return new Promise((resolve, reject) => {
      console.log("task:", task);
      console.log("task.devices:", task.devices);
      let itemRefs = task.devices.map(id => {
        return firebase.firestore().collection('robots').doc(id).get();
      });
      Promise.all(itemRefs)
      .then(docs => {
        let items = docs.map(doc => doc.data());
        console.log("items:", items);
      })
      .catch(error => console.log(error))
      // const refs = task.devices.map(id => firebase.firestore().collection('robots').doc(`${id}`))
      // firebase.firestore().getAll(...refs).then(robots => console.log(robots.map(doc => doc.data())))
      // //console.log("task_result:", task_result);
      // resolve("task_result");
    });    
  }

  function asyncGetMesgs(task) {

    return new Promise((resolve, reject) => {   
      const query = firebase.firestore().collection('users').doc(task.uid).collection('messages').where("timestamp", ">", task.timestamp);
      query.get().then((querySnapshot) => {
        let errors = [];
        let infos = [];
        let d_id = task.devices;
        querySnapshot.forEach((message) => {
          var result = d_id.find(item => item === message.data().notification_subject_id);
          var errorTime = timeStr(message.data().timestamp.toDate());
          if(result === undefined){
            return;
          }
          if(message.data().notification_slug === "RL_1_1" || message.data().notification_type !== "dev_events"){
            infos.push({id: message.id, time: errorTime, ...message.data()});
          }else{
            errors.push({id: message.id, time: errorTime, ...message.data()});
          }
        });
        
        let errTags = [...new Set(errors.map(a => a.notification_slug))];
        let task_result = {...task, errTags: errTags, errors: errors, infos: infos };
        //let report = {id: id, company: company, user: user, zone_name: zone_name, timestamp: beginTime, timestamp_end: endTime, duration: duration, devices: devices, d_id: d_id, complete: complete, errors: errors, infos: infos, errTags: errTags, memo:memo};
        console.log("task_result:", task_result);

        let itemRefs = task_result.devices.map(id => {
          return firebase.firestore().collection('robots').doc(id).get();
        });
        Promise.all(itemRefs)
        .then(docs => {
          task_result.robots_model = docs.map(doc => {return (doc.exists)?doc.data().model:"unknown";});
          resolve(task_result);
        })
        .catch(error => console.log(error))
      }); 
    });   
  }
  function asyncGetTasks(account, tasks) {

    return new Promise((resolve, reject) => {   
      const query = firebase.firestore().collection('users').doc(account.uid).collection('tasks');
      query.get().then((querySnapshot) => {
        
        let tasks = [];
        querySnapshot.forEach((task) => {
          console.log("task:", account.uid, account.name, task.id, task.data(), task.data().timestamp instanceof firebase.firestore.Timestamp);
          let id = task.id;
          let uid = account.uid;
          let type = (_.has(task.data(),'task_type'))?task.data().task_type:"";
          var timestamp = (_.has(task.data(),'timestamp') && task.data().timestamp instanceof firebase.firestore.Timestamp)?task.data().timestamp.toDate(): new Date();

          let user = account;
          let user_name = account.name;
          let email = account.email;
          let company = account.company;

          let devices = (_.has(task.data(),'robots_id'))?task.data().robots_id: [];

          let zone_name = (_.has(task.data(),'zone.name'))?task.data().zone.name:"";
          let zone_id = (_.has(task.data(),'zone_id'))?task.data().zone_id:"";
          
          let path_angle = (_.has(task.data(),'zone.path_angle'))?task.data().zone.path_angle:"";
          let path_angle_auto = (_.has(task.data(),'zone.path_angle_auto'))?task.data().zone.path_angle_auto:"";

          let blade_height = (_.has(task.data(),'zone.config.blade_height'))?task.data().zone.config.blade_height:"";
          let mode = (_.has(task.data(),'zone.config.mode'))?task.data().zone.config.mode:"";
          const mode_str = {normal: 'regular', sport: 'fast', slope: 'traction'};
          mode = mode_str[mode];
      
          let sonar_on = (_.has(task.data(),'zone.config.sonar_on'))?task.data().zone.config.sonar_on:"";
          sonar_on = sonar_on?"On":"Off";
          tasks.push({key: id, id: id, uid:uid, type: type, timestamp:timestamp, user: user, user_name:user_name, email: email, company: company, devices: devices, zone_name:zone_name, zone_id:zone_id, path_angle: path_angle, path_angle_auto:path_angle_auto, blade_height: blade_height, mode: mode, sonar_on:sonar_on});
        });
        
        if(tasks.length === 0){
          resolve([]);
          return;
        }
        const promises = [];
        tasks.map(task => promises.push(asyncGetMesgs(task)));
      
        return Promise.all(promises).then(tasks => {
          console.log('Tasks for account:', tasks, account.email);
          resolve(tasks);
        }).catch((error) => {
            console.log(error)
        });
      });  
    });
  }
    
  const getReports =  async (e) => {
    //console.log('getReports: ', dates);
    const account = [];
    const reports = [];

    var s_date = dates[0].toDate();
    s_date.setHours(0, 0, 0, 0);
    var e_date = dates[1].toDate();
    e_date.setHours(23, 59, 59, 99);

    setIsLoading(true);

    let time1 = new Date();

    //Add myself into this ilist
    //account.push({name: userInfo[0].email, uid: userInfo[1].claims.uid, company: userInfo[1].claims.company});
    
    //Add all my company users into this list
    const docRef =  firebase.firestore().collection("users").where("company", "==", userInfo[1].claims.company);
    try {
      var querySnapshot = await docRef.get()
      querySnapshot.forEach((doc) => {
        account.push({user: doc.data(), name: doc.data().name, email: doc.data().email, uid: doc.data().uid, company: doc.data().company});
      });
    } catch (err) {
        console.log("Error getting document:", err);
    }
    const docRef2 =  firebase.firestore().collection("users").where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company});
    try {
      var querySnapshot2 = await docRef2.get()
      querySnapshot2.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          account.push({user: doc.data(), name: doc.data().name, email: doc.data().email, uid: doc.data().uid, company: doc.data().company});
          //const reportRef = firebase.firestore().collection('reports').where("user.uid", "==", doc.data().uid);
      });
      console.log("Accounts to search:", account);
    } catch (err) {
        console.log("Error getting document:", err);
    }

    setStatus(`Retreive running tasks...`);
    //const query = firebase.firestore().collection('reports').where("timestamp", ">", s_date).where("timestamp", "<", e_date).orderBy("timestamp", "desc");
    try {
      var tempTasks = [];
      
      //setStatus(`Processing ${i++}/${all} report...`);
      const promises = [];
      // for ( const report of tempReports){
      //   setStatus(`Processing ${i++}/${all} report...`);
      //   promises.push(asyncGetMesgs(report, tempReports));
      // }
      account.map(account => promises.push(asyncGetTasks(account, tempTasks)));
      //console.log("reports:", reports);
      console.log("Total acounts to find tasks:", account.length);
      //setStatus(`${tempReports.length} report found, processing...`);

      return Promise.all(promises).then(tasks => {

        let time2 = new Date();
        let diff = time2.getTime() - time1.getTime();
        console.log('Promise response from all accounts:', tasks);
        var result = [];// tasks.filter(e => e.length);
        for ( const task of tasks){
          result.push(...task);
        }
         console.log('Tasks of all accounts:', result);


        setData(result);
        setCurrentDataSource(result);
        let companies = [...new Set(result.map(a => a.company))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        setCompFilter(companies);
        let areas = [...new Set(result.map(a => a.zone_name))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        setAreaFilter(areas);

        let errTags = [];
        result.map(task => errTags.push(...task.errTags));
        errTags = [...new Set(errTags)].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        // //console.log("errTags:", errTags);
        setErrTagsFilter(errTags);
        setStatus(`Query Finished! Search time:${diff/1000}s`);
        setIsLoading(false);
      }).catch((error) => {
          console.log(error)
      });
    } catch (err) {
        console.log("Error getting document:", err);
    }
  }  
  function sort_Errors(Errors, order){
    function desc( a, b ) {
      if ( a.timestamp.seconds < b.timestamp.seconds ){
        return 1;
      }
      if ( a.timestamp.seconds > b.timestamp.seconds ){
        return -1;
      }
      if ( a.timestamp.nanoseconds > b.timestamp.nanoseconds ){
        return -1;
      }else{
        return 1;
      }
    }
    function aesc( a, b ) {
        if ( a.timestamp.seconds < b.timestamp.seconds ){
          return -1;
        }
        if ( a.timestamp.seconds > b.timestamp.seconds ){
          return 1;
        }
        if ( a.timestamp.nanoseconds > b.timestamp.nanoseconds ){
          return 1;
        }else{
          return -1;
        }
      }
    if(order === "AESC"){
      Errors.sort(aesc)
    }else{
      Errors.sort(desc)
    }
    return Errors;
  }
  function mesgHandler(report) {
    let mesgs = [];
    if(report.errors){
      mesgs = mesgs.concat(report.errors);
    }
    if(report.infos){
      mesgs = mesgs.concat(report.infos);
    }
    sort_Errors(mesgs, "DESC");
    report.mesgs = mesgs;
  }

  function disabledTime(date) {  
    console.log("disabledTime",date);
    return true;
    //return current > moment() || current < moment().subtract(3, 'day');
  }

  return (
    <div className="layout_limit">
      <Drawer title="Task Detail" placement={'right'} 
      onClose={onClose} open={open} visible={open} size='large' width={"50%"}
      extra={
          !isL0()?null:
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
      }
      >
        <TaskDetailItem props={{task:task , userInfo:userInfo}} ref = {ref}/>
      </Drawer>

      <div className="mainform-wrapper">
      <p className="main-title report_title">Task Management({data.length})</p> 
      </div>
     
      <hr className="hr-line" />

      <div className = "report-search task-search">
        
        <Button htmlType="submit" onClick={async (e) => await getReports()} loading={isLoading}>
          Query
        </Button>
        <p className="report_search_desc">Status: {status}</p> 
      </div>

      <Table 
        className="generaltable"
        showHeader = "true"
        key = "Table"
        title = {(currentPageData) => {
          return `Number of filtered data: ${currentDataSource.length}`;
          //return `<p className="main-title">Robots list()</p>`
        }}
        size="large" 
        loading={isLoading}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {mesgHandler(record);setLocalTask(record);showDrawer();}, // click row
          };
        }}
        onChange={(pagination, filters, sorter, extra) => setCurrentDataSource(extra.currentDataSource)}
        dataSource={data} 
        columns={isL0()?columns:columns.slice(0, -1)} 
        pagination={{ defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['25', '50', '100', '500']}}
        scroll={{ x: 350 }}
        summary={(pageData) => {
          let totalErrors = 0;
          let totalDuration = 0;
          let Errors = [];
          pageData.forEach(({ errTags, errors, duration }) => {
            totalErrors += errors.length;
            totalDuration += duration;
            Errors = Errors.concat(errors);
          });
          let counts = {};
          for (const error of Errors) {
            counts[error.notification_slug] = counts[error.notification_slug] ? counts[error.notification_slug] + 1 : 1;
          }
          let summary = "";
          for (const [key, value] of Object.entries(counts)) {
            summary += `${key}:${value}, `;
          }
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  <Text type="danger">{totalErrors}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}>
                <Text keyboard="true">{summary}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};
export default TaskDetail;

const TaskDetailItem = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showAlert() {
      alert("Hello from Child Component")
    },
    reset(){
      //setMemoText(report.memo.text);
      console.log("reset");
      setDisabled(true);
      form.resetFields();
    },
    value: memoText
  }))

  let task = props.props.task;
  let userInfo = props.props.userInfo;
  //console.log("ReportDetailItem report:", props);
  const [memoText, setMemoText] = useState("");
  const { TextArea } = Input;
  const [isLoading, setIsLoading] = useState(false);

  const isL0 = () => {
    return (userInfo[1].claims.role === "L0");
  }
  const isL0L1 = () => {
    return (userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1");
  }

  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const SECURITY_CODE = '82884839';

  const onFinish = (values) => {
    console.log('Success:', values);
    if(values.passcode === SECURITY_CODE){
      message.success('Submit success!');
      setDisabled(false);
    }else{
      message.error('Submit failed!');
    }
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Submit failed!');
  };


  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h' : 'h') : "";
    var mDisplay = m > 0 ? m + (m == 1 ? 'm' : 'm') : "";
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : "";
    // return hDisplay + mDisplay + sDisplay; 

    if ((h <= 0) && (m <= 0 )) {
        return "1min";
    }
    return hDisplay + " " + mDisplay;
  }

  function timeStr(date){
    return new Intl.DateTimeFormat('en-us', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }).format(date);
  }

  async function stopTask(task_param){

    let task = {
      zone_id: task_param.zone_id,
      robots_id: [],
      user: task_param.user,
      company_id: task_param.user.company,
      task_id: task_param.id
    }

    var cs_name = prompt("Leave your name please!", "");

    var code = prompt("Confirm security code again", "Security Code");
    //alert("你的暱稱是" + nickname);
    if(code !== SECURITY_CODE){
      alert("Incorrect security code!");
      return;
    }
    var answer = window.confirm("Are you sure to stop this task?");
    if(!answer){
      return;
    }
    var answer2 = window.confirm("Final confirm!!! click OK to stop the task!");
    if(!answer2){
      return;
    }
    setIsLoading(true);
    
    console.log("task_param:", task_param);
    console.log("task:", task);
    await docBackup(task, cs_name, userInfo[0].email);
    
    let result = await stopTheTaskAsync_funcs(task);

    if(result === 'error'){
      alert("Error to end task. Possible reasons: this task is terminated by users. Refresh this page and try again. If task exist and can't terminate it, contact tech team.");
    }else{
      alert("Task is terminated, please refresh this page!");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    //setMemoText(report.memo.text);
    //console.log("useEffect:", report.memo.text);
  }, [task]);

  return (
    <div className="list-wrapper">
    <div className="report_info_wrapper">
    <Descriptions title="Task Info" layout="vertical">
      <Descriptions.Item label={<span style={{color:'grey'}}>Start</span>} >{<span style={{paddingLeft:'10px'}}>{task.timestamp?timeStr(task.timestamp):null}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Compnay</span>}>{<span style={{paddingLeft:'10px'}}>{task.company}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>User</span>}>{<span style={{paddingLeft:'10px'}}>{task.user_name}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Area</span>}>{<span style={{paddingLeft:'10px'}}>{task.zone_name}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Robots</span>}>{<span style={{paddingLeft:'10px'}}>{<> 
      {task.robots_model?task.robots_model.map((robot) => {
        let color = 'geekblue';
        return (
          <Tag color={color} key={Math.floor(Math.random() * 99999)}>
            {robot}
          </Tag>
        );
      }):null}
    </>}</span>}
      </Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Blade Height(inch)</span>}>{<span style={{paddingLeft:'10px'}}>{task.blade_height}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Mode</span>}>{<span style={{paddingLeft:'10px'}}>{task.mode}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Sonar</span>}>{<span style={{paddingLeft:'10px'}}>{task.sonar_on}</span>}</Descriptions.Item>
    </Descriptions>
          
    <div className="list_item">            
        <p className="account_job list_title">Mesg Type</p>
        <p className="account_job list_title">Time</p>
        <p className="account_job list_title map_detail">Error</p>
        <p className="account_job list_title map_detail">Robot</p>
        <p className="report_image list_title">Event Snapshot</p> 
      </div>
        <Row gutter={[0,8]}>
          {task.mesgs?task.mesgs.map(mesg => (
            <Col 
            key={mesg.id}
            sm={{ span: 24 }} 
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
            >
            <ErrorItem error={mesg}/>
            </Col>
          )):null}
        </Row>

      <Form
        form = {form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 400, marginLeft:'0px', marginTop:'30px' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
      {isL0L1()?(
          <>
        <Form.Item
          label="Engineering mode"
          name="passcode"
          rules={[{ required: true, message: 'Please input password' }]}
        >
          <Input placeholder= "Please input security code!" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Enter
          </Button>
        </Form.Item>
        </>
        ):null}


        {!disabled?(
          <>
        <Button
          type="primary"
          size="small"
          style={{
            width: 90,
            marginLeft:'70px', 
            marginTop:'20px'
          }}
          onClick={async (e) => await stopTask(task)} loading={isLoading}
        >
          Stop Task
        </Button>
        </>
        ):null}
      </Form>




    </div>
    </div>
  );
})

