import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, notification, Modal, Row, Col } from 'antd';
import Icon from '@mdi/react'
import { mdiAccessPointNetwork } from '@mdi/js';
import { StoreContext } from "../../store";
import { setUserInfoApi, setCustomerDetail, updateUserInfo } from "../../actions";
import RobotItem from "../robot/RobotItem";
import firebase from 'firebase/app'
import { update_customer_funcs, dp_log } from "../../Api"

const CustomerItemDetail = () => {
  const { state: {customerDetail:{ user }, userSignin: { userInfo }}, dispatch } = useContext(StoreContext);
  const [robots, setRobotData] = useState([]);
  const [dataShow, setUserData] = useState([]);
  const [userData, updateFormUserData] = useState(user);
  const history = useHistory();
  const [form] = Form.useForm();
  const CustomerMag = () => {
    history.push("/customermag");
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = async (event) => {
    console.log("handleUpdate:" ,userData);
    setIsModalVisible(false);
    //const result = await update_customer_funcs(userInfo, userData);
    if(userData.id !== "undefined"){
      let setWithMerge =  await firebase.firestore().collection("users").doc(userData.id).set({
          address:userData['address'],
          address_zip:userData['address_zip'],   
          name:userData['name'],
          job:userData['job'],
          phone:userData['phone']
      }, { merge: true });
      
      setWithMerge =  await firebase.firestore().collection("users").doc(userData.id).collection("memo").doc(userInfo[1].claims.role+"-"+userInfo[1].claims.company).set({
        memo:userData['memo']
      }, { merge: true });
      dp_log({user:userInfo, action: "update_Customer", item: userData.id, meta: JSON.stringify({user: userData})});
    }

    history.push("/customermag");
  };

  const handleChange = (event) => {
    updateFormUserData({
      ...userData,
      [event.target.name]: event.target.value.trim()
    });
    //console.log("userData:" ,userData);
  };

  useEffect(() => {
    //console.log('CustomerItemDetail useEffect1 enter:', user);

    let memo = "";
    //const users = [];
    const memoRef = firebase.firestore().collection("users").doc(user.id).collection("memo").doc(userInfo[1].claims.role+"-"+userInfo[1].claims.company);
    memoRef.get().then((memo_doc) => {
      memo = (memo_doc.exists && memo_doc.data().memo)?memo_doc.data().memo:"";
      //users.push({name : user.name, company : user.company, email: user.email, phone: user.phone,
      //  address: user.address, address_zip: user.address_zip, job: user.job, id : user.id, role: user.role, memo: memo})
      //users.push({...user, memo:memo});
      setUserData([{...user, memo:memo}]);
      updateFormUserData({...user, memo:memo});
      localStorage.setItem("user", JSON.stringify(user));
    })
    .catch((error) => {
      console.log("Error getting memo: ", error);
      //users.push({...user, memo:memo});
      setUserData([{...user, memo:memo}]);
      updateFormUserData({...user, memo:memo});
      localStorage.setItem("user", JSON.stringify(user));
    });

    const docRef = firebase.firestore().collection('robots').where("company", "==", user.company).where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company})
    docRef.get().then((querySnapshot) => {
      const robot = [];
      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          var war_string = "In stock"
          if(doc.data().warranty && doc.data().warranty.activate){
            var beginTime = new Intl.DateTimeFormat('en-us', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }).format(doc.data().warranty.selling_date.toDate());
            var endTime = new Intl.DateTimeFormat('en-us', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }).format(doc.data().warranty.warranty_end_date.toDate());
            //var war_string = `Warranty from ${doc.data().warranty.warranty_start_date.toDate().toDateString()} - ${doc.data().warranty.warranty_end_date.toDate().toDateString()}`
            war_string = `Warranty  ${beginTime} - ${endTime}`
          }
          
          //robot.push({psn : doc.data().warranty.psn, model : doc.data().model, warranty: war_string, company: doc.data().company, id : doc.data().updated_time});
          let psn = (doc.data().warranty && doc.data().warranty.psn)?doc.data().warranty.psn:"";
          let model = (doc.data().model)?doc.data().model:"";
          let company = (doc.data().company)?doc.data().company:"";
          let id =  doc.id;
          robot.push({psn: psn, model: model, warranty: war_string, company: company, id: id});
      });
      setRobotData(robot);
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
  }, []);

    const { TextArea } = Input;
    return (
      <div>
        {dataShow.map(user => {
          return (
            <div className="layout_limit" key={user.id} >
              <div className="accform-wrapper">
              <img src="/images/menu_back_btn.png" onClick={CustomerMag}/>
                <p className="acc-title">{user.email}</p> 
                  <Button
                    htmlType="submit"className="account_remove__button acc_btn" onClick={showModal}
                  >
                    Update
                  </Button>
                  <Modal title="NEXMOW management system" visible={isModalVisible} 
                    onOk={handleUpdate} onCancel={handleCancel}>
                    <p>Are you sure to update data of this customer?</p>
                  </Modal>
                  
              </div>
                
              <div className="accform-wrapper">
                <Form
                  name="normal_login"
                  className="login-form_list"
                  form={form}
                  layout="vertical"
                  //onFinish={handleUpdate}
                  //onChange={val => setCustomerDetail(dispatch)}
                  onChange = {handleChange}
                  initialValues={user}
                  
                >
                  <Form.Item 
                      label="Customer type" required 
                      className="account_detail_form"
                      hasFeedback>
                      <p className="user_company">{(user.role === "L3")?"Landscaping":"Dealer"}</p> 
                  </Form.Item>
                  <Form.Item 
                      label="Company name" required 
                      className="account_detail_form"
                      hasFeedback>
                      <p className="user_company">{user.company}</p> 
                  </Form.Item>
                  <Form.Item 
                      label="Email address" required 
                      className="account_detail_form"
                      hasFeedback>
                      <p className="user_email">{user.email}</p> 
                  </Form.Item>
                  <Form.Item 
                      label="Name" required 
                      className="account_detail_form"
                      hasFeedback>
                      <Input  placeholder={user.name} name="name" onChange={handleChange} />
                  </Form.Item>
                </Form>
                <Form
                  name="normal_login"
                  //className="login-form_list"
                  form={form}
                  layout="vertical"
                  //onFinish={handleUpdate}
                  //onChange={val => setCustomerDetail(dispatch)}
                  onChange = {handleChange}
                  initialValues={user}
                >
                  <Form.Item
                      label="Phone number (optional)"
                      className="account_detail_form"
                      hasFeedback
                  >
                      <Input placeholder={user.phone} name="phone" />
                  </Form.Item>
                  <Form.Item
                      label="Address (optional)"
                      className="account_detail_form"
                      hasFeedback
                  >
                      <Input placeholder={user.address} name="address" />
                  </Form.Item>
                  <Form.Item
                      label="Zip code (optional)"
                      className="account_detail_form"
                      hasFeedback
                  >
                      <Input placeholder={user.address_zip} name="address_zip" />
                  </Form.Item>
                  <Form.Item
                      label="Job title (optional)"
                      className="account_detail_form"
                      hasFeedback
                  >
                      <Input placeholder={user.job} name="job"/>
                  </Form.Item>
                  {/* <Form.Item>
                    <Button
                      htmlType="submit"className="update_btn space_btn" 
                    >
                      Update
                    </Button>
                  </Form.Item> */}
                </Form>
              </div>
              <Form
                  name="normal_login"
                  form={form}
                  layout="vertical"
                  onChange = {handleChange}
                  initialValues={user}
              >
                <Form.Item
                    label="Memo"
                    className="detail_input_form"
                    hasFeedback
                >
                    <TextArea defaultValue={user.memo} rows={10} name="memo" />
                </Form.Item>
              </Form>
              <div className="list-wrapper">
                  <div>
                    <p className="main-title">Robots list({robots.length})</p> 
                      <Row gutter={[0,8]}>
                        {robots.map(robot => (
                          <Col 
                          key={robot.id} 
                          sm={{ span: 24 }} 
                          lg={{ span: 24 }}
                          xl={{ span: 24 }}
                          xxl={{ span: 24 }}
                          >
                          <RobotItem robot={robot}/>
                          </Col>
                        ))}
                      </Row>
                  </div>
              </div>
            </div>
          );
        })}
      </div>
    );
};
export default CustomerItemDetail;