import React, { useContext, useEffect,useState } from "react";
import { useHistory,Link } from "react-router-dom";
import { Form, Input, Button, Text, Col, Row, Drawer, Space } from 'antd';
//import { StoreContext } from "../../store"
import TransferRobotItemPSN from "../robot/TransferRobotItemPSN";
import robots from "../../json/robots.json";
import TransferRobotdrawerItem from "../robot/TransferRobotdrawerItem";
import firebase from 'firebase/app'

const RentRobotlistPSN = () => {
  const [dataToShow, setData] = useState([]);
  const [dataShow, setuserData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState('top');

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
  const userdocRef =  firebase.firestore().collection("users");
    userdocRef.get().then((querySnapshot) => {
        const user = [];
        querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            user.push({name : doc.data().company, email: doc.data().email, id : doc.data().uid})
        });
        setuserData(user);
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
  }, []);

  useEffect(() => {
  
  }, []);

  return (
    <div className="layout_limit"> 
      <div className="mainform-wrapper">
        <p className="main-title">Rent robots</p>
          <Button
            htmlType="submit" className="psnfinish__button" onClick={showDrawer}
          >
            Confirm
          </Button>
          <Drawer
            title="NEXMOW management system"
            placement={placement}
            height={700}
            onClose={onClose}
            visible={visible}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="primary" onClick={onClose}>
                  OK
                </Button>
              </Space>
            }
          >
            <p className="drawer_word">Please select one company to transfer the robot.</p>
            <div className="list-wrapper">
              <Row gutter={[0,8]}>
                {dataShow.map(user => (
                    <Col 
                    key={user.id} 
                    sm={{ span: 24 }} 
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                    xxl={{ span: 24 }}
                    >
                    <TransferRobotdrawerItem user={user}/>
                    </Col>
                ))}
              </Row>
            </div>
          </Drawer>
      </div>
      <hr className="hr-line" />
      <Link to="/transferrobot">
        <img src="/images/menu_back_btn.png" className="back_s_btn"/>
      </Link>
      <div className="robotnum_wrapper">
            <p className="main-title">Robots list ()</p> 
            <p className="robottrans_notice">Please input the last 4 PSN, or scan the barcode directly</p> 
          </div>
      <div className="list-wrapper">
        <Row gutter={[0,8]}>
            {dataToShow.map(robot => (
                <Col 
                key={robot.id} 
                sm={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                >
                <TransferRobotItemPSN robot={robot}/>
                </Col>
            ))}
          </Row>
        {/* <div className="list_item robot_list_item">
          <p className="robot_sn_psn">SN M1B2A1MC0007</p> 
          <Input.Group size="small" className="psn_inputform">
            <Row gutter={2}>
                <p className="robot_confirm">Confirm PSN</p>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
            </Row>
          </Input.Group>
        </div>
        <div className="list_item robot_list_item">
          <p className="robot_sn_psn">SN M1B2A1MC0009</p> 
          <Input.Group size="small" className="psn_inputform">
            <Row gutter={3}>
                <p className="robot_confirm">Confirm PSN</p> 
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
            </Row>
          </Input.Group>
        </div>
        <div className="list_item robot_list_item">
          <p className="robot_sn_psn">SN M1B2A1MC0011</p> 
          <Input.Group size="small" className="psn_inputform">
            <Row gutter={3}>
                <p className="robot_confirm">Confirm PSN</p> 
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
            </Row>
          </Input.Group>
        </div>
        <div className="list_item robot_list_item">
          <p className="robot_sn_psn">SN M1B2A1MC0015</p> 
          <Input.Group size="small" className="psn_inputform">
            <Row gutter={3}>
                <p className="robot_confirm">Confirm PSN</p> 
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
            </Row>
          </Input.Group>
        </div>
        <div className="list_item robot_list_item">
          <p className="robot_sn_psn">SN M1B2A1MC0017</p> 
          <Input.Group size="small" className="psn_inputform">
            <Row gutter={3}>
                <p className="robot_confirm">Confirm PSN</p> 
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
            </Row>
          </Input.Group>
        </div>
        <div className="list_item robot_list_item">
          <p className="robot_sn_psn">SN M1B2A1MC0020</p> 
          <Input.Group size="small" className="psn_inputform">
            <Row gutter={4}>
                <p className="robot_confirm">Confirm PSN</p> 
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
                <Col span={1}><Input style={{ padding: 3}}/></Col>
            </Row>
          </Input.Group>
        </div> */}
      </div>
    </div>
  );
};
export default RentRobotlistPSN;