import { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import { setBranchDetail } from "../../actions";

export default function BranchItem({ branch }) {
    const { dispatch } = useContext(StoreContext);
    const [dataToShow, setData] = useState([]);

    return (
        <Link to={`/branchitem/${branch.name}`}
          onClick={() => {
            setBranchDetail(dispatch, branch);
          }}
        >
          <div className="list_item">
            <p className="account_company">{branch.company}</p> 
            <p className="acc_name">{branch.name}</p> 
            <p className="account_email">{branch.email}</p> 
            <p className="account_job">{branch.job}</p> 
            <p className="account_phone">{branch.phone}</p> 
            <p className="account_address">{branch.address}</p> 
          </div>
        </Link>
    );
}
