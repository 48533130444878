import { Layout } from 'antd';
import NavBar from "../../components/NavBar";
import RobotDetail from "../../components/robot/RobotDetail";
//import products from "../../json/products.json";

const { Content } = Layout;

function RobotMag({ match }) {
   // const product = products.find(
   //    x => x.id === match.params.productId
   // );
   return (
      <Layout className="container main-layout">
         <NavBar />
         <Layout className="bg-gray">
            <Content className="layout-content">
               <RobotDetail />
            </Content>
         </Layout>
      </Layout>
   );
}

export default RobotMag;