import { Layout } from 'antd';
import { useContext , useEffect} from "react"; 
import NavBar from "../../components/NavBar";
import CustomerDetail from "../../components/customer/CustomerDetail";
//import { setCustomerDetail } from "../../actions";
import { StoreContext } from "../../store"

const { Content } = Layout;

function CustomerMag({ match }) {
   const { dispatch } = useContext(StoreContext);

   // useEffect(() => {
   //    setCustomerDetail(dispatch, match.params.customerId, 0, match.params.category)
   // }, [])
   return (
      
      <Layout className="container main-layout">
            <NavBar />
         <Layout className="bg-gray">
            <Content className="layout-content">
               <CustomerDetail />
            </Content>
         </Layout>
      </Layout>
   );
}

export default CustomerMag;
