import React, { useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Text, notification, Modal } from 'antd';
import { StoreContext } from "../store";
import firebase from 'firebase/app'
import 'firebase/firestore';

const TutorialPage = () => {
    return (
        <div className="layout_limit" >
            <div className="addcustomer">
                <p className="addcustomer-title">Tutorial</p>
            </div>
            <hr className="hr-line" />
            <div className="tutorial-wrapper">
            <iframe width="80%" height="640" src="https://www.youtube.com/embed/tPP4Qaz-EW0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    );
};
export default TutorialPage;