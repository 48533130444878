import React, { useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Text, notification, Modal } from 'antd';
import { StoreContext } from "../../store";
import { setUserInfoApi, setAccountDetail, updateUserInfo } from "../../actions";
import { update_account_funcs } from "../../Api"

const MapItemDetail = () => {
  const [dataToShow, setData] = useState([]);
  const { state:{ mapDetail: { map } }, dispatch } = useContext(StoreContext);
  const history = useHistory();
  const [form] = Form.useForm();
 
  const MapMag = () => {
    history.push("/mapmag");
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = async (event) => {
    console.log("handleUpdate:" ,userData);
    setIsModalVisible(false);
    const result = await update_account_funcs(userData);
    MapMag();
  };

  const [userData, updateFormUserData] = useState(map);
  const handleChange = (event) => {
    //console.log(`userData.admin: ${userData.admin}`)
    updateFormUserData({
      ...userData,
      [event.target.name]: event.target.value.trim()
    });

    if(event.target.name === 'admin'){
      updateFormUserData({
        ...userData,
        [event.target.name]: event.target.checked
      });
    }
    //console.log("userData:" ,userData);
    //console.log(`${event.target.name}: ${event.target.checked}`)
  };

  console.log("BranchItemDetail Enter: ", map);
  //console.log("BranchItemDetail Enter: ", userData);
  useEffect(() => {
    console.log("useEffect:" ,userData);
    const maps = [];
    maps.push(map);
    setData(maps);
    localStorage.setItem("branch", JSON.stringify(map));
  }, [map]);

  return (
    <div>
      {dataToShow.map(map => {
        return (
          <div className="layout_limit" key={map.id} >
            <div className="accform-wrapper">
            <img src="/images/menu_back_btn.png" className="back_s_btn" onClick={MapMag}/>
              <p className="acc-title">{map.name}</p> 
                <Button
                  htmlType="submit"className="account_remove__button acc_btn" onClick={showModal}
                >
                  Update
                </Button> 
                <Modal title="NEXMOW management system" visible={isModalVisible} 
                  onOk={handleUpdate} onCancel={handleCancel}>
                  <p>Are you sure to update this account?</p>
                </Modal>
            </div>
              
            <div className="accform-wrapper">
              <Form
                  name="normal_login"
                  className="login-form_list"
                  form={form}
                  layout="vertical"
                  onChange={handleChange}
                  initialValues={map}
                >
                <Form.Item 
                    label="Company" required 
                    className="account_detail_form">
                    <p className="account_company">{map.company}</p> 
                </Form.Item>
                <Form.Item 
                    label="Email address" required 
                    className="account_detail_form">
                    <p className="account_email">{map.email}</p> 
                </Form.Item>
                <Form.Item 
                    label="Name" required 
                    className="account_detail_form">
                    <Input placeholder={map.name} name="name"/>
                </Form.Item>
                <Form.Item 
                    label="Administrator" required 
                    className="account_detail_form ">
                    <input className="robot_select" type="checkbox" value="admin" name="admin" checked={userData.admin} onChange={handleChange}/>
                </Form.Item>
              </Form>
              <Form
                  name="normal_login"
                  //className="login-form_list"
                  form={form}
                  layout="vertical"
                  onChange={handleChange}
                  initialValues={map}
                >
                <Form.Item
                    label="Phone number (optional)"
                    className="account_detail_form"
                >
                    <Input placeholder={map.phone} name="phone"/>
                </Form.Item>
                <Form.Item
                    label="Address (optional)"
                    className="account_detail_form"
                >
                    <Input placeholder={map.address} name="address"/>
                </Form.Item>
                <Form.Item
                    label="Zip code (optional)"
                    className="account_detail_form"
                >
                    <Input placeholder={map.address_zip} name="address_zip"/>
                </Form.Item>
                <Form.Item
                    label="Job title (optional)"
                    className="account_detail_form"
                >
                    <Input placeholder={map.job} name="job"/>
                </Form.Item>
              </Form>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default MapItemDetail;