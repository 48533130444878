import {
    SET_PAGE_TITLE,
    SET_PAGE_CONTENT,
    SET_DATA_DETAIL,
    SET_REPORTS,
    SET_NAVBAR_ACTIVEITEM,
    BEGIN_DATA_REQUEST,
    SUCCESS_DATA_REQUEST,
    FAIL_DATA_REQUEST,
    BEGIN_LOGIN_REQUEST,
    SUCCESS_LOGIN_REQUEST,
    FAIL_LOGIN_REQUEST,
    BEGIN_REGISTER_REQUEST,
    SUCCESS_REGISTER_REQUEST,
    FAIL_REGISTER_REQUEST,
    LOGOUT_REQUEST,
    REMEMBER_LOGIN,
    BEGIN_UPDATE_USERINFO,
    SUCCESS_UPDATE_USERINFO,
    FAIL_UPDATE_USERINFO,
    SAVE_NEW_ACCOUNT,
    SAVE_NEW_BRANCH,
    SAVE_NEW_CUSTOMER,
    SAVE_NEW_REGISTER,
    BEGIN_REQUEST_USERINFO,
    SUCCESS_REQUEST_USERINFO,
    FAIL_REQUEST_USERINFO,
    BEGIN_CUSTOMER_DETAIL,
    SUCCESS_CUSTOMER_DETAIL,
    FAIL_CUSTOMER_DETAIL,
    SET_SELECTED_ROBOTS
  } from "../utils/constants";
  
  import {
    signInWithEmailPassword,
    registerWithEmailPassword,
    signOut,
    updateUserInfoApi,
    setUserInfoApi,
    storefirebaseauth,
    checkLoginApi,
    getCustomerById,
    getRobotById,
    getAccountById,
    getNewCustomerById,
    getNewAccountById,
    getNewBranchById,
    getDealer
  } from "../Api";

  import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
  
  export const setPage = async (dispatch, url, title) => {
    dispatch({
      type: SET_PAGE_TITLE,
      payload: title,
    });
    try {
      dispatch({
        type: SET_PAGE_CONTENT,
        payload: { title },
      });
      dispatch({
        type: SET_NAVBAR_ACTIVEITEM,
        payload: url,
      });
    } catch (error) {
      console.log(error);
    }
  }

  export const setCustomerDetail =  async (dispatch, user) => {
    //console.log('setCustomerDetail: ', user);
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        dispatch({
          type: SET_DATA_DETAIL,
          payload: user
        })
      dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (error) {
      console.log(error);
      dispatch({ type: FAIL_DATA_REQUEST, payload: error });
    }
  }

  export const setRobotDetail =  async (dispatch, robot) => {
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        dispatch({
          type: SET_DATA_DETAIL,
          payload: {robot}
        })
      dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (error) {
      console.log(error);
      dispatch({ type: FAIL_DATA_REQUEST, payload: error });
    }
    localStorage.setItem('robot', JSON.stringify(robot));
  }

  export const setAccountDetail =  async (dispatch,account) => {
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        dispatch({
          type: SET_DATA_DETAIL,
          payload: account
        })
      dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (error) {
      console.log(error);
      dispatch({ type: FAIL_DATA_REQUEST, payload: error });
    }
  }

  export const setBranchDetail =  async (dispatch,branch) => {
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        dispatch({
          type: SET_DATA_DETAIL,
          payload: branch
        })
      dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (error) {
      console.log(error);
      dispatch({ type: FAIL_DATA_REQUEST, payload: error });
    }
  }

  export const setReportDetail =  async (dispatch,report) => {
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        dispatch({
          type: SET_DATA_DETAIL,
          payload: report
        })
      dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (error) {
      console.log(error);
      dispatch({ type: FAIL_DATA_REQUEST, payload: error });
    }
  }

  export const setReports =  async (dispatch,reports) => {
    console.log("setReports:", reports);
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        dispatch({
          type: SET_REPORTS,
          payload: reports
        })
      dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (error) {
      console.log(error);
      dispatch({ type: FAIL_DATA_REQUEST, payload: error });
    }
  }
  
  export const loginToFirebase = async (dispatch, userInfo) => {
    dispatch({ type: BEGIN_LOGIN_REQUEST });
    //console.log('loginToFirebase:', JSON.stringify(userInfo));
    try {
      const user = await signInWithEmailPassword(userInfo.email, userInfo.password);
      console.log('user', user);
      console.log('user.user:', user.user);

      const token = await firebase.auth().currentUser.getIdTokenResult();
      console.log('user.getIdTokenResult()', token);

      const dealers = await getDealer(user.user.uid);
      dispatch({
        type: SUCCESS_LOGIN_REQUEST,
        payload: [user.user.providerData[0],token, dealers ,user.user.uid],
      })  
      
      console.log('payload,', [user.user.providerData[0],token]);


      return {user:user, token:token};
    } catch (e) {
      dispatch({
        type: FAIL_LOGIN_REQUEST,
        payload: e.message
      })
      console.log(e)
      return null;
    }
  }

  //Henry Add
  export const registerToFirebase2 = async (dispatch, newCustomer) => {
    dispatch({ type: BEGIN_REGISTER_REQUEST });
    try {
      const user = await registerWithEmailPassword("e@e.com", "abcd1234", "d@d.com");
      //Henry Add
      console.log('registerToFirebase2 user: ', JSON.stringify(user));

    } catch (e) {
      console.log(e)
      return null;
    }
  }
  
  export const registerToFirebase = async (dispatch, userInfo) => {
    dispatch({ type: BEGIN_REGISTER_REQUEST });
    try {
      const user = await registerWithEmailPassword(userInfo.email, userInfo.password, userInfo.name);
      console.log(user)
      dispatch({
        type: SUCCESS_REGISTER_REQUEST,
        payload: user.providerData[0],
      })
      return user;
    } catch (e) {
      dispatch({
        type: FAIL_REGISTER_REQUEST,
        payload: e.message
      })
      console.log(e)
      return null;
    }
  }
  
  export const rememberLoginUser = (dispatch, remember) => {
    dispatch({
      type: REMEMBER_LOGIN,
      payload: remember,
    })
  }

  export const updateUserInfo = async (dispatch, user) => {
    dispatch({ type: BEGIN_UPDATE_USERINFO });
    try {
      const user = await updateUserInfoApi(
        // userInfo.email,
        // userInfo.password,
        // userInfo.name,
        user.company,
        user.name,
        user.email,
        user.job,
        user.phone,
        user.address
      );
      dispatch({
        type: SUCCESS_UPDATE_USERINFO,
        payload: user.providerData[0],
      });
    } catch (e) {
      dispatch({
        type: FAIL_UPDATE_USERINFO,
        payload: e.message,
      });
      console.log(e);
    }
  };
  
  export const logoutFromFirebase = async (dispatch) => {
    signOut();
    dispatch({ type: LOGOUT_REQUEST });
    localStorage.removeItem("userInfo");
  }
  
  export const checkLogin = (dispatch) => {
    const isLogin = checkLoginApi();
    if(!isLogin) {
      dispatch({ type: LOGOUT_REQUEST });    
    }
    return isLogin;
  }

  export const saveNewCustomer = (dispatch, newCustomer) => {
    dispatch({
      type: SAVE_NEW_CUSTOMER,
      payload: newCustomer,
    });
    localStorage.setItem('newCustomer', JSON.stringify(newCustomer));
  }

  export const saveNewAccount = (dispatch, newAccount) => {
    dispatch({
      type: SAVE_NEW_ACCOUNT,
      payload: newAccount,
    });
    localStorage.setItem('newAccount', JSON.stringify(newAccount));
  }

  export const saveNewBranch = (dispatch, newBranch) => {
    dispatch({
      type: SAVE_NEW_BRANCH,
      payload: newBranch,
    });
    localStorage.setItem('newBranch', JSON.stringify(newBranch));
  }

  export const saveNewRegister = (dispatch, newRegister) => {
    dispatch({
      type: SAVE_NEW_REGISTER,
      payload: newRegister,
    });
    localStorage.setItem('newRegister', JSON.stringify(newRegister));
  }

  // export const setUserInfo = async (dispatch, userInfo) => {
  //   //dispatch({ type: BEGIN_REQUEST_USERINFO });
  //   try {
  //     const user = await storefirebaseauth(userInfo.email);
  //     dispatch({
  //       type: SUCCESS_REQUEST_USERINFO,
  //       payload: user.user.providerData[0],
  //     });
  //   } catch (e) {
  //     // dispatch({
  //     //   type: FAIL_REQUEST_USERINFO,
  //     //   payload: e.message,
  //     // });
  //     console.log(e);
  //   }
  // };

  export const requestCustomerDetail = async (dispatch, customerId) => {
    dispatch({ type: BEGIN_CUSTOMER_DETAIL });
    try {
      const customer = await getNewCustomerById(customerId);
      dispatch({ 
        type: SUCCESS_CUSTOMER_DETAIL,
        payload: customer
      });
    } catch (error) {
      dispatch({ 
        type: FAIL_CUSTOMER_DETAIL, 
        payload: error 
      });
    }
  }

  export const requestAccountDetail = async (dispatch, accountId) => {
    dispatch({ type: BEGIN_CUSTOMER_DETAIL });
    try {
      const account = await getNewAccountById(accountId);
      dispatch({ 
        type: SUCCESS_CUSTOMER_DETAIL,
        payload: account
      });
    } catch (error) {
      dispatch({ 
        type: FAIL_CUSTOMER_DETAIL, 
        payload: error 
      });
    }
  }

  export const requestBranchDetail = async (dispatch, branchId) => {
    dispatch({ type: BEGIN_CUSTOMER_DETAIL });
    try {
      const branch = await getNewBranchById(branchId);
      dispatch({ 
        type: SUCCESS_CUSTOMER_DETAIL,
        payload: branch
      });
    } catch (error) {
      dispatch({ 
        type: FAIL_CUSTOMER_DETAIL, 
        payload: error 
      });
    }
  }

  export const requestRegisterDetail = async (dispatch, registerId) => {
    dispatch({ type: BEGIN_CUSTOMER_DETAIL });
    try {
      const register = await getNewAccountById(registerId);
      dispatch({ 
        type: SUCCESS_CUSTOMER_DETAIL,
        payload: register
      });
    } catch (error) {
      dispatch({ 
        type: FAIL_CUSTOMER_DETAIL, 
        payload: error 
      });
    }
  }

  export const saveSelectedRobots = (dispatch, selRobots) => {
    dispatch({
      type: SET_SELECTED_ROBOTS,
      payload: selRobots
    })  
  }  