import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, notification, Modal, Row, Col } from 'antd';
import Icon from '@mdi/react'
import { mdiAccessPointNetwork } from '@mdi/js';
import { StoreContext } from "../../store";
import { setUserInfoApi, setCustomerDetail, updateUserInfo } from "../../actions";
import ErrorItem from "./ErrorItem";
import firebase from 'firebase/app'
import { update_customer_funcs } from "../../Api"
import { Descriptions,Tag } from 'antd';


const ReportItemDetail = () => {
  const { state: {reportDetail:{ report }, reports:{ reports }, userSignin: { userInfo }}, dispatch } = useContext(StoreContext);
  const [dataToShow, setData] = useState([]);
  const [errMesg, setErrMsg] = useState([]);
  const [infoMesg, setInfoMsg] = useState([]);
  const history = useHistory();
  const [form] = Form.useForm();
  const ReportMag = () => {
    history.push("/reportmag");
  };
  
  function sort_Errors(Errors, order){
    function desc( a, b ) {
      if ( a.timestamp.seconds < b.timestamp.seconds ){
        return 1;
      }
      if ( a.timestamp.seconds > b.timestamp.seconds ){
        return -1;
      }
      if ( a.timestamp.nanoseconds > b.timestamp.nanoseconds ){
        return -1;
      }else{
        return 1;
      }
    }
    function aesc( a, b ) {
        if ( a.timestamp.seconds < b.timestamp.seconds ){
          return -1;
        }
        if ( a.timestamp.seconds > b.timestamp.seconds ){
          return 1;
        }
        if ( a.timestamp.nanoseconds > b.timestamp.nanoseconds ){
          return 1;
        }else{
          return -1;
        }
      }
    if(order === "AESC"){
      Errors.sort(aesc)
    }else{
      Errors.sort(desc)
    }
    return Errors;
  }
  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h' : 'h') : "";
    var mDisplay = m > 0 ? m + (m == 1 ? 'm' : 'm') : "";
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : "";
    // return hDisplay + mDisplay + sDisplay; 

    if ((h <= 0) && (m <= 0 )) {
        return "1 min";
    }
    return hDisplay + " " + mDisplay;
  }

  function timeStr(date){
    return new Intl.DateTimeFormat('en-us', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }).format(date);
  }
  useEffect(() => {
    console.log('CustomerItemDetail useEffect1 enter:', reports);
    
    let mesgs = [];
    if(report.errors){
      setErrMsg(report.errors);
      mesgs = mesgs.concat(report.errors);
    }
    if(report.infos){
      setInfoMsg(report.infos);
      mesgs = mesgs.concat(report.infos);
    }
    
    console.log('report.infos:', report.infos);
    console.log('mesgs:', mesgs);
    sort_Errors(mesgs, "DESC")
    setData(mesgs);
    localStorage.setItem("report", JSON.stringify(report));

    if(mesgs.length === 0){
      alert("There was no messages generated during this task!");
    }

  }, []);

    return (
      <div className="layout_limit">
        <div className="reportform-wrapper">
          <img src="/images/menu_back_btn.png" onClick={ReportMag}/>
          <p className="main-title report_detail_title">Info msgs({infoMesg.length})/Warning msgs({errMesg.length})</p> 
        </div>
        <div className="list-wrapper">
          <div className="report_info_wrapper">
          <Descriptions title="Report Info" layout="vertical">
            <Descriptions.Item label={<span style={{color:'grey'}}>Start</span>} >{<span style={{paddingLeft:'10px'}}>{timeStr(report.timestamp)}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Finish</span>}>{<span style={{paddingLeft:'10px'}}>{timeStr(report.timestamp_end)}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Compnay</span>}>{<span style={{paddingLeft:'10px'}}>{report.company}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>User</span>}>{<span style={{paddingLeft:'10px'}}>{report.user}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Area</span>}>{<span style={{paddingLeft:'10px'}}>{report.zone_name}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Robots</span>}>{<span style={{paddingLeft:'10px'}}>{<> 
            {report.devices.map((robot) => {
              let color = 'geekblue';
              return (
                <Tag color={color} key={Math.floor(Math.random() * 9999)}>
                  {robot}
                </Tag>
              );
            })}
          </>}</span>}
            </Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Duration</span>}>{<span style={{paddingLeft:'10px'}}>{secondsToHms(report.duration)}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Complete</span>}>{<span style={{paddingLeft:'10px'}}>{report.complete}</span>}</Descriptions.Item>
          </Descriptions>
            <div className="list_item">            
              <p className="account_job list_title">Mesg Type</p>
              <p className="account_job list_title">Time</p>
              <p className="account_job list_title map_detail">Error</p>
              <p className="account_job list_title map_detail">Robot</p>
              <p className="report_image list_title">Event Snapshot</p> 
            </div>
              <Row gutter={[0,8]}>
                {dataToShow.map(error => (
                  <Col 
                  key={error.id}
                  sm={{ span: 24 }} 
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                  xxl={{ span: 24 }}
                  >
                  <ErrorItem error={error}/>
                  </Col>
                ))}
              </Row>
          </div>
        </div>
      </div>
    );
};
export default ReportItemDetail;