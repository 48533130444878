import { useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { StoreContext } from "../store"

export default function UserInfo(props) {

   const { state: { userSignin : { userInfo, remember } } } = useContext(StoreContext);
   const history = useHistory();

   useEffect(() => {
      if(remember)
         localStorage.setItem("userInfo", JSON.stringify(userInfo)); //存取user email
      else
       localStorage.removeItem("userInfo");
   }, [userInfo, remember]);

   return (
      <>
         <nav style={{ ...props.style }}>
               {userInfo
                  ? <div className="account_name" values={userInfo[0].email}>{userInfo[0].email}</div>
                  : <div className="account_name" values={userInfo[0].email}>{userInfo[0].email}</div>
               }
               {userInfo
                  ? <div className="account_name" values={userInfo[1].claims.company}>{userInfo[1].claims.company}</div>
                  : <div className="account_name" values={userInfo[1].claims.company}>{userInfo[1].claims.company}</div>
               }
         </nav>
      </>
   );
}
