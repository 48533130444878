import { Layout } from "antd";
import AddAccountCheck from "../../components/account/AddAccountCheck";
const { Content } = Layout;

function AccountCheck(props) {
  return (
    <Layout className="container main-layout">
      <Content>
        <AddAccountCheck />
      </Content>
    </Layout>
  );
}

export default AccountCheck;
