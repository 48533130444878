import React, { memo, useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Text, Row, Col, Modal, Alert } from 'antd';
import { StoreContext } from "../../store"
import ReportItem from "../report/ReportItem";
import firebase from 'firebase/app'
import ModalImage from "react-modal-image";
import { setReports, setReportDetail } from "../../actions";
import { Space, Table, Spin, Tooltip, Tag, Typography,  Drawer, Descriptions } from 'antd';
import { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, Popover } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs'
import moment from 'moment';
import ErrorItem from "./ErrorItem";
import { CSVLink, CSVDownload } from "react-csv";

import { forwardRef, useImperativeHandle } from "react"



const ReportDetail = () => {
  const { state:{ reports:{ reports, reportDates, reportcompFilter, reportareaFilter, reporterrTagsFilter }, userSignin: { userInfo } } , dispatch} = useContext(StoreContext);
  const [data, setData] = useState(reports);
  
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentDataSource, setCurrentDataSource] = useState(reports);
  const [isLoading, setIsLoading] = useState(false);
  const [compFilter, setCompFilter] = useState(reportcompFilter);
  const [areaFilter, setAreaFilter] = useState(reportareaFilter);
  const [errTagsFilter, setErrTagsFilter] = useState(reporterrTagsFilter);
  const [status, setStatus] = useState("idle");
  const [dates, setDates] = useState(reportDates);
  const [report, setLocalReport] = useState({});
  const searchInput = useRef(null);

  const { RangePicker } = DatePicker;
  const { Text } = Typography;
  const { TextArea } = Input;

  //const { token } = Theme.useToken();
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const ref = useRef(null);

  const isL0 = () => {
    return (userInfo[1].claims.role === "L0");
  }
  
  const [dataCSV, setDataCSV] = useState([]);

  
  const showDrawer = () => {
    setOpen(true);
  };  
  const onClose = () => {
    setOpen(false);
    ref.current.reset();
  };

  const onSave = (event) => {
    setIsSaving(true);
    console.log("ref:", ref);
    let memo = {};
    memo.text = ref.current.value;
    memo.timestamp = (memo.text!=="")?firebase.firestore.Timestamp.fromDate(new Date()):firebase.firestore.Timestamp.fromDate(new Date(null));
    
    firebase.firestore().collection("reports").doc(report.id).set({
      memo
    }, { merge: true })
    .then(() => {
        let t_report = data.findIndex(element => element.id === report.id);
        data[t_report] = {...data[t_report], memo};
        let data2 = data.filter(element=>element);
        //console.log(t_report, data2);
        setData(data2);
        console.log("Document successfully written!");
        setIsSaving(false);
        setOpen(false);
    })
    .catch((error) => {
        console.error("Error writing document: ", error);
        Alert("Network problem, try again later!");
        setIsSaving(false);
    });
  
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //selectedKeys[0] search string
    //dataIndex: the column name
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    confirm();  //Henry: Add
    setSearchedColumn(dataIndex);//Henry: Add
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters,selectedKeys, confirm, dataIndex) }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true })
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      //timeStr(record[dataIndex]).toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Start",
      dataIndex: 'timestamp',
      key: 'timestamp',
      //width: '30%',
      //...getColumnSearchProps('timestamp'),
      sorter: (a, b) => (a.timestamp.getTime()>b.timestamp.getTime())?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder:'descend',
      render: (date) => timeStr(date)
      //render: (text) => <ModalImage medium="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/2880px-Google_2015_logo.svg.png" alt={text}/>,
    },
    {
      title: "Finish",
      dataIndex: 'timestamp_end',
      key: 'timestamp_end',
      //width: '30%',
      //...getColumnSearchProps('timestamp_end'),
      sorter: (a, b) => (a.timestamp_end.getTime()>b.timestamp_end.getTime())?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (date) => timeStr(date)
      //render: (text) => <ModalImage medium="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/2880px-Google_2015_logo.svg.png" alt={text}/>,
    },
    {
      title: "Company",
      dataIndex: 'company',
      key: 'company',
      //width: '30%',
      //...getColumnSearchProps('company'),
      sorter: (a, b) => a.company.localeCompare(b.company),//a.company.toUpperCase() > b.company.toUpperCase(),
      sortDirections: ['ascend', 'descend', 'ascend'],
      filters: compFilter,
      onFilter: (value, record) => record.company===value,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      //width: '30%',
      ...getColumnSearchProps('user'),
      sorter: (a, b) => a.user.localeCompare(b.user),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: "Area",
      dataIndex: 'zone_name',
      key: 'zone_name',
      //width: '30%',
      //...getColumnSearchProps('zone_name'),
      sorter: (a, b) => a.zone_name.localeCompare(b.zone_name),
      sortDirections: ['ascend', 'descend', 'ascend'],
      filterSearch: true,
      filters: areaFilter,
      onFilter: (value, record) => record.zone_name===value,
    },
    {
      title: "Robots",
      dataIndex: 'devices',
      key: 'devices',
      //width: '30%',
      ...getColumnSearchProps('devices'),
      sorter: (a, b) => (a.devices.length>b.devices.length)?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (_, { devices }) => (
        <> 
          {devices.map((robot) => {
            let color = 'geekblue';
            return (
              <Tag color={color} key={Math.floor(Math.random() * 9999)}>
                {robot}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Duration",
      dataIndex: 'duration',
      key: 'duration',
      //width: '30%',
      //...getColumnSearchProps('company'),
      sorter: (a, b) => (a.duration>b.duration)?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      //filters: [{text:"<10min", value: {min:0, max:60*10-1}},{text:"10min-60min", value: {min:60*10, max:60*60}},{text:">1hour", value: {min:60*60, max:60*60*9999}}],
      filters: [{text:"<10min", value: [0,60*10-1]},{text:"10min-60min", value: [60*10,60*60-1]},{text:"1h-6h", value: [60*60,60*60*6-1]},{text:">6hour", value: [60*60*6,60*60*9999]}], 
      onFilter: (value, record) => _.inRange(record.duration, value[0], value[1]),
      render: (seconds) => secondsToHms(seconds)
    },
    {
      title: <div> Complete&nbsp;
      <Tooltip placement='bottom' title="Assigned mowing task finished">
      <InfoCircleOutlined />
      </Tooltip>
    </div>,
      dataIndex: 'complete',
      key: 'complete',
      //width: '30%',
      //...getColumnSearchProps('company'),
      //sorter: (a, b) => a.company.toUpperCase() > b.company.toUpperCase(),
      //sortDirections: ['ascend', 'descend', 'ascend'],
      filters: [{text:"Yes", value: "Yes"},{text:"No", value: "No"}],
      onFilter: (value, record) => record.complete===value,
    },
    // {
    //   title: "# of Infos",
    //   dataIndex: 'infos',
    //   key: 'infos',
    //   //width: '30%',
    //   //...getColumnSearchProps('company'),
    //   sorter: (a, b) => a.infos.length > b.infos.length,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   //onFilter: (value, record) => record.company.startsWith(value),
    //   render: (infos) => infos.length
    // },
    // {
    //   title: "# of Warnings",
    //   dataIndex: 'errors',
    //   key: 'errors',
    //   //width: '30%',
    //   //...getColumnSearchProps('company'),
    //   sorter: (a, b) => a.errors.length > b.errors.length,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   //onFilter: (value, record) => record.company.startsWith(value),
    //   render: (errors) => errors.length
    // },
    {
      title: 'Warnings',
      key: 'errors_tag',
      dataIndex: 'errors',
      sorter: (a, b) => (a.errors.length>b.errors.length)?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      filters: errTagsFilter,
      onFilter: (value, record) => record.errTags.includes(value),
      render: (_, { errors }) => (
        <>
          {errors.map((tag) => {
            let color = tag.notification_slug.length > 5 ? 'geekblue' : 'green';
            if (tag.notification_slug === 'BUMPER') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={Math.floor(Math.random() * 9999)}>
                {tag.notification_slug.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Memo",
      dataIndex: 'memo',
      key: 'memo',
      sorter: (a, b) => (a.memo.timestamp.toDate().getTime()>b.memo.timestamp.toDate().getTime())?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (_, { memo }) => (
        <>
          <Popover content={<p>{memo.text}</p>} overlayStyle={{width: "20vw"}}title="Memo">
            {<a href="#">{(memo.timestamp.seconds!==0)?timeShortStr(memo.timestamp.toDate()):""}</a>}
          </Popover>
        </>
      ),
    },
  ];
  function secondsToHms(d) {

    if(d===0) return "0min";

    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h' : 'h') : "";
    var mDisplay = m > 0 ? m + (m == 1 ? 'm' : 'm') : "";
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : "";
    // return hDisplay + mDisplay + sDisplay; 

    if ((h <= 0) && (m <= 0 )) {
        return "1min";
    }
    return hDisplay + " " + mDisplay;
  }

  function timeStr(date){
    return new Intl.DateTimeFormat('en-us', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }).format(date);
  }

  function timeShortStr(date){
    return new Intl.DateTimeFormat('en-us', {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

  function asyncGetMesgs(report, reports) {

    return new Promise((resolve, reject) => {   
      let devices = [];
      let d_id = [];
      let sw_vers = [];
      console.log("report:", report.id, report.data());
      for(let i=0; i< report.data().completed_tasks.length; i++){
        devices.push(report.data().completed_tasks[i].device.model);
        d_id.push(report.data().completed_tasks[i].device.id);
        if(_.has(report.data().completed_tasks[i].device,'rsw_ver')){
          sw_vers.push(report.data().completed_tasks[i].device.rsw_ver);
        }
       //console.log("robots:", devices.toString());
        // if(i!==report.data().completed_tasks.length-1){
        //   devices += ",";
        // }else{
        //   devices = `(${report.data().completed_tasks.length}) ` + devices;
        // }
      }
      let id = report.id;
      let company = (_.has(report.data(),'user.company'))?report.data().user.company:"";
      let user = (_.has(report.data(),'user.name'))?report.data().user.name:"";
      let zone_name = (_.has(report.data(),'zone.name'))?report.data().zone.name:"";
      var beginTime = (_.has(report.data(),'timestamp'))?report.data().timestamp.toDate(): new Date();
      var endTime = (_.has(report.data(),'timestamp_end'))?report.data().timestamp_end.toDate(): new Date();
      let duration = Math.abs(endTime - beginTime)/1000; //in minutes
      let memo = (_.has(report.data(),'memo'))?report.data().memo:{text:"", timestamp: firebase.firestore.Timestamp.fromDate(new Date(null))};

      let complete = true;
      if(_.has(report.data(),'completed_tasks')){
        for(let i=0; i< report.data().completed_tasks.length; i++){
        complete &= report.data().completed_tasks[i].photos.length > 0;
        }
      }
      complete = (complete)?"Yes":"No";

      const mode_str = {normal: 'regular', sport: 'fast', slope: 'traction'};
      let op_mode = (_.has(report.data(),'zone.config.mode'))?report.data().zone.config.mode:"N/A";
      let blade_height = (_.has(report.data(),'zone.config.blade_height'))?report.data().zone.config.blade_height:"N/A";
      
      const query = firebase.firestore().collection('users').doc(report.data().user.uid).collection('messages').where("timestamp", ">", report.data().timestamp.toDate()).where("timestamp", "<", report.data().timestamp_end.toDate());
      query.get().then((querySnapshot) => {
        let errors = [];
        let infos = [];
        querySnapshot.forEach((message) => {
          var result = d_id.find(item => item === message.data().notification_subject_id);
          var errorTime = timeStr(message.data().timestamp.toDate());
          if(result === undefined){
            return;
          }
          if(message.data().notification_slug.startsWith('RL') || message.data().notification_type !== "dev_events"){
            infos.push({id: message.id, time: errorTime, ...message.data()});
          }else{
            errors.push({id: message.id, time: errorTime, ...message.data()});
          }
        });
        
        let errTags = [...new Set(errors.map(a => a.notification_slug))];
        let report = {id: id, company: company, user: user, zone_name: zone_name, timestamp: beginTime, timestamp_end: endTime, duration: duration, devices: devices, d_id: d_id, sw_vers: sw_vers, complete: complete, errors: errors, infos: infos, errTags: errTags, memo:memo, op_mode: mode_str[op_mode], blade_height:blade_height};
        //console.log("report:", report);
        resolve(report);
      });  
    });
  }
    
  const getReports =  async (e) => {
    console.log('getReports: ', dates);
    const account = [];
    const reports = [];

    var s_date = dates[0].toDate();
    s_date.setHours(0, 0, 0, 0);
    var e_date = dates[1].toDate();
    e_date.setHours(23, 59, 59, 99);

    setIsLoading(true);

    let time1 = new Date();

    //Add myself into this ilist
    //account.push({name: userInfo[0].email, uid: userInfo[1].claims.uid, company: userInfo[1].claims.company});
    
    //Add all my company users into this list
    const docRef =  firebase.firestore().collection("users").where("company", "==", userInfo[1].claims.company);
    try {
      var querySnapshot = await docRef.get()
      querySnapshot.forEach((doc) => {
        account.push({name: doc.data().name, uid: doc.data().uid, company: doc.data().company});
      });
    } catch (err) {
        console.log("Error getting document:", err);
    }
    const docRef2 =  firebase.firestore().collection("users").where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company});
    try {
      var querySnapshot2 = await docRef2.get()
      querySnapshot2.forEach((doc) => {
          account.push({name: doc.data().name,  uid: doc.data().uid, company: doc.data().company});
      });
    } catch (err) {
        console.log("Error getting document:", err);
    }
    console.log("Accounts to search:", account);

    setStatus(`Retreive reports...`);
    const query = firebase.firestore().collection('reports').where("timestamp", ">", s_date).where("timestamp", "<", e_date).orderBy("timestamp", "desc");
    try {
      var tempReports = [];
      var querySnapshot = await query.get();
      console.log("await query.get();", querySnapshot);
      querySnapshot.forEach(async (report) => {
        var result = account.find(item => item.uid === report.data().user.uid);
        if(result === undefined){
          return;
        }
        tempReports.push(report);
      });

      console.log("tempReports length:", tempReports.length);

      let i = 1;
      let all = tempReports.length;
      if(all>1500){
        alert(`Time range is too large, please set time range smaller.`);
        return;
      }


      setStatus(`Processing ${i++}/${all} report...`);
      const promises = [];
      // for ( const report of tempReports){
      //   setStatus(`Processing ${i++}/${all} report...`);
      //   promises.push(asyncGetMesgs(report, tempReports));
      // }
      tempReports.map(report => promises.push(asyncGetMesgs(report, report)));
      console.log("reports:", reports);
      console.log("promises length:", promises);
      setStatus(`${tempReports.length} report found, processing...`);

      return Promise.all(promises).then(reports => {

        let time2 = new Date();
        let diff = time2.getTime() - time1.getTime();
        console.log('Promise all reports:', reports, diff);
        setData(reports);
        setCurrentDataSource(reports);
        let companies = [...new Set(reports.map(a => a.company))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        setCompFilter(companies);
        let areas = [...new Set(reports.map(a => a.zone_name))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        setAreaFilter(areas);

        let errTags = [];
        reports.map(report => errTags.push(...report.errTags));
        errTags = [...new Set(errTags)].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        //console.log("errTags:", errTags);
        setErrTagsFilter(errTags);
        setStatus(`Query Finished! Search time:${diff/1000}s`);
        setIsLoading(false);
      }).catch((error) => {
          console.log(error)
      });
    } catch (err) {
        console.log("Error getting document:", err);
    }
  }  
  function sort_Errors(Errors, order){
    function desc( a, b ) {
      if ( a.timestamp.seconds < b.timestamp.seconds ){
        return 1;
      }
      if ( a.timestamp.seconds > b.timestamp.seconds ){
        return -1;
      }
      if ( a.timestamp.nanoseconds > b.timestamp.nanoseconds ){
        return -1;
      }else{
        return 1;
      }
    }
    function aesc( a, b ) {
        if ( a.timestamp.seconds < b.timestamp.seconds ){
          return -1;
        }
        if ( a.timestamp.seconds > b.timestamp.seconds ){
          return 1;
        }
        if ( a.timestamp.nanoseconds > b.timestamp.nanoseconds ){
          return 1;
        }else{
          return -1;
        }
      }
    if(order === "AESC"){
      Errors.sort(aesc)
    }else{
      Errors.sort(desc)
    }
    return Errors;
  }
  function mesgHandler(report) {
    let mesgs = [];
    if(report.errors){
      mesgs = mesgs.concat(report.errors);
    }
    if(report.infos){
      mesgs = mesgs.concat(report.infos);
    }
    sort_Errors(mesgs, "DESC");
    report.mesgs = mesgs;
  }

  // const getReports =  async (e) => {
  //   console.log('getReports: ', dates);
  //   //console.log("userinfo", userInfo);
  //   const account = [];
  //   const reports = [];
  //   var d = new Date();
  //   // Set it to one month ago
  //   d.setDate(d.getDate() - 5);
  //   d.setHours(0, 0, 0, 0);

  //   var s_date = dates[0].toDate();
  //   s_date.setHours(0, 0, 0, 0);
  //   var e_date = dates[1].toDate();
  //   e_date.setHours(23, 59, 59, 99);
  //   console.log(d, s_date, e_date);

  //   var Difference_In_Time = e_date.getTime() - s_date.getTime();
  //   // To calculate the no. of days between two dates
  //   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  //   if(Difference_In_Days>1500){
  //     alert(`Time range is too large, please set time range under 60 days.`);
  //     return;
  //   }

  //   setIsLoading(true);

  //   //Add myself into this ilist
  //   account.push({name: userInfo[0].email, uid: userInfo[1].claims.uid, company: userInfo[1].claims.company});
    
  //   const docRef =  firebase.firestore().collection("users").where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company});
  //   try {
  //     var querySnapshot = await docRef.get()
  //     querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots
  //         account.push({name: doc.data().name, uid: doc.data().uid, company: doc.data().company});
  //         //const reportRef = firebase.firestore().collection('reports').where("user.uid", "==", doc.data().uid);
  //     });
  //     console.log("Accounts:", account);
  //   } catch (err) {
  //       console.log("Error getting document:", err);
  //   }
  //   setStatus(`Retreive reports...`);
  //   const query = firebase.firestore().collection('reports').where("timestamp", ">", s_date).where("timestamp", "<", e_date).orderBy("timestamp", "desc");
  //   try {
  //     var tempReports = [];
  //     var querySnapshot = await query.get();
  //     console.log("await query.get();", querySnapshot);
  //     querySnapshot.forEach(async (report) => {
  //       var result = account.find(item => item.uid === report.data().user.uid);
  //       if(result === undefined){
  //         return;
  //       }
  //       tempReports.push(report);
  //     });
  //     let i = 1;
  //     let all = tempReports.length;
  //     let total_time = 0;
  //     setStatus(`Processing ${i++}/${all} report...`);
  //     for ( const report of tempReports){
  //       console.log("report:", report.data());
  //     }  
  //     console.log("report end");
  //     setIsLoading(false);
  //     return;
  //     for ( const report of tempReports){
  //       //console.log("report:", report.data());
  //       setStatus(`Processing ${i++}/${all} report...`);
  //       let time1 = new Date();

  //       let devices = "";
  //       let d_id = [];
  //       for(let i=0; i< report.data().completed_tasks.length; i++){
  //         devices += report.data().completed_tasks[i].device.model;
  //         d_id.push(report.data().completed_tasks[i].device.id);
  //         if(i!==report.data().completed_tasks.length-1){
  //           devices += ",";
  //         }else{
  //           devices = `(${report.data().completed_tasks.length}) ` + devices;
  //         }
  //       }
  //       let id = report.id;
  //       let company = (report.data().user)?report.data().user.company:"";
  //       let user = (report.data().user)?report.data().user.name:"";
  //       let zone_name = (report.data().zone)?report.data().zone.name:"";
  //       var beginTime = report.data().timestamp.toDate();
  //       var endTime = report.data().timestamp_end.toDate();
  //       let duration = Math.abs(report.data().timestamp_end.toDate() - report.data().timestamp.toDate())/1000; //in minutes
  //       let complete = true;
  //       for(let i=0; i< report.data().completed_tasks.length; i++){
  //         complete &= report.data().completed_tasks[i].photos.length > 0;
  //       }
  //       complete = (complete)?"Yes":"No";
        
  //       const queryMesg = firebase.firestore().collection('users').doc(report.data().user.uid).collection('messages').where("timestamp", ">", report.data().timestamp.toDate()).where("timestamp", "<", report.data().timestamp_end.toDate());
  //       //console.log("before await queryMesg.get();");
  //      //var queryMesgSnapshot = await queryMesg.get();
  //       //console.log("await queryMesg.get();");
  //       let errors = [];
  //       let infos = [];
  //       /*
  //       queryMesgSnapshot.forEach((message) => {
  //         var result = d_id.find(item => item === message.data().notification_subject_id);
  //         var errorTime = timeStr(message.data().timestamp.toDate());
          
  //         if(result === undefined){
  //           //console.log("Report uid not matched", report.data().user.company, report.data());
  //           //console.log(report.data().user.name," error =>",time, message.data().notification_type, message.data().body, message.data());
  //           return;
  //         }
  //         if(message.data().notification_slug === "RL_1_1" || message.data().notification_type !== "dev_events"){
  //           infos.push({id: message.id, time: errorTime, ...message.data()});
  //         }else{
  //           errors.push({id: message.id, time: errorTime, ...message.data()});
  //         }
  //       });*/
  //       let time2 = new Date();
  //       var Difference_In_Time = time2.getTime() - time1.getTime();
  //       total_time+=Difference_In_Time;
  //       reports.push({id: id, company: company, user: user, zone_name: zone_name, timestamp: beginTime, timestamp_end: endTime, duration: duration, devices: devices, d_id: d_id, complete: complete, errors: errors, infos: infos});
  //       console.log("Difference_In_Time:", i, Difference_In_Time);
  //     }
  //     console.log("reports:", reports, total_time);
  //     setData(reports);
  //     setCurrentDataSource(reports);
  //     let companies = [...new Set(reports.map(a => a.company))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
  //     setCompFilter(companies);
  //     let areas = [...new Set(reports.map(a => a.zone_name))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
  //     setAreaFilter(areas);
  //     setStatus('Query Finished!')
  //     setIsLoading(false);
  //   } catch (err) {
  //       console.log("Error getting document:", err);
  //   }
  // }  
  useEffect(() => {
    //console.log("userinfo", reports, reportDates, reportcompFilter, reportareaFilter, reporterrTagsFilter);
    // const account = [];
    // const reports = [];
    // var d = new Date();
    // // Set it to one month ago
    // d.setDate(d.getDate() - 100);
    // d.setHours(0, 0, 0, 0);
    // console.log(d);
    // setPeriod(timeStr(d) + " - Now");

    // //Add myself into this ilist
    // account.push({name: userInfo[0].email, uid: userInfo[1].claims.uid, company: userInfo[1].claims.company});
    
    // const docRef =  firebase.firestore().collection("users").where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company});
    // docRef.get().then((querySnapshot) => {
    //   querySnapshot.forEach((doc) => {
    //       // doc.data() is never undefined for query doc snapshots
    //       account.push({name: doc.data().name, uid: doc.data().uid, company: doc.data().company});
    //       //const reportRef = firebase.firestore().collection('reports').where("user.uid", "==", doc.data().uid);
    //   });
    //   console.log("account list,  => ", account);
    //   const query = firebase.firestore().collection('reports').where("timestamp", ">", d).orderBy("timestamp", "desc");
    //   query.get().then((querySnapshot) => {
    //     querySnapshot.forEach((report) => {
    //       var result = account.find(item => item.uid === report.data().user.uid);
    //       if(result === undefined){
    //         //console.log("Report uid not matched", report.data().user.company, report.data());
    //         return;
    //       }
    //       //console.log("uid,  => ", report.data());
    //       let devices = "";
    //       let d_id = [];
    //       for(let i=0; i< report.data().completed_tasks.length; i++){
    //         devices += report.data().completed_tasks[i].device.model;
    //         d_id.push(report.data().completed_tasks[i].device.id);
    //         if(i!==report.data().completed_tasks.length-1){
    //           devices += ",";
    //         }else{
    //           devices = `(${report.data().completed_tasks.length}) ` + devices;
    //         }
    //       }
    //       let id = report.id;
    //       let company = (report.data().user)?report.data().user.company:"";
    //       let user = (report.data().user)?report.data().user.name:"";
    //       let zone_name = (report.data().zone)?report.data().zone.name:"";
    //       var beginTime = timeStr(report.data().timestamp.toDate());
    //       var endTime = timeStr(report.data().timestamp_end.toDate());
    //       let duration = secondsToHms( Math.abs(report.data().timestamp_end.toDate() - report.data().timestamp.toDate())/1000 ); //in minutes
    //       let complete = true;
    //       for(let i=0; i< report.data().completed_tasks.length; i++){
    //         complete &= report.data().completed_tasks[i].photos.length > 0;
    //       }
    //       complete = (complete)?"Yes":"No";

    //       const query = firebase.firestore().collection('users').doc(report.data().user.uid).collection('messages').where("timestamp", ">", report.data().timestamp.toDate()).where("timestamp", "<", report.data().timestamp_end.toDate());
    //       query.get().then((querySnapshot) => {
    //         //console.log(beginTime,"=>",endTime, d_id);
    //         let errors = [];
    //         let infos = [];
    //         querySnapshot.forEach((message) => {
    //           var result = d_id.find(item => item === message.data().notification_subject_id);

              
    //           var errorTime = timeStr(message.data().timestamp.toDate());
              
    //           if(result === undefined){
    //             //console.log("Report uid not matched", report.data().user.company, report.data());
    //             //console.log(report.data().user.name," error =>",time, message.data().notification_type, message.data().body, message.data());
    //             return;
    //           }
    //           if(message.data().notification_slug === "RL_1_1" || message.data().notification_type !== "dev_events"){
    //             infos.push({id: message.id, time: errorTime, ...message.data()});
    //           }else{
    //             errors.push({id: message.id, time: errorTime, ...message.data()});
    //           }
    //           reports.push({id: id, company: company, user: user, zone_name: zone_name, timestamp: beginTime, timestamp_end: endTime, duration: duration, devices: devices, d_id: d_id, complete: complete, errors: errors, infos: infos});
    //           console.log(reports);
    //         });
    //         // reports.push({id: id, company: company, user: user, zone_name: zone_name, timestamp: beginTime, timestamp_end: endTime, duration: duration, devices: devices, d_id: d_id, complete: complete, errors: errors, infos: infos});
    //         // setData(reports);
    //         // setCurrentDataSource(reports);
    //         // setIsLoading(false);
    //         // console.log(data);

    //         let companies = [...new Set(reports.map(a => a.company))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
    //         setCompFilter(companies);
    //         //let swVers = [...new Set(reports.map(a => a.swVer))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?-1:1).map(x => ({text: x, value: x}));
    //         //setSwVerFilter(swVers);
    //       })  
    //     });
    //   })
      
    // })
    // .catch((error) => {
    //     console.log("Error getting documents: ", error);
    // });
  }, []);
  function disabledTime(date) {  
    console.log("disabledTime",date);
    return true;
    //return current > moment() || current < moment().subtract(3, 'day');
  }


  return (
    <div className="layout_limit">
      <Drawer title="Report Detail" placement={'right'} 
      onClose={onClose} open={open} visible={open} size='large' width={"50%"}
      extra={
        
          !isL0()?null:
          
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onSave} loading={isSaving}>Save</Button>
          </Space>
          
        
      }
      >
        <ReportDetailItem props={{report:report , userInfo:userInfo}} ref = {ref}/>
      </Drawer>

      <div className="mainform-wrapper">
      <p className="main-title report_title">Report Management({data.length})</p> 
        <CSVLink
          data={dataCSV}
          filename={`reports-by-Nexmow-${moment(dates[0]).format('YYYY-MM-DD')}-${moment(dates[1]).format('YYYY-MM-DD')}.csv`} 
          className="exportcsv__button"
          target="_blank"
          asyncOnClick={true}
          headers = {[
            { label: "Start", key: "timestamp" },
            { label: "Finish", key: "timestamp_end" },
            { label: "Company", key: "company" },
            { label: "User", key: "user" },
            { label: "Area", key: "zone_name" },
            { label: "Robots", key: "devices" },
            { label: "Duration(seconds)", key: "duration" },
            { label: "Cutting height", key: "blade_height" },
            { label: "Complete", key: "complete" },
            { label: "Warnings", key: "errors" },
            (isL0())?{ label: "Memo", key: "memo.text" }:{lable:"",key:""}
          ]}
          onClick={(event, done) => {
            //console.log("You click the link:", data); // 👍🏻 Your click handling logic
            let dataCSV = [];
            
            if(data.length ===0){
              alert("Data downloading or no data, try again later");
              done(false);
            }

            data.forEach((r) => {
              var timestamp = timeStr(r.timestamp);
              var timestamp_end = timeStr(r.timestamp_end);
              let duration = secondsToHms(r.duration);
              let errors = r.errors.map(err => err.notification_slug);
              let report = {...r, timestamp: timestamp, timestamp_end: timestamp_end, duration:duration, errors: errors};
              dataCSV.push(report);
            });

            setDataCSV(dataCSV);
            //console.log("You click the link:", dataCSV); // 👍🏻 Your click handling logic
            done(true);
          }}
        >
        Export CSV
      </CSVLink>

      </div>
     
      <hr className="hr-line" />

      <div className = "report-search">
        <RangePicker 
          className="generalRangePicker"
          //disabledDate={disabledTime}
          defaultValue={dates}
          onChange={ (dates, dateStrings) =>  setDates(dates)}
        />
        <Button htmlType="submit" onClick={async (e) => await getReports()} loading={isLoading}>
          Query
        </Button>
        <p className="report_search_desc">Status: {status}</p> 
      </div>

      <Table 
        className="generaltable"
        showHeader = "true"
        key = "Table"
        title = {(currentPageData) => {
          return `Number of filtered data: ${currentDataSource.length}`;
          //return `<p className="main-title">Robots list()</p>`
        }}
        size="large" 
        loading={isLoading}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {mesgHandler(record);setLocalReport(record);showDrawer();}, // click row
          };
        }}
        onChange={(pagination, filters, sorter, extra) => setCurrentDataSource(extra.currentDataSource)}
        dataSource={data} 
        columns={isL0()?columns:columns.slice(0, -1)} 
        pagination={{ defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['25', '50', '100', '500']}}
        scroll={{ x: 350 }}
        summary={(pageData) => {
          let totalErrors = 0;
          let totalDuration = 0;
          let Errors = [];
          pageData.forEach(({ errTags, errors, duration }) => {
            totalErrors += errors.length;
            totalDuration += duration;
            Errors = Errors.concat(errors);
          });
          let counts = {};
          for (const error of Errors) {
            counts[error.notification_slug] = counts[error.notification_slug] ? counts[error.notification_slug] + 1 : 1;
          }
          let summary = "";
          for (const [key, value] of Object.entries(counts)) {
            summary += `${key}:${value}, `;
          }
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Text>{secondsToHms(totalDuration)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <Text type="danger">{totalErrors}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}>
                <Text keyboard="true">{summary}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};
export default ReportDetail;

const ReportDetailItem = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showAlert() {
      alert("Hello from Child Component")
    },
    reset(){
      setMemoText(report.memo.text);
      //console.log("reset");
    },
    value: memoText
  }))

  let report = props.props.report;
  let userInfo = props.props.userInfo;
  //console.log("ReportDetailItem report:", props);
  const [memoText, setMemoText] = useState(report.memo.text);
  const { TextArea } = Input;

  const isL0 = () => {
    return (userInfo[1].claims.role === "L0");
  }

  const onTextChange = (event) => {
    setMemoText(event.target.value);
    //setLocalReport({...report, memo:{...memo, text:event.target.value.trim()}});
    //console.log("onTextChange:", memoText);
  }

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h' : 'h') : "";
    var mDisplay = m > 0 ? m + (m == 1 ? 'm' : 'm') : "";
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : "";
    // return hDisplay + mDisplay + sDisplay; 

    if ((h <= 0) && (m <= 0 )) {
        return "1min";
    }
    return hDisplay + " " + mDisplay;
  }

  function timeStr(date){
    return new Intl.DateTimeFormat('en-us', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }).format(date);
  }

  useEffect(() => {
    setMemoText(report.memo.text);
    //console.log("useEffect:", report.memo.text);
  }, [report]);

  return (
    <div className="list-wrapper">
    <div className="report_info_wrapper">
    <Descriptions title="Report Info" layout="vertical">
      <Descriptions.Item label={<span style={{color:'grey'}}>Start</span>} >{<span style={{paddingLeft:'10px'}}>{report.timestamp?timeStr(report.timestamp):null}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Finish</span>}>{<span style={{paddingLeft:'10px'}}>{timeStr(report.timestamp_end)}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Compnay</span>}>{<span style={{paddingLeft:'10px'}}>{report.company}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>User</span>}>{<span style={{paddingLeft:'10px'}}>{report.user}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Area</span>}>{<span style={{paddingLeft:'10px'}}>{report.zone_name}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Robots</span>}>{<span style={{paddingLeft:'10px'}}>{<> 
      {report.devices?report.devices.map((robot) => {
        let color = 'geekblue';
        return (
          <Tag color={color} key={Math.floor(Math.random() * 99999)}>
            {robot}
          </Tag>
        );
      }):null}
    </>}</span>}
      </Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Duration</span>}>{<span style={{paddingLeft:'10px'}}>{secondsToHms(report.duration)}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Complete</span>}>{<span style={{paddingLeft:'10px'}}>{report.complete}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Mode</span>}>{<span style={{paddingLeft:'10px'}}>{report.op_mode}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Blade Height(inch)</span>}>{<span style={{paddingLeft:'10px'}}>{report.blade_height}</span>}</Descriptions.Item>
      <Descriptions.Item label={<span style={{color:'grey'}}>Robot SW Ver</span>}>{<span style={{paddingLeft:'10px'}}>{report.sw_vers.join()}</span>}</Descriptions.Item>
    </Descriptions>

    
    { !isL0()?null:
      <>
      <p>{<span style={{color:'grey'}}>Memo:</span>}{<span style={{paddingLeft:'10px'}}></span>}</p>
      <TextArea
            showCount
            maxLength={500}
            style={{
              height: 120,
              resize: 'none',
            }}
            name="memo"
            value = {memoText}
            //defaultValue = {report.memo.text}//{_.has(report,"memo.txt")?report.memo.text:""}
            onChange={onTextChange}
            placeholder="Leave memo for this mowing task report"
          />
      </>   
    } 
          
    <div className="list_item">            
        <p className="account_job list_title">Mesg Type</p>
        <p className="account_job list_title">Time</p>
        <p className="account_job list_title map_detail">Error</p>
        <p className="account_job list_title map_detail">Robot</p>
        <p className="report_image list_title">Event Snapshot</p> 
      </div>
        <Row gutter={[0,8]}>
          {report.mesgs?report.mesgs.map(mesg => (
            <Col 
            key={mesg.id}
            sm={{ span: 24 }} 
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
            >
            <ErrorItem error={mesg}/>
            </Col>
          )):null}
        </Row>
    </div>
    </div>
  );
})