export const firebaseConfig = {
  
  // -- DEV VERSION --
  // apiKey: "AIzaSyDFLisIU7BWgDFbu91RkgQH0jVbdPPRUo4",
  // authDomain: "productm-dev.firebaseapp.com",
  // databaseURL: "https://productm-dev.firebaseio.com",
  // projectId: "productm-dev",
  // storageBucket: "productm-dev.appspot.com",
  // messagingSenderId: "100721303274",
  // appId: "1:100721303274:web:4508f7faa8507cd4404193",
  // measurementId: "G-9Y88T0Q0NW"

  // -- NEW STAGE --
  // apiKey: "AIzaSyDyHIPp_AwSnx2wS94cFYRHcmz1nolWK3Q",
  // authDomain: "m68x-stage-21wk39.firebaseapp.com",
  // projectId: "m68x-stage-21wk39",
  // storageBucket: "m68x-stage-21wk39.appspot.com",
  // messagingSenderId: "170747783644",
  // appId: "1:170747783644:web:3105e3a4c840cd2c810719",
  // measurementId: "G-45CPVHGNWH"

  // -- NEW NEW REL --
  apiKey: "AIzaSyDxdpWeo3j-STaxtxnlK0QYn_7VcmzEi5Y",
  authDomain: "m68x-release-21wk47.firebaseapp.com",
  projectId: "m68x-release-21wk47",
  storageBucket: "m68x-release-21wk47.appspot.com",
  messagingSenderId: "520978259818",
  appId: "1:520978259818:web:fc06bc1fff0b6e2b068f13",
  measurementId: "G-EZCJJB0GQB"

};