import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './App.css';
import Login from './pages/Login'
import Register from './pages/register/Register'
import RegisterCheck from './pages/register/RegisterCheck'
import RegisterFeedback from './pages/register/RegisterFeedback'
import CustomerMag from './pages/customer/CustomerMag'
import CustomerItem from './pages/customer/CustomerItem'
import AddCustomer from './pages/customer/AddCustomer'
import CustomerCheck from './pages/customer/CustomerCheck'
import RobotMag from './pages/robot/RobotMag'
import RobotItem from './pages/robot/RobotItem'
import TransferRobot from './pages/robot/TransferRobot'
import TransferRobotPSN from './pages/robot/TransferRobotPSN'
import RentRobotPSN from './pages/robot/RentRobotPSN'
import ActivateRobot from './pages/robot/ActivateRobot'
import ActivateRobotPSN from './pages/robot/ActivateRobotPSN'
import AccountMag from './pages/account/AccountMag'
import AccountItem from './pages/account/AccountItem'
import AddAccount from './pages/account/AddAccount'
import AccountCheck from './pages/account/AccountCheck'
import BranchMag from './pages/branch/BranchMag'
import BranchItem from './pages/branch/BranchItem'
import AddBranch from './pages/branch/AddBranch'
import BranchCheck from './pages/branch/BranchCheck'
import MapMag from './pages/map/MapMag'
import MapItem from './pages/map/MapItem'
import ReportMag from './pages/report/ReportMag'
import TaskMag from './pages/task/TaskMag'
import ReportItem from './pages/report/ReportItem'
import Tutorial from './pages/Tutorial'


import { StoreProvider } from "./store";

function App() {
  const [user, setUser] = useState({});

  //console.log('App enter:', user);

  return (
    <StoreProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/customermag" component={CustomerMag} />
          <Route path="/customeritem/:customerId" component={CustomerItem} />
          <Route path="/addcustomer" component={AddCustomer} />
          <Route path="/addcustomercheck" component={CustomerCheck} />
          <Route path="/robotmag" component={RobotMag} />
          <Route path="/robotitem/:reportId" component={RobotItem} />
          <Route path="/transferrobot" component={TransferRobot} />
          <Route path="/transferrobotpsn" component={TransferRobotPSN} />
          <Route path="/rentrobotpsn" component={RentRobotPSN} />
          <Route path="/activaterobot" component={ActivateRobot} />
          <Route path="/activaterobotpsn" component={ActivateRobotPSN} />
          <Route path="/accountmag" component={AccountMag} />
          <Route path="/accountitem/:accountId" component={AccountItem} />
          <Route path="/addaccount" component={AddAccount} />
          <Route path="/addaccountcheck" component={AccountCheck} />
          <Route path="/branchmag" component={BranchMag} />
          <Route path="/branchitem/:branchId" component={BranchItem} />
          <Route path="/addbranch" component={AddBranch} />
          <Route path="/addbranchcheck" component={BranchCheck} />
          <Route path="/mapmag" component={MapMag} />
          <Route path="/mapitem/:mapId" component={MapItem} />
          <Route path="/reportmag" component={ReportMag} />
          <Route path="/reportitem/:reportId" component={ReportItem} />
          <Route path="/taskmag" component={TaskMag} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/registercheck" component={RegisterCheck} />
          <Route path="/registerfeedback" component={RegisterFeedback} />
          <Route path="/tutorial" component={Tutorial} />
        </Switch>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;