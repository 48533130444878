import { createContext, useReducer } from "react";
import moment from 'moment';

import {
   SET_PAGE_TITLE,
   SET_PAGE_CONTENT,
   SET_DATA_DETAIL,
   SET_REPORTS,
   SET_NAVBAR_ACTIVEITEM,
   BEGIN_DATA_REQUEST,
   SUCCESS_DATA_REQUEST,
   FAIL_DATA_REQUEST,
   BEGIN_LOGIN_REQUEST,
   SUCCESS_LOGIN_REQUEST,
   FAIL_LOGIN_REQUEST,
   LOGOUT_REQUEST,
   REMEMBER_LOGIN,
   BEGIN_UPDATE_USERINFO,
   SUCCESS_UPDATE_USERINFO,
   FAIL_UPDATE_USERINFO,
   BEGIN_REQUEST_USERINFO,
   SUCCESS_REQUEST_USERINFO,
   FAIL_REQUEST_USERINFO,
   SAVE_NEW_CUSTOMER,
   SAVE_NEW_ACCOUNT,
   SAVE_NEW_BRANCH,
   SAVE_NEW_REGISTER,
   BEGIN_CUSTOMER_DETAIL,
   SUCCESS_CUSTOMER_DETAIL,
   FAIL_CUSTOMER_DETAIL,
   SET_SELECTED_ROBOTS
} from "../utils/constants"

export const StoreContext = createContext();

let userInfo;
try {
  userInfo =  JSON.parse(localStorage.getItem("userInfo"));
   if(userInfo === null){
      userInfo = [];
      userInfo.push({email:"No logged-in user"});
      userInfo.push({claims:{role:"", company:""}});
   }
  console.log("userInfo:", userInfo);
} catch(e) {
  //userInfo = null;
   if(userInfo === null){
      userInfo = [];
      userInfo.push({email:"No logged in users"});
      userInfo.push({claims:{role:"", company:""}});
   }
}
let user;
try {
   user =  JSON.parse(localStorage.getItem("user"));
} catch(e) {
   user = null;
}
let newCustomer;
try {
   newCustomer =  JSON.parse(localStorage.getItem("newCustomer"));
} catch(e) {
   newCustomer = null;
}
let account;
try {
   account =  JSON.parse(localStorage.getItem("account"));
} catch(e) {
   account = null;
}
let newAccount;
try {
   newAccount =  JSON.parse(localStorage.getItem("newAccount"));
} catch(e) {
   newAccount = null;
}
let branch;
try {
   branch =  JSON.parse(localStorage.getItem("branch"));
} catch(e) {
   branch = null;
}
let newBranch;
try {
   newBranch =  JSON.parse(localStorage.getItem("newBranch"));
} catch(e) {
   newBranch = null;
}
let map;
try {
   map =  JSON.parse(localStorage.getItem("map"));
} catch(e) {
   map = null;
}
let report;
try {
   report =  JSON.parse(localStorage.getItem("report"));
} catch(e) {
   report = null;
}
let reportDates;
try {
   reportDates = [moment().subtract(1, 'days'),moment()];
} catch(e) {
   reportDates = [];
}

let reports;
try {
   reports =  [];
} catch(e) {
   reports = [];
}

let newRegister= {};

let robot;
try {
   robot = JSON.parse(localStorage.getItem("robot"));
   console.log("robot:", robot);
} catch(e) {
   robot = {};
}

const initialState = {
   allCustomers: [],
    customerDetail: {
      user,
      newCustomer,
   },
   allRobots: [],
   selRobots: [],
    robotDetail: {
      robot
   },
   allAccounts: [],
    accountDetail: {
      account,
      newAccount,
   },
   allBranchs: [],
   branchDetail: {
      branch,
      newBranch,
   },
   mapDetail: {
      map,
   },
   reportDetail: {
      report,
   },
   reports: {
      reports: [],
      reportDates: reportDates, 
      reportcompFilter: [], 
      reportareaFilter:[], 
      reporterrTagsFilter: []
   },
   navBar: {
      activeItem: "/",
   },
   userSignin: {
      loading: false,
      userInfo,
      remember: true,
      error: "",
   },
   userRegister: {
      newRegister,
   }
};

function reducer(state, action) {

   //console.log('reducer: ', action.payload);


   switch (action.type) {
      case SET_PAGE_TITLE:
         return {
            ...state,
            page: {
               ...state.page,
               title: action.payload,
            },
         };
      case SET_PAGE_CONTENT:
         return {
            ...state,
            page: {
               ...state.page,
               user: action.payload,
            },
         };
      case SET_NAVBAR_ACTIVEITEM:
         return {
            ...state,
            navBar: {
               activeItem: action.payload
            }
         };
      case SET_DATA_DETAIL:
         console.log("SET_DATA_DETAIL:", action.payload);
         return {
            ...state,
            customerDetail: {...state.customerDetail, user: action.payload },
            robotDetail: { ...state.robotDetail, ...action.payload, loading: true },
            accountDetail: { ...state.accountDetail, account: action.payload },
            reportDetail: { ...state.reportDetail, report: action.payload }
         };
      case SET_REPORTS:
         console.log("SET_REPORTS:", action.payload);
         return {
            ...state,
            reports:{...state.reports, reports: action.payload[0], reportDates: action.payload[1], reportcompFilter: action.payload[2], reportareaFilter:action.payload[3], reporterrTagsFilter: action.payload[4]},
         };   
      case BEGIN_DATA_REQUEST:
         return {
            ...state,
            requestCustomers: { ...state.requestCustomers, loading: true },
            requestRobots: { ...state.requestRobots, loading: true },
            requestAccounts: { ...state.requestAccounts, loading: true },
            requestBranchs: { ...state.requestBranchs, loading: true },
         };
      case SUCCESS_DATA_REQUEST:
         return {
            ...state,
            requestCustomers: { ...state.requestCustomers, loading: false },
            requestRobots: { ...state.requestRobots, loading: false },
            requestAccounts: { ...state.requestAccounts, loading: false },
            requestBranchs: { ...state.requestBranchs, loading: true },
         };
      case FAIL_DATA_REQUEST:
         return {
            ...state,
            requestCustomers: {
               ...state.requestCustomers,
               loading: false,
               error: action.payload,
            },
            requestRobots: {
               ...state.requestRobots,
               loading: false,
               error: action.payload,
            },
            requestAccounts: {
               ...state.requestAccounts,
               loading: false,
               error: action.payload,
            },
            requestBranchs: {
               ...state.requestBranchs,
               loading: false,
               error: action.payload,
            },
         };
      case BEGIN_LOGIN_REQUEST:
         return { ...state, userSignin: { ...state.userSignin, loading: true } };
      case SUCCESS_LOGIN_REQUEST:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               loading: false,
               userInfo: action.payload,
               error: "",
            },
         };
      case FAIL_LOGIN_REQUEST:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               loading: false,
               userInfo: null,
               error: action.payload,
            },
         };
      case BEGIN_UPDATE_USERINFO:
         return { ...state, userSignin: { ...state.userSignin, loading: true } };
      case SUCCESS_UPDATE_USERINFO:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               loading: false,
               userInfo: action.payload,
               error: "",
            },
         };
      case FAIL_REQUEST_USERINFO:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               loading: false,
               error: action.payload,
            },
         };
      case BEGIN_REQUEST_USERINFO:
         return { ...state, userSignin: { ...state.userSignin } };
      case SUCCESS_REQUEST_USERINFO:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               userInfo: action.payload,
               error: "",
            },
         };
      case FAIL_REQUEST_USERINFO:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               error: action.payload,
            },
         };
      case LOGOUT_REQUEST:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               userInfo: null,
            },
         };
      case REMEMBER_LOGIN:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               remember: action.payload,
            },
         };
      case SAVE_NEW_CUSTOMER:
         console.log('action.payload.newcustomer = ',action.payload)
         return { ...state, customerDetail: { ...state.newCustomer, newCustomer: action.payload } };
      case SAVE_NEW_ACCOUNT:
         console.log('action.payload.newaccount = ',action.payload);
         return { ...state, accountDetail: { ...state.newAccount, newAccount: action.payload } };
      case SAVE_NEW_BRANCH:
         console.log('action.payload.newBranch = ',action.payload);
         return { ...state, branchDetail: { ...state.newBranch, newBranch: action.payload } };
      case SAVE_NEW_REGISTER:
         console.log('action.payload.newregister = ')
         console.log(action.payload)
         return { ...state, account: { ...state.register, newRegister: action.payload } };
      case BEGIN_CUSTOMER_DETAIL:
         return {
            ...state,
            customerDetail: {
               ...state.customerDetail,
            }
         };
      case SUCCESS_CUSTOMER_DETAIL:
         return {
            ...state,
            customerDetail: {
               ...state.customerDetail,
               customer: action.payload,
            },
         };
      case FAIL_CUSTOMER_DETAIL:
         return {
            ...state,
            customerDetail: {
               ...state.customerDetail,
               error: action.payload,
            },
         };
      case SET_SELECTED_ROBOTS:
         return {
            ...state,
            selRobots: action.payload
         };
         default:
         return state;
   }
}

export function StoreProvider(props) {
   const [state, dispatch] = useReducer(reducer, initialState);
   const value = { state, dispatch };

   console.log('StoreProvider enter:', state);
   //console.log('initialState enter:', initialState);
   
   return (
      <StoreContext.Provider value={value}>
         {props.children}
      </StoreContext.Provider>
   );
}