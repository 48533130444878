import React, { useContext, useEffect,useState } from "react";
import { useHistory,Link } from "react-router-dom";
import { Form, Input, Button, Text, Col, Row, DatePicker, Drawer, Space } from 'antd';
//import { StoreContext } from "../../store"
//import ActivateRobotItemPSN from "../robot/ActivateRobotItemPSN";
import TransferRobotItemPSN from "../robot/TransferRobotItemPSN";
import TransferRobotdrawerItem from "../robot/TransferRobotdrawerItem";
import robots from "../../json/robots.json";
import firebase from 'firebase/app'
import { StoreContext } from "../../store"
import {transferRobot, activateRobot} from "../../Api/index.js"

const ActivateRobotlistPSN = () => {
  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  const [dataToShow, setRobotData] = useState([]);
  const [companies, setCompanyData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState('top');
  const { state:{ userSignin: { userInfo} ,selRobots}, dispatch } = useContext(StoreContext);
  const history = useHistory();

  const [warranty_string, setWarranty_String] = useState("");
  

  const companySelected = async (company) => {
    console.log("companySelected:" ,company);

    if(!window.confirm(`Are you sure you want to activate and transfer to ${company.name}?`)){
      return;
    }

    var selected = [];
    Object.entries(robotData).forEach( (element) => {
      //console.log(element[0],":", element[1]);
      if(element[1].digitsCorrectness){
        selected.push(element[1]);
      }
    });
    console.log("selected:", selected);

    if(selected.length == 0) return;
    //switch company
    //0. robots to transfer "robotData"
    //1. change robot company field "companyName"
    //2. add myself to robot dealer "array"
    //transferRobot(selected, companyName, {role:role, company:companyName});
    let result = activateRobot(userInfo, selected, new Date().toLocaleDateString('en-US'), company);
    //transferRobot2(selected, companyName, {role:role, company:companyName});
    console.log("selected:", selected,company);
    result = await transferRobot(userInfo, selected, company);
    onClose()
    history.push("robotmag");
  };

  const showDrawer = () => {
    //console.log("Test: ", robotData);
    let digitsAllCorrect = true;
    Object.entries(robotData).forEach( (element) => {
      digitsAllCorrect &= element[1].digitsCorrectness;
    });

    if(Object.keys(robotData).length !== selRobots.length){
      digitsAllCorrect = false;  //in case user did not input any digits
    }

    if(!digitsAllCorrect){
      alert("Wrong last 4 PSN digits! Please check again!");
      return;
    }
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const [zip, updateZip] = useState("");
  //console.log("updateZip zip:" ,zip);
  const onZipChange = (event) => {
    //console.log("zipChange:" ,event.target.value);
    updateZip(event.target.value);
    //console.log(`${event.target.name}: ${event.target.checked}`)
  };

  const [selDate, updateDate] = useState("");
  //console.log("updateDate date:" ,selDate);
  const onDateChange = (date) => {
    //console.log("onDateChange:" ,new Date(date).toLocaleDateString('en-US'));
    updateDate(new Date(date).toLocaleDateString('en-US'));
  };

  const [robotData, updateRobotData] = useState({});
  //console.log("TransferRobotlistPSN robotData: ", robotData);
  const digitsReport = (robotReport) => {
    //console.log("digitsReport:" ,robotReport);
    updateRobotData({
      ...robotData,
      [robotReport.id]: robotReport
    });
    //console.log("digitsReport robotData:" ,robotData);
    //console.log(`${event.target.name}: ${event.target.checked}`)
  };

  function sort_companies(companies, order){
    function desc( a, b ) {
      if ( a.name.toUpperCase() < b.name.toUpperCase() ){
        return 1;
      }
      if ( a.name.toUpperCase() > b.name.toUpperCase() ){
        return -1;
      }
      return 0;
    }
    function aesc( a, b ) {
        if ( a.name.toUpperCase() < b.name.toUpperCase() ){
          return -1;
        }
        if ( a.name.toUpperCase() > b.name.toUpperCase() ){
          return 1;
        }
        return 0;
      }
    if(order === "AESC"){
      companies.sort(aesc)
    }else{
      companies.sort(desc)
    }
    return companies;
  }

  useEffect(() => {
    //var company = userInfo[1].claims.company;
    var today = new Date().toLocaleDateString('en-US');
    var warranty_date = new Date();
    warranty_date.setFullYear(warranty_date.getFullYear() + 2);  //多一天
    warranty_date.setDate(warranty_date.getDate() + 7 - 1); //減一天回來
    warranty_date = warranty_date.toLocaleDateString('en-US');
    var war_str = today+" - "+warranty_date+" (7 days grace period)"
    setWarranty_String(war_str);
    
    //var company = userInfo[1].claims.company;
    var email = userInfo[0].email;
    //var companies = [{name:userInfo[1].claims.company, admin: userInfo[0].email, role: userInfo[1].claims.role,id: userInfo[1].claims.company}];
    var companies = [];
    const myCompany = firebase.firestore().collection('companies').doc(userInfo[1].claims.company);
    myCompany.get().then((company) => {
      //console.log("clinet => ", company.data().name);
      let name = company.data().name;
      let admin = company.data().admin;
      let role = company.data().role;
      let dealer = (company.data().dealer)?company.data().dealer:[];
      dealer = (dealer !== [""])?dealer:[];
      let id = company.id;
      companies.push({name: name, admin: admin, role: role, id: id, dealer: dealer});
      
      const docRef = firebase.firestore().collection('companies').where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company});
      docRef.get().then((querySnapshot) => {
        querySnapshot.forEach((company) => {
          //console.log("clinet => ", i, company.data().name);
          let name = company.data().name;
          let admin = company.data().admin;
          let role = company.data().role;
          let dealer = (company.data().dealer)?company.data().dealer:[];
          let id = company.id;
          companies.push({name: name, admin: admin, role: role, id: id, dealer: dealer});
          //companies.push({name:company.data().name, admin: company.data().admin, role: company.data().role,id: company.id});
        });
        console.log("companies => ", companies);
        companies = sort_companies(companies, "AESC");
        setCompanyData(companies);
      });
    });
  }, []);

  useEffect(() => {
    let itemRefs = selRobots.map(id => {
      return firebase.firestore().collection('robots').doc(id).get();
    });
    Promise.all(itemRefs)
    .then(docs => {
      const robots = [];
      docs.forEach((robot) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(robot.id, " => ", robot.data());
          let psn = (robot.data().warranty && robot.data().warranty.psn)?robot.data().warranty.psn:"";
          let model = (robot.data().model)?robot.data().model:"";
          let dealer = robot.data().dealer;
          let id =  robot.id;
          robots.push({psn: psn, model: model, id: id, dealer: dealer});
      });
      setRobotData(robots);
    })
    .catch(error => console.log(error))
  }, []);

  return (
    <div className="layout_limit"> 
      <div className="mainform-wrapper">
        <Link to="/activaterobot">
          <img src="/images/menu_back_btn.png" className="back_s_btn"/>
        </Link>
        <p className="main-title">Activate robots</p>
          <Button
            htmlType="submit"className="psnfinish__button" onClick={showDrawer}
          >
            Confirm
          </Button>
          <Drawer
            title="NEXMOW management system"
            placement={placement}
            height={700}
            onClose={onClose}
            visible={visible}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="primary" onClick={onClose}>OK</Button>
              </Space>
            }
          >
            <p className="drawer_word">Please select one company to transfer the robot.</p>
            <div className="list-wrapper">
            <div className="list_item">
            <p className="custom_name list_title">Type</p>
            <p className="custom_name list_title">Company</p>
            <p className="custom_address list_title">Email</p>
          </div>
              <Row gutter={[0,8]}>
                {companies.map(company => (
                    <Col 
                    key={company.id} 
                    sm={{ span: 24 }} 
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                    xxl={{ span: 24 }}
                    >
                    <TransferRobotdrawerItem company={{comp:company,callback:companySelected}}/>
                    </Col>
                ))}
              </Row>
            </div>
          </Drawer>
      </div>
      <hr className="hr-line" />
      <Form
          name="normal_login"
          className="activate_list"
          layout="vertical"
          //onFinish={onFinish}
        >
        <p className="activaterobot_title">Warranty Period</p>   
        <Form.Item 
            className="activaterobot_form">
            {warranty_string}
        </Form.Item>
        
      </Form>
      <div className="robotnum_wrapper">
        <p className="main-title">Robots list ({dataToShow.length})</p> 
        <p className="robottrans_notice">Please input the last 4 PSN</p> 
      </div>  { /*Anita--0530add*/}
      <div className="list-wrapper">
        <Row gutter={[0,8]}>
          {dataToShow.map(robot => (
              <Col 
              key={robot.id} 
              sm={{ span: 24 }} 
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
              >
              <TransferRobotItemPSN robot={{rob:robot, callback:digitsReport}}/>
              </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default ActivateRobotlistPSN;