import { Layout } from "antd";
import * as QueryString from "query-string";
import RegisterFeedbackCard from "../../components/register/RegisterFeedbackCard";
const { Content } = Layout;

function RegisterFeedback(props) {
  return (
    <Layout className="container main-layout">
      <Layout className="bg-gray main-area">
        <Content className="layout-content">
          <RegisterFeedbackCard />
        </Content>
      </Layout>
    </Layout>
  );
}

export default RegisterFeedback;