import React, { useContext, useEffect,useState } from "react";
import { useHistory,Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Text, Row, Col} from 'antd';
//import { StoreContext } from "../../store"
import TransferRobotItem from "./TransferRobotItem";
import RobotItem from "./RobotItem";
import robots from "../../json/robots.json";
import firebase from 'firebase/app'
import { StoreContext } from "../../store"
import {saveSelectedRobots} from "../../actions/index.js"
import {create_admin, create_robot} from "../../Api"

const TransferRobotDetail = () => {
  const { state:{ userSignin: { userInfo, loading, error, remember } ,selRobots}, dispatch } = useContext(StoreContext);
  const [dataToShow, setData] = useState([]);
  const [g_robots, setGrobots] = useState([]);
  const [order, setOrder] = useState("AESC");
  const history = useHistory();
  const RentAddPSNnum = async () => {
    //history.push("/rentrobotpsn");
    const psn_root = "M1B1A1M06";
    const cpu_root = "00000000000006"
    for (let i = 0; i < 10; i++) { 
      var psn = psn_root + i.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      }) + "B";
      var cpu = cpu_root + i.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      var model = "M1-" + cpu.slice(-3);
      console.log(cpu," => ", psn," => ", model);
      create_robot(psn, cpu, "ursrobot");
    }
  };
  const AddPSNnum = () => {
    //console.log("AddPSNnum selected userData: ", userData);
    //console.log("AddPSNnum selected selRobots: ", selRobots);
    var selected = [];
    Object.entries(userData).forEach( (element) => {
      //console.log(element[0],":", element[1]);
      if(element[1]){
        selected.push(element[0]);
      }
    });
    console.log("AddPSNnum selected: ", selected);

    if(selected.length > 0){
      saveSelectedRobots(dispatch, selected);
      history.push("/transferrobotpsn");
    }else{
      alert("Please select one or more robots to transfer");
    }
  };
  function refreshPage() {
    window.location.reload(false);
  }

  const [userData, updateFormUserData] = useState({});
  const handleChange = (event) => {
    //console.log(`userData.admin: ${userData.admin}`)
    updateFormUserData({
      ...userData,
      [event.target.name]: event.target.checked
    });
    //console.log("TransferRobotDetail userData:" ,userData);
    //console.log(`${event.target.name}: ${event.target.checked}`)
  };
  
  //console.log("TransferRobotDetail: ", userData, Object .values (userData) .filter (v => v.x == 1) .length);
  useEffect(() => {
    
  //console.log("TransferRobotDetail effect: ", userInfo[1].claims.company);
    const docRef = firebase.firestore().collection('robots').where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company})
      const robots = [];
      docRef.get().then((querySnapshot) => {
        
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            //robot.push({name : doc.data().model, id : doc.data().model})
            let psn = (doc.data().warranty && doc.data().warranty.psn)?doc.data().warranty.psn:"";
            let model = (doc.data().model)?doc.data().model:"";
            let company = (doc.data().company)?doc.data().company:"";
            let uid = (doc.data().uid)?doc.data().uid:undefined;
            let id =  doc.id;
            robots.push({psn: psn, model: model, company: company, id: id, uid: uid});
            //console.log("robots: ", robots);
        });
        //console.log("robots: ", robots);
        setGrobots(robots)
        setData(robots);
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });
  }, []);

  //搜尋與排序
  function search() {
    var input = document.getElementById("search_field").value;
    var robots = JSON.parse(JSON.stringify(g_robots));
    var r = robots.filter(i => i.psn.includes(input) || i.model.includes(input) || i.company.includes(input));
    var sorted_robots = sort_robots(r, order);
    setData(sorted_robots);
  }

  function sort_robots(robots, order){
    function desc( a, b ) {
      if ( a.psn.toUpperCase() < b.psn.toUpperCase() ){
        return 1;
      }
      if ( a.psn.toUpperCase() > b.psn.toUpperCase() ){
        return -1;
      }
      return 0;
    }
    function aesc( a, b ) {
        if ( a.psn.toUpperCase() < b.psn.toUpperCase() ){
          return -1;
        }
        if ( a.psn.toUpperCase() > b.psn.toUpperCase() ){
          return 1;
        }
        return 0;
      }
    if(order == "AESC"){
      robots.sort(aesc)
    }else{
      robots.sort(desc)
    }
    return robots;
  }

  function sort() {
    var or = (order == "DESC"? "AESC":"DESC");
    setOrder(or);
    var robots = sort_robots(dataToShow, or);
    setData(robots);
    //return doc.data()
  }

  return (
    <div className="layout_limit"> 
      <div className="mainform-wrapper">
        <Link to="/robotmag">
          <img src="/images/menu_back_btn.png" className="back_s_btn"/>
        </Link>
        <p className="main-title">Transfer robots</p> 
        <Button
          htmlType="submit"className="update_btn" onClick={AddPSNnum}
        >
          Next
        </Button>
      </div>
      <hr className="hr-line" />
      
      {<div className="set-wrapper">
        <Input className="searchbar" placeholder="Search" id="search_field" onChange={search} prefix={<img src="/images/search.png" className="search_icon"/>} />
       </div> }
       
      <div className="list-wrapper">
      <div className="list_item">
            <p className="robot_psn list_title">PSN Number</p>
            <p className="robot_name list_title">Model</p>
            <p className="robot_company list_title">Company</p>
            <p className="robot_select list_title">Select</p> 
            
          </div>
          <Row gutter={[0,8]}>
            {dataToShow.map(robot => (
                <Col 
                key={robot.id} 
                sm={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                >
                <TransferRobotItem robot={ {rob:robot, callback:handleChange}}/>
                </Col>
            ))}
          </Row>
      </div>
    </div>
  );
};
export default TransferRobotDetail;