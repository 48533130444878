import React, { useState, useContext } from "react";
import { Form, Input, Button, Modal } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { saveNewCustomer } from "../../actions"
import { StoreContext } from "../../store";

export default function AddCustomerCard() {
    const { state: { userSignin: { userInfo}, customerDetail:{ newCustomer }}, dispatch } = useContext(StoreContext);
    const [form] = Form.useForm();
    const history = useHistory();

    const AddCustomerCheck = (values) => {
      //console.log("AddCustomerCheck:", values);
      if(!values.customer_dealer)
        values.customer_dealer = false;
      Object.keys(values).forEach(function(key, index) {
        if (this[key] == null) this[key] = "";
      }, values);

      //Set email and company to lower case for firestore search
      values.customer_email = values.customer_email.toLowerCase();
      values.company_name = values.company_name.toLowerCase();
      values.role = (values.customer_dealer) ? 'L2':'L3';
      /*
      if(!values.customer_address)
        values.customer_address = '';
      if(!values.customer_job)
        values.customer_job = '';  
      if(!values.customer_phone)
        values.customer_phone = '';  
      if(!values.customer_zip)
        values.customer_zip = '';    */
      //console.log("AddCustomerCheck:", values);  
      
      saveNewCustomer(dispatch, values)
      history.push("/addcustomercheck");
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const dealercheckbox_visible = (userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1")?true:false;
    const showModal = () => {
      setIsModalVisible(true);
    };
    
    const handleOk = () => {
      history.push("/customermag");
      setIsModalVisible(false);
      saveNewCustomer(dispatch, "");
    };
    
    const handleCancel = () => {
      setIsModalVisible(false);
    };

  return (
    <div className="main-layout">
      <div className="addcustomer-wrapper">
        <img src="/images/ad_4_img.png" className="add_pic"/>
        <img src="/images/back_btn.png" className="back_btn" onClick={showModal}/>
        <Modal title="NEXMOW management system" visible={isModalVisible}
          onOk={handleOk} onCancel={handleCancel}>
          <p>Are you sure to exit without save?</p>
        </Modal>
      </div>
      <div className="addcustomer-form">
        <div className="addcustomer">
            <p className="addcustomer-title">Add new customer</p> 
            <hr className="hr-addcustomerline" />
        </div>
        <Form
          className="accountform_list"
          name="add_customer_form"
          form={form}
          layout="vertical"
          initialValues={newCustomer}
          onFinish={AddCustomerCheck}
        >
        <Form.Item 
          label="company name"  required
          name="company_name"
          className="addcustomer_form"
          rules={[
            ({getFieldValue})=>({
              validator(rule,value){
                var compRegxp = /\s/;
                if(value && !compRegxp.test(value)){
                  return Promise.resolve()
                }
                return Promise.reject("Company name not allow space")
              }
            }),
            {
              type: "string",
              required: true,
              message: "Please input company name",
            },
          ]}
          hasFeedback
          >
            <Input placeholder="input placeholder"/>
        </Form.Item>
        <Form.Item 
          label="customer name"  required
          name="customer_name"
          className="addcustomer_form"
          rules={[
            ({getFieldValue})=>({
              validator(rule,value){
                var compRegxp = /(^\s)|(\s$)/;
                var charRegxp = /(\w+)/;
                if(value && !compRegxp.test(value) && charRegxp.test(value)){
                  return Promise.resolve()
                }
                if(value && compRegxp.test(value)){
                  return Promise.reject("No space in the front/back");
                }
                if(value && !compRegxp.test(value)){
                  return Promise.reject("Require at least 1 characters");
                }
              }
            }),
            {
              type: "string",
              required: true,
              message: "Please input customer name",
            },
          ]}
          hasFeedback
          >
            <Input placeholder="input placeholder"/>
        </Form.Item>
        <Form.Item 
          label="email address"  required
          name="customer_email"
          className="addcustomer_form"
          rules={[
            ({getFieldValue})=>({
              validator(rule,value){
                var emailRegxp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                if(value && emailRegxp.test(value)){
                  return Promise.resolve()
                }
                return Promise.reject("Incorrect email")
              }
            }),
            {
              type: "string",
              required: true,
              message: "Please input email address",
            }
          ]}
          hasFeedback
          >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item 
          label="Default password"  required
          name="customer_password"
          className="addcustomer_form"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please input default password",
            },
            { min: 6, message: 'Username must be minimum 6 characters.' },
          ]}
          hasFeedback
          >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item 
          label="Retype password" required 
          name="customer_password_check"
          className="addcustomer_form"
          rules={[
            ({getFieldValue})=>({
              validator(rule,value){
                if(!value || getFieldValue('customer_password') === value){
                  return Promise.resolve()
                }
                return Promise.reject("Incorrect password")
              }
            }),
            {
              required: true,
              message: "Please input password again",
            }
          ]}
          hasFeedback
          >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          label="Job title (optional)"
          name="customer_job"
          className="addcustomer_form"
          hasFeedback
        >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          label="Phone number (optional)"
          name="customer_phone"
          className="addcustomer_form"
          hasFeedback
        >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          label="Zip code (optional)"
          name="customer_zip"
          className="addcustomer_form"
          hasFeedback
        >
            <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          label="address (optional)"
          name="customer_address"
          className="addcustomer_form"
          hasFeedback
        >
            <Input placeholder="input placeholder" />
        </Form.Item>
        {dealercheckbox_visible?
          <Form.Item
            label="Dealer?"
            name="customer_dealer"
            valuePropName="checked"
            className="addcustomer_form"
            hasFeedback
          >
          <input type="checkbox" value="dealer"/>
          </Form.Item>
                :null}

        <Form.Item className="login-enter">
          <Button
            htmlType="submit"
            className="addcustomer_btn"
          >
            Save
          </Button>
        </Form.Item>
        </Form>
      </div>
    </div>
  );
};