import { Layout } from "antd";
import * as QueryString from "query-string";
import RegisterCheckCard from "../../components/register/RegisterCheckCard";
const { Content } = Layout;

function RegisterCheck(props) {
  return (
    <Layout className="container main-layout">
      <Layout className="bg-gray main-area">
        <Content className="layout-content">
          <RegisterCheckCard />
        </Content>
      </Layout>
    </Layout>
  );
}

export default RegisterCheck;