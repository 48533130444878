import React, { useContext, useEffect,useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Text, notification, Row, Col } from 'antd';
import { StoreContext } from "../../store"
import CustomerItem from "../customer/CustomerItem";
import { setCustomerDetail } from "../../actions";
//import customers from "../../json/customers.json";
import firebase from 'firebase/app'

export default function CustomerDetail() {
  //const { dispatch } = useContext(StoreContext);
  const { state:{ userSignin: { userInfo, loading, error, remember }}, dispatch } = useContext(StoreContext);
  const [dataToShow, setData] = useState([]);
  const [g_user, setGuser] = useState([]);
  const [order, setOrder] = useState("AESC");
  const history = useHistory();
  const AddCustomerCard = () => {
    history.push("/addcustomer");
  };

  useEffect(() => {
    console.log(" CustomerDetail useEffect : enter");
    var users = [];
    const docRef =  firebase.firestore().collection("users").where("dealer", "array-contains", {role:userInfo[1].claims.role, company:userInfo[1].claims.company}).where("contact", "==", true);
    docRef.get().then((querySnapshot) => {
      
      querySnapshot.forEach((doc) => {
        /*users.push({company : doc.data().company,name : doc.data().name, address: doc.data().address, 
          phone: doc.data().phone, job: doc.data().job, role: doc.data().role, id : doc.id, email: doc.data().email, address_zip: doc.data().address_zip})
        */
        let company =  (doc.data().company)?doc.data().company:"";
        let name = (doc.data().name)?doc.data().name:"";
        let address = (doc.data().address)?doc.data().address:"";
        let phone = (doc.data().phone)?doc.data().phone:"";
        let job = (doc.data().job)?doc.data().job:"";
        let role = (doc.data().role)?doc.data().role:"";
        let id = doc.id;
        let email = (doc.data().email)?doc.data().email:"";
        let address_zip = (doc.data().address_zip)?doc.data().address_zip:"";
        
        if(!doc.data().contact){
          console.log("no contact");
          return;
        }
        users.push({company: company,name: name, address: address, phone: phone, job: job, role: role, id : id, email: email, address_zip: address_zip});
      });
      setGuser(JSON.parse(JSON.stringify(users)));
      var sorte_users = sort_users(users, order);
      setData(sorte_users);
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
  }, []);
  
  function refreshPage() {
    window.location.reload(false);
  }
  function search() {
    var input = document.getElementById("search_field").value;
    var users = JSON.parse(JSON.stringify(g_user));
    var r = users.filter(i => i.name.includes(input) || i.company.includes(input) || i.email.includes(input)|| i.address.includes(input)|| i.phone.includes(input)|| i.job.includes(input));
    var sorted_r = sort_users(r, order);
    setData(sorted_r);
  }

  function sort_users(users, order){
    function desc( a, b ) {
      if ( a.company.toUpperCase() < b.company.toUpperCase() ){
        return 1;
      }
      if ( a.company.toUpperCase() > b.company.toUpperCase() ){
        return -1;
      }
      return 0;
    }
    function aesc( a, b ) {
        if ( a.company.toUpperCase() < b.company.toUpperCase() ){
          return -1;
        }
        if ( a.company.toUpperCase() > b.company.toUpperCase() ){
          return 1;
        }
        return 0;
      }
    if(order == "AESC"){
      users.sort(aesc)
    }else{
      users.sort(desc)
    }
    return users;
  }

  function sort() {
    var or = (order == "DESC"? "AESC":"DESC");
    setOrder(or);
    var users = sort_users(dataToShow, or);
    setData(users);
    //return doc.data()
  }

  return (
    <div className="layout_limit">
      {console.log( 'customerdetail render' )}
      <div className="mainform-wrapper">
        <p className="main-title">Customer Management({g_user.length})</p> 
          <Button
            htmlType="submit"className="addcustomer__button" onClick={AddCustomerCard}
          >
            + Add new customer
          </Button> 
      </div>
      <hr className="hr-line" />
      <div className="set-wrapper">
        <Input className="searchbar" id="search_field" placeholder="Search" onChange={search} prefix={<img src="/images/search.png" className="search_icon"/>} />
        <img src="/images/reflash_normal_btn.png" className="de_search_icon" onClick={search}/>
        <img src="/images/sorting_normal_btn.png" className="de_search_icon" onClick={sort}/>
      </div>
      <div className="list-wrapper">
        <div className="list_item">
          <p className="custom_company list_title">Type</p> 
          <p className="custom_company list_title">Company</p> 
          <p className="custom_name list_title">Name</p> 
          <p className="custom_email list_title">Email</p> 
          <p className="custom_job list_title">Job</p> 
          <p className="custom_phone list_title">Phone</p> 
          <p className="custom_address list_title">Address</p> 
        </div>
        <Row gutter={[0,8]}>
            {dataToShow.map(user => (
                <Col 
                key={user.id} 
                sm={{ span: 24 }} 
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                >
                <CustomerItem user={user}/>
                </Col>
            ))}
        </Row>
      </div>
    </div>
  );
};
