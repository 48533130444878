import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button, Checkbox, Spin } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { StoreContext } from "../../store";
import { requestAccountDetail } from "../../actions"
import { createUser_funcs } from "../../Api"
import { saveNewAccount } from "../../actions"
import { dp_log } from "../../Api"
import firebase from 'firebase/app'
import 'firebase/firestore';

export default function AddAccountCheck({accountId}) {
  //const { state: { accountDetail: { newAccount } }, dispatch } = useContext(StoreContext);
  const { state: { accountDetail:{ newAccount}, userSignin: {userInfo}}, dispatch } = useContext(StoreContext);
   
  const history = useHistory();
  const AddAccount = () => {
    history.push("/addaccount");
  };

  console.log("AddAccountCheck enter newAccount: ", newAccount);
  console.log("AddAccountCheck enter userInfo: ", userInfo);

  const [loadings, setLoadings] = useState([]);
  const SubmitAccount = async (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    
    let user = {};
    user.email = newAccount.account_email;
    user.password = newAccount.account_password;
    user.admin = newAccount.account_admin;
    user.company_name = userInfo[1].claims.company;
    user.role = userInfo[1].claims.role;
    let uid = undefined;
    try{
      const result = await createUser_funcs(user);
      uid = result.data;
      console.log("createUser:", result);
    }catch(error){
      console.log("error:", error);
    }  
    if(uid){
      let setWithMerge =  await firebase.firestore().collection("users").doc(uid).set({
        address:newAccount['account_address'],
        address_zip:newAccount['account_zip'],   
        company:userInfo[1].claims.company,
        name:newAccount['account_name'],
        //No dealer field for non-admin users
        dealer: firebase.firestore.FieldValue.arrayUnion(...userInfo[2]),
        email:newAccount['account_email'],
        job: newAccount['account_job'],
        phone: newAccount['account_phone'],
        role: userInfo[1].claims.role,
        uid: uid,
        admin: newAccount['account_admin']
      }, { merge: true });
      dp_log({user:userInfo, action: "add_Account", item: newAccount.account_email, meta: JSON.stringify(newAccount)});
    }else{
      alert("Add acount error, please check email correctness!");
      dp_log({user:userInfo, action: "add_Account", item: newAccount.account_email, meta: "Error"+JSON.stringify(newAccount)});
    }

    saveNewAccount(dispatch, "");
    history.push("/accountmag");
  };

  return (
    <div className="main-layout">
      <div className="addcustomer-wrapper">
        <img src="/images/ad_2_img.png" className="ad_1"/>
        <img src="/images/back_btn.png" className="back_btn" onClick={AddAccount}/>
      </div>
      <div className="addcustomer-form">
        <div className="nexmowlogo">
          <img src="/images/brand_logo_img.png" />
        </div>
        <p className="customerckeck-title">Welcome to be our partner!</p> 
        <div className="customerckeck">
            <p className="ckeckform-title">Please check again, before submit.</p>
            <p className="ckeckform-detail">Name:  {newAccount.account_name}</p> 
            <p className="ckeckform-detail">Email address:  {newAccount.account_email}</p> 
            <p className="ckeckform-detail">Password:  {newAccount.account_password}</p> 
            <p className="ckeckform-detail">Job title:  {newAccount.account_job}</p> 
            <p className="ckeckform-detail">Phone number:  {newAccount.account_phone}</p> 
            <p className="ckeckform-detail">Zip:  {newAccount.account_zip}</p> 
            <p className="ckeckform-detail">Address:  {newAccount.account_address} </p> 
            <p className="ckeckform-detail">Administrator:  {newAccount.account_admin ? 'True':'False'} </p> 
        </div>
        <Button
            htmlType="submit"
            className="customersent_btn"
            onClick={() =>SubmitAccount(0)}
            loading={loadings[0]}
        >
            Submitted
        </Button>
      </div>
    </div>
  );
};