import { useContext, useState, useEffect } from "react";
import { Card, Row, Col } from "antd"
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import { setCustomerDetail } from "../../actions";
import firebase from 'firebase/app'

export default function CustomerItem(props) {
    const { dispatch } = useContext(StoreContext);
    const [dataToShow, setData] = useState([]);

    const company = props.company.comp;
    const callback = props.company.callback;

    const onClick = (event) => {
      callback(company);
    };

    useEffect(() => {
      }, []);

    return (
        <div className="list_item" onClick={onClick}>
            <p className="custom_name">{(company.role === "L3")?"Landscaping":"Dealer"}</p> 
            <p className="custom_name">{company.name}</p> 
            <p className="custom_address">{company.admin}</p> 
        </div>
    );
}
