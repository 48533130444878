import { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import { setAccountDetail } from "../../actions";
import firebase from 'firebase/app'

export default function AccountItem({ account }) {
    const { dispatch } = useContext(StoreContext);
    const [dataToShow, setData] = useState([]);
    //console.log("AccountItem => ", account);

    return (
        <Link to={`/accountitem/${account.name}`}
            onClick={() => {
                setAccountDetail(dispatch, account);
            }}
        >
          <div className="list_item">
            <p className="account_company">{account.company}</p> 
            <p className="acc_name">{account.name}</p> 
            <p className="account_email">{account.email}</p> 
            <p className="account_job">{account.job}</p> 
            <p className="account_phone">{account.phone}</p> 
            <p className="account_address">{account.address}</p>
            <p className="account_address">{(account.admin)?"Yes":"No"}</p> 
          </div>
        </Link>
    );
}
