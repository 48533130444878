import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';

import "firebase/storage"
import _ from 'lodash';

import {firebaseConfig} from "./config.js"

export const getProject =  () => {
  return firebaseConfig.projectId;
}

firebase.initializeApp(firebaseConfig);
// const customersCollectionRef = firebase.firestore().collection("users");
// const customersDocRef = customersCollectionRef.doc("json");
// const allCustomersCollectionRef = customersDocRef.collection("users");
const customersDocRef =  firebase.firestore().collection("users");

const robotsCollectionRef = firebase.firestore().collection("robots");
const robotsDocRef = robotsCollectionRef.doc("json");
const allRobotsCollectionRef = robotsDocRef.collection('robots');

const accountsCollectionRef = firebase.firestore().collection("users");
const accountsDocRef = accountsCollectionRef.doc("json");
const allAccountsCollectionRef = accountsDocRef.collection("users");

const branchsDocRef =  firebase.firestore().collection("users");

export const storageRef = firebase.storage().ref(); 

//REFERENCE AUTH
const auth = firebase.auth();

export const signInWithEmailPassword = async (email, password) => {
  return await auth.signInWithEmailAndPassword(email, password);
}

export function dp_log(log) {
  console.log("log:", log);
  console.log("log user1:", log.user[1]);
  firebase.firestore().collection("dp_logs").add({
    s_acc: log.user[0].email,
    s_uid: log.user[1].claims.uid,
    s_comp: log.user[1].claims.company,
    s_role: log.user[1].claims.role,
    action: log.action,
    item: log.item,
    meta: log.meta,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  })
  .then(() => {
      console.log("Document successfully written!");
  })
  .catch((error) => {
      console.error("Error writing document: ", error);
  });
}

export async function getDealer(uid) {
  let doc = await firebase.firestore().collection("users").doc(uid).get();
  if (doc.exists){
    console.log("getDealer:", doc.data());
    console.log("getDealer:", doc.data().dealer);
    return doc.data().dealer;
  }
  throw new Error("No such document");
  
  //console.log("getDealer:", err);
}

export async function checkUserRole(uid) {
  try {
      let role = await getValues(uid);
      console.log("checkUserRole role", role);
      return role;
  } catch(err) {
      console.log("ERROR:", err);
  }
}
async function getValues(uid) {
  let doc = await firebase.firestore().collection("users").doc(uid).get();
  if (doc.exists) return doc.data().role;
  throw new Error("No such document");
}
export async function activateRobot(userInfo, robots, date, transCompany){
  //Activate robot
  //0. stamp selling date and warranty_end_date
  //1. set sim card number
  console.log("activateRobot:", robots);
  var db = firebase.firestore();
  var batch = db.batch();

  robots.forEach(robot => {
    console.log("activateRobot:", robot);
    var robotRef = db.collection("robots").doc(robot.id);
    //let sim_number = robot.sim_number;
    let selling_date = firebase.firestore.Timestamp.fromDate(new Date(date));
    let war_date = firebase.firestore.Timestamp.fromDate(new Date(date)).toDate();
    war_date.setSeconds(war_date.getSeconds() + 60*60*24*365*2 + 60*60*24*7);
    let pay_date = firebase.firestore.Timestamp.fromDate(new Date(date)).toDate();
    pay_date.setSeconds(pay_date.getSeconds() + 60*60*24*365*1 + 60*60*24*7);
    batch.set(robotRef, 
      {warranty:{
        activate:true,
        //sim_number:sim_number, 
        //storage_zip:storage_zip,
        selling_date:selling_date,
        warranty_end_date:war_date},
       bs:{
        pay_exp: pay_date
       } 
      }, { merge: true });  

      // if (++count >= 500 || !datas.length) {
      //   await batch.commit();
      //   batch = admin.firestore().batch();
      //   count = 0;
      // }  
  });

  var emlFields = {
    //to: ['alan_chen@ur-srobot.com'],
    //bcc: 'henry_chen@ur-srobot.com',
    transcompany: transCompany.name
  };
  try{
    var result = await batch.commit();
    console.log("activateRobot:", result);

    var configRef = firebase.firestore().collection('configurations').doc('act_ntf_eml');
    var config = await configRef.get();
    emlFields.to = (_.has(config.data(),'to'))?config.data().to:'URS_IT@ur-srobot.com';
    emlFields.bcc = (_.has(config.data(),'bcc'))?config.data().bcc:'';

    console.log("config:", config.data());

    var userRef = firebase.firestore().collection('users').doc(userInfo[1].claims.uid);
    var user = await userRef.get();
    
    emlFields.activations = '';
    let cnt = 1;
    robots.forEach(robot => {
      console.log('robot', robot);
      emlFields.noofactivations = cnt;
      emlFields.activations += `${cnt++}. ${robot.psn}/${robot.model}\n`;
    });
    emlFields.username = user.data().name;
    emlFields.useraccount = user.data().email;
    emlFields.usercompany = user.data().company;
    emlFields.subject = `[Nexmow Notification] Robot Activation(${emlFields.noofactivations}) by ${user.data().name} from ${user.data().company}`;
    emlFields.activationtime =  new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }) + '(PDT)';
    console.log("emlFields:", emlFields);
    sendEmail_funcs(emlFields);
  }catch (error) {
    console.log(`Error: ${error}`);  
  }

  robots.forEach(robot => {
    dp_log({user:userInfo, action: "actv_Robot", item: robot.id, meta: JSON.stringify({robot: robot, date:date})});
  });
}
/* export async function transferRobot2(robots, company, dealer) {
  //switch company
  //0. robots to transfer "robotData"
  //1. change robot company field "companyName"
  //2. add myself to robot dealer "array"
  console.log("transferRobot2:", robots);
  console.log("transferRobot2:", company);
  console.log("transferRobot2:", dealer);
  var db = firebase.firestore();
  var batch = db.batch();
  robots.forEach(robot => {
    console.log("transferRobot2 robot:", robot);
    var robotRef = db.collection("robots").doc(robot.id);
    batch.set(robotRef, {company: company, dealer:firebase.firestore.FieldValue.arrayUnion(dealer)}, { merge: true });
  });

  try{
    var result = await batch.commit();
    console.log("transferRobot:", result);
  }catch (error) {
    console.log(`Error: ${error}`);  
  }
} */

export async function lockMap(userInfo, maps) {
  //lock map
  //0. set lock field in zone document
  console.log("lockMap:", maps);

  var db = firebase.firestore();
  var batch = db.batch();

  for (const map_id in maps) {
    console.log(`${map_id}: ${maps[map_id]}`);
    var zoneRef = db.collection("zones").doc(map_id);
    batch.set(zoneRef, {lock:maps[map_id]}, { merge: true });
  }
  try{
    var result = await batch.commit();
    console.log("lockMap:", result);
  }catch (error) {
    console.log(`Error: ${error}`);  
  }
  for (const map_id in maps) {
    dp_log({user:userInfo, action: "lock_Map", item: map_id, meta: JSON.stringify({lock:maps[map_id]})});
  } 
}

export async function transferRobot(userInfo, robots, company) {
  //switch company
  //0. robots to transfer "robotData"
  //1. change robot company field "companyName"
  //2. add myself to robot dealer "array"
  console.log("transferRobot robots:", robots);
  console.log("transferRobot company:", company);
  //console.log("transferRobot:", dealer);
  var db = firebase.firestore();
  var batch = db.batch();
  var my_stack = userInfo[2];
  var target_stack = [...my_stack]; //logged-in user stack
  target_stack.push({role: userInfo[1].claims.role, company: userInfo[1].claims.company}); //加上目前logged-in user
  target_stack.push({company:company.name, role: company.role}); //加上目地公司
  console.log("transferRobot target_stack:", target_stack);

  robots.forEach(robot => {
    console.log("transferRobot robot:", robot);
    var robotRef = db.collection("robots").doc(robot.id);
    batch.set(robotRef, {dealer:firebase.firestore.FieldValue.arrayRemove(...robot.dealer)}, { merge: true });
    batch.set(robotRef, {company: company.name, dealer:firebase.firestore.FieldValue.arrayUnion(...target_stack)}, { merge: true });
  });

  try{
    var result = await batch.commit();
    console.log("transferRobot result:", result);
  }catch (error) {
    console.log(`Error: ${error}`);  
  }

  robots.forEach(robot => {
    dp_log({user:userInfo, action: "tran_Robot", item: robot.id, meta: JSON.stringify({robot:robot, company:company, target_stack:target_stack})});
  });
}

export const registerWithEmailPassword = async (email, password, displayName) => {
  await auth.createUserWithEmailAndPassword(email, password);
  const user = auth.currentUser;
  await user.updateProfile({ displayName })
  return user;
}

export const createUser_funcs = async (user) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('create_user');
  let sendData = user; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("createUser_funcs result:", result);
  return result;
}

export const setClaim_funcs = async (user) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('set_claim');
  let sendData = user; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("setClaim_funcs result:", result);
  return result;
}

export const registerWithEmailPassword_customer_funcs = async (newCustomer) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('create_customer');
  //newCustomer['customer_password'] = 'abcd1234';
  console.log("registerWithEmailPassword_customer_funcs:", JSON.stringify(newCustomer));
  let sendData = newCustomer; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  return result;
}

export const concave_CCPP_funcs = async (zone_id) => {

  const cmd = 'cal-un-convex-hull';
  //const zone_id = zone_id;//'kj0lJpPh2xxbOrQXQG5';

  //slow
  //https://asia-northeast1-m68x-stage-21wk39.cloudfunctions.net/urs_rd_tool_entry/v2?cmd=cal-un-convex-hull&zone_id=dALm4O4prR5uNeIPjWNA
  //faster
  //https://asia-northeast1-m68x-stage-21wk39.cloudfunctions.net/urs_rd_tool_entry/v2?cmd=cal-un-convex-hull&zone_id=kj0lJpPh2xxbOrQXQG5y
  
  const projectId = getProject();

  let baseURL = "";
  if(projectId === "m68x-release-21wk47"){
    baseURL = 'https://asia-northeast1-m68x-release-21wk47.cloudfunctions.net/urs_rd_tool_entry2/v2';
  }else if(projectId === "m68x-stage-21wk39"){
    baseURL = "https://asia-northeast1-m68x-stage-21wk39.cloudfunctions.net/urs_rd_tool_entry/v2";
  }else if(projectId === "productm-dev"){
    baseURL = "https://asia-northeast1-productm-dev.cloudfunctions.net/urs_rd_tool_entry/v2";
  }else{
    baseURL = "";
  }
  
  const commands = [`?cmd=cal-un-convex-hull&zone_id=${zone_id}`,
              `?cmd=cal-un-convex-hull-pattern&zone_id=${zone_id}&pattern=-1`,
              `?cmd=cal-un-convex-hull-pattern&zone_id=${zone_id}&pattern=0`,
              `?cmd=cal-un-convex-hull-pattern&zone_id=${zone_id}&pattern=30`,
              `?cmd=cal-un-convex-hull-pattern&zone_id=${zone_id}&pattern=60`,
              `?cmd=cal-un-convex-hull-pattern&zone_id=${zone_id}&pattern=90`];
  const urls = commands.map(command => baseURL+command);
  console.log(urls);

  const functions = firebase.functions();
  
  //const functions = firebase.functions();
  //functions.useEmulator('127.0.0.1',5001);  //You can use oncall emulator locally
  try{
    const triggerCallable = functions.httpsCallable('concave_CCPP2', {timeout: 540000});
    let sendData = {url:urls}; // 要送出去的資料
    let result = await triggerCallable(sendData);
    console.log("concave_CCPP_funcs result:", result);
    console.log("concave_CCPP_funcs result.data:", result.data);

    var res = [];
    var degrees = ["generic", "-1", "0", "30", "60", "90"];
    var pass = "pass:";
    var fail = "fail:";
    result.data.forEach((v, i) => {
      if(v.status === "fulfilled"){
        res.push(true);
        pass = pass+degrees[i]+", ";
      }else{
        res.push(false);
        fail = fail+degrees[i]+", ";
      }
    });
    console.log(pass, fail);
    if(res.filter(r => r===true).length === degrees.length){
      return `map generation finished without problem (${pass})`;
    }else{
      return `map gen result => ${pass} / ${fail}`;
    }
    
    
    

  } catch (e){
    console.log("concave_CCPP_funcs error:", e);
    return ;//{data:{result:"error"}};
  }
}

export const sendEmail_funcs = async (emlFields) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('sendEmail_onCall');
  let sendData = emlFields; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("email_funcs result:", result);
  return result;
}

/* export const registerWithEmailPassword_account_funcs = async (newAccount) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('create_account');
  //newCustomer['customer_password'] = 'abcd1234';
  //newAccount['role'] = newAccount.customer_dealer ? 'L2':'L3';  //20230119, this line is useless, restful api uses users context to set role
  console.log("registerWithEmailPassword_account_funcs:", JSON.stringify(newAccount));
  let sendData = newAccount; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  return result;
} */

export const registerWithEmailPassword_branch_funcs = async (email, password, newBranch) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('create_branch');
  //newCustomer['customer_password'] = 'abcd1234';
  //newBranch['role'] = newBranch.customer_dealer == 'L4';
  console.log("registerWithEmailPassword_branch_funcs:", JSON.stringify(newBranch));
  let sendData = newBranch; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  return result;
}

export const update_customer_funcs = async (userInfo, userData) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('update_customer');
  let sendData = userData; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  dp_log({user:userInfo, action: "update_Customer", item: userData.id, meta: JSON.stringify({user: userData})});
  return result;
}

export const update_account_funcs = async (userInfo, userData) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('update_account');
  console.log("update_account_funcs:", JSON.stringify(userData));
  let sendData = userData; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  dp_log({user:userInfo, action: "update_Account", item: userData.id, meta: JSON.stringify({user: userData})});
  return result;
}

export const update_branch_funcs = async (userData) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('update_branch');
  console.log("update_branch_funcs:", JSON.stringify(userData));
  let sendData = userData; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  return result;
}

export const stopTheTaskAsync_funcs = async (task) => {
  task.projectId = firebaseConfig.projectId;
  const functions = firebase.functions();

  try{
    const triggerCallable = functions.httpsCallable('end_task_onCall');
    console.log("stopTheTaskAsync_funcs:", task);
    let sendData = task;//userData; // 要送出去的資料
    let result = await triggerCallable(sendData);
    if( (_.has(result,'data')) && result.data !== 'error'){
      return 'OK';
    }else{
      return 'error';
    }
  }catch(err){
    console.log("stopTheTaskAsync_funcs exception err:", err);
    return 'error';
  }

  //Can't normally stop this task, hard reset firebase documents and 
 // result = await firebase.firestore().collection("dp_docBk").doc(`${ts}_stopTask`).update({timestamp: firebase.firestore.FieldValue.delete()});
  

}

const robotReadAsync = async (param) => {

  return new Promise((resolve, reject) => { 
    const docRef = firebase.firestore().collection('robots').doc(param.robot);
    docRef.get().then(doc => {
      if(doc.exists){
        resolve(doc);
      }
    }).catch((err) => {
      console.log("Error getting document:", err);
      reject();
    })
  });
}
const robotWriteAsync = async (param) => {

  return new Promise((resolve, reject) => { 

    firebase.firestore().collection("dp_docBk").doc(`${param.ts}_stopTask`).collection('robots').doc(param.robot.id).set(param.robot.data())
    .then(() => {
        console.log("Document successfully written!");
        resolve('write ok');
    })
    .catch((error) => {
        console.error("Error writing document: ", error);
        reject('write fail');
    });
  });
}

export const docBackup = async (task, cs_name, cs_email) => {

  const ts = new Date().getTime();
  console.log("ts:", ts);
  console.log(`${ts}_stopTask`);
  console.log(task);

  //0. create log document
  try{
    let desc = {data:{...task}, timestamp: firebase.firestore.Timestamp.fromDate(new Date()), cs_name: cs_name, cs_email: cs_email};
    await firebase.firestore().collection("dp_docBk").doc(`${ts}_stopTask`).set(desc);
  }catch(err){
    console.log("can't create single log");
  }  
  //1. backup task doc
  var robots = [];
  const docRef = firebase.firestore().collection('users').doc(task.user.uid).collection('tasks').doc(task.task_id);
  try {
    var doc_task = await docRef.get();
    if(doc_task.exists){
      console.log("exist");
      //console.log("result:", result);
      let result = await firebase.firestore().collection("dp_docBk").doc(`${ts}_stopTask`).collection('tasks').doc(task.task_id).set(doc_task.data());
      console.log('Write task:', result);
      robots = (_.has(doc_task.data(),'robots_id'))?doc_task.data().robots_id:[];
    }else{
      console.log("not exist");
    }
  } catch (err) {
      console.log("Error getting document:", err);
  }

  //2. backup zone doc
  const docRef_zone = firebase.firestore().collection('zones').doc(task.zone_id);
  try {
    var doc_zone = await docRef_zone.get();
    console.log('Read zone:', task.zone_id, doc_zone.data());
    if(doc_zone.exists){
      let result = await firebase.firestore().collection("dp_docBk").doc(`${ts}_stopTask`).collection('zones').doc(task.zone_id).set(doc_zone.data());
      console.log('Write zone:', result);
    }
  } catch (err) {
      console.log("Error getting document:", err);
  }

  //3. backup all robot docs
  const promises = [];
  robots.map(robot => promises.push(robotReadAsync({ts:ts,robot:robot})));
  let res = await Promise.all(promises);
  console.log("Read robots:", res);
  const promises2 = [];
  res.map(robot => promises2.push(robotWriteAsync({ts:ts,robot:robot})));
  let res2 = await Promise.all(promises2);
  console.log('Write robots:',res2);
}

export const create_admin = async () => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('create_admin');
  //console.log("update_account_funcs:", JSON.stringify(userData));
  let sendData = ""; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  return result;
}

export const create_robot = async (psn, cpu, company) => {

  console.log("create_robot:", psn);
  var model = "M1-" + cpu.slice(-3);

  try{
    let doc = await firebase.firestore().collection("robots").doc(cpu).set({
      company: company,
      warranty: {psn: psn, activate: false, sim_number:"", storage_zip:""},
      model: model,
      dealer: [{role:"L0", company: company}]
    });
  }catch (error) {
    console.log(`Error: ${error}`);  
  }
}
export const delete_robot = async (psn, cpu, company) => {

  // console.log("delete_robot:", psn);
  // var model = "M1-" + cpu.slice(-3);

  // try{
  //   await firebase.firestore().collection("robots").doc(cpu).delete();
  // }catch (error) {
  //   console.log(`Error: ${error}`);  
  // }
}


export const updateUserInfoApi = async (email, password, displayName) => {
  const user = auth.currentUser;
  if(displayName)
    await user.updateProfile({displayName });
    // 等於{displayName:displayName}
  if(email)
    await user.updateEmail(String(email));
  if(password)
    await user.updatePassword(password);
  return user;
}

export const signOut = () => {
  auth.signOut();
}

export const resetPassword = (email) => {
  console.log("resetPassword: ", email);
  auth.sendPasswordResetEmail(email)
  .then(() => {
    // Password reset email sent!
    console.log("Password reset email sent");
    alert(`Password reset success! Please check your email to reset your password`);
  })
  .catch((error) => {
    //var errorCode = error.code;
    //var errorMessage = error.message;
    console.log("Password reset error", error.code, error.message);
    alert(`Password reset error! (error:${error.message})`)
  });
}

export const checkLoginApi = () => {
  const user = auth.currentUser;
  if(user){
    return user.uid? true : false;
  }else{
    return false
  }
}

export const getNewCustomerById = async (users) => {
  const doc = await customersDocRef.doc(users).get();
  return doc.data()
}

export const getNewAccountById = async (accountId) => {
  const doc = await allAccountsCollectionRef.doc(accountId).get();
  return doc.data()
}

export const getNewBranchById = async (branchId) => {
  const doc = await branchsDocRef.doc(branchId).get();
  return doc.data()
}

export const getNewRegisterById = async (registerId) => {
  const doc = await allAccountsCollectionRef.doc(registerId).get();
  return doc.data()
}