import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button, Checkbox } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { StoreContext } from "../../store";
import { requestBranchDetail } from "../../actions"
import { registerWithEmailPassword_branch_funcs } from "../../Api"

export default function AddBranchCheck({branchId}) {
  const { state: { branchDetail:{ newBranch }, userSignin: {userInfo}}, dispatch } = useContext(StoreContext);
   
  const history = useHistory();
  const AddBranch = () => {
    history.push("/addbranch");
  };

  console.log("AddBranchCheck enter newBranch: ", newBranch);
  console.log("AddBranchCheck enter userInfo: ", userInfo);

  const [loadings, setLoadings] = useState([]);
  const SubmitBranch = async (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    const result = await registerWithEmailPassword_branch_funcs(newBranch.branch_email, newBranch.branch_email, newBranch);
    history.push("/branchmag");
    //console.log( getCurrentTimestamp, Date.now())
  };

  // function getCurrentTimestamp() {
  //   return Date.now();
  // }

  return (
    <div className="main-layout">
      <div className="addcustomer-wrapper">
        <img src="/images/ad_2_img.png" className="ad_1"/>
        <img src="/images/back_btn.png" className="back_btn" onClick={AddBranch}/>
      </div>
      <div className="addcustomer-form">
        <div className="nexmowlogo">
          <img src="/images/brand_logo_img.png" />
        </div>
        <p className="customerckeck-title">Welcome to be our partner!</p> 
        <div className="customerckeck">
            <p className="ckeckform-title">Please check again, before submit.</p>
            <p className="ckeckform-detail">Name:  {newBranch.branch_name}</p> 
            <p className="ckeckform-detail">Email address:  {newBranch.branch_email}</p> 
            <p className="ckeckform-detail">Password:  {newBranch.branch_password}</p> 
            <p className="ckeckform-detail">Job title:  {newBranch.branch_job}</p> 
            <p className="ckeckform-detail">Phone number:  {newBranch.branch_phone}</p> 
            <p className="ckeckform-detail">Zip:  {newBranch.branch_zip}</p> 
            <p className="ckeckform-detail">Address:  {newBranch.branch_address} </p>
            <p className="ckeckform-detail">Administrator:  {newBranch.branch_admin ? 'True':'False'} </p> 
        </div>
        <Button
            htmlType="submit"
            className="customersent_btn"
            onClick={() => SubmitBranch(0)}
            loading={loadings[0]}
        >
            Submitted
        </Button>
      </div>
    </div>
  );
};