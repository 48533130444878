import { Layout } from "antd";
import AddBranchCheck from "../../components/branch/AddBranchCheck";
const { Content } = Layout;

function BranchCheck(props) {
  return (
    <Layout className="container main-layout">
      <Content>
        <AddBranchCheck />
      </Content>
    </Layout>
  );
}

export default BranchCheck;
