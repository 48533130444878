import React, { useContext, useEffect,useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Text, Row, Col} from 'antd';
import { StoreContext } from "../../store"
import RobotItem from "../robot/RobotItem";
import robots from "../../json/robots.json";
import Lottie from 'react-lottie';
import animationData from '../../animations/loading_anim.json';
import firebase from 'firebase/app'
import { setRobotDetail } from "../../actions"
import { Space, Table, Spin, Tooltip } from 'antd';
import { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {  Drawer, Descriptions, Popover } from 'antd';
import Icon, { HomeOutlined } from '@ant-design/icons';
import _ from 'lodash';
import GoogleMap from 'google-map-react';
import * as semver from "semver";
import { forwardRef, useImperativeHandle } from "react"
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';

export default function RobotDetail() {
  const [data, setData] = useState([]);
  const { state:{ robotDetail: { loading }, userSignin: { userInfo } }, dispatch } = useContext(StoreContext);
  const history = useHistory();
  const Transfer = () => {
    history.push("/transferrobot");
  };
  const Activate = () => {
    history.push("/activaterobot");
  };
  const [visible, setVisible] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentDataSource, setCurrentDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [compFilter, setCompFilter] = useState([]);
  const [swVerFilter, setSwVerFilter] = useState([]);
  const [robot, setLocalRobot] = useState({});
  const [location, setLocation] = useState({lat: 0,lng: 0});
  const [mapObj, setMapObj] = useState([undefined, undefined]);
  const [prevMaker, setMarker] = useState(undefined);
  const searchInput = useRef(null);

  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const ref = useRef(null);

  const isL0L1 = () => {
    return (userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1");
  }
  const showDrawer = () => {
    setOpen(true);
  };  
  const onClose = () => {
    setOpen(false);
    ref.current.reset();
  };
  
  const onSave = (event) => {
    setIsSaving(true);
    console.log("ref:", ref);
    let memo = {};
    memo.text = ref.current.value;
    memo.timestamp = (memo.text!=="")?firebase.firestore.Timestamp.fromDate(new Date()):firebase.firestore.Timestamp.fromDate(new Date(null));
    
    firebase.firestore().collection("robots").doc(robot.id).set({
      memo
    }, { merge: true })
    .then(() => {
        let t_robot = data.findIndex(element => element.id === robot.id);
        data[t_robot] = {...data[t_robot], memo};
        let data2 = data.filter(element=>element);
        //console.log(t_report, data2);
        setData(data2);
        console.log("Document successfully written!");
        setIsSaving(false);
        setOpen(false);
    })
    .catch((error) => {
        console.error("Error writing document: ", error);
        alert("Network problem, try again later!");
        setIsSaving(false);
    });
  
  };


  function createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.BOTTOM_CENTER,
          mapTypeIds: [
              maps.MapTypeId.ROADMAP,
              maps.MapTypeId.SATELLITE,
              maps.MapTypeId.HYBRID
          ]
      },

    };
  }
  const renderMarkers = (map, maps) => {
    //console.log("renderMarkers:", map, maps);
    //console.log("location:", location, robot.model);

    let marker = new maps.Marker({
      position: location,//{ lat: -25.363, lng: 131.044 },
      map,
      label: {text: `${robot.model}`, color: "blue"}
    });
    setMarker(marker);
    setMapObj([map, maps]);
    //return marker;
   };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //selectedKeys[0] search string
    //dataIndex: the column name
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    confirm();  //Henry: Add
    setSearchedColumn(dataIndex);//Henry: Add
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters,selectedKeys, confirm, dataIndex) }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true })
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: <div> PSN&nbsp;
      <Tooltip placement='bottom' title="Product Serial Number">
      <InfoCircleOutlined />
      </Tooltip>
    </div>,
      dataIndex: 'psn',
      key: 'psn',
      //width: '30%',
      ...getColumnSearchProps('psn'),
      sorter: (a, b) => a.psn.localeCompare(b.psn),
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder:'ascend',
      //render: (text) => <ModalImage medium="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/2880px-Google_2015_logo.svg.png" alt={text}/>,
    },
    {
      title: 'Model',
      dataIndex: 'line',
      key: 'line',
      //width: '30%',
      ...getColumnSearchProps('model'),
      sorter: (a, b) => a.line.localeCompare(b.line),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: <div> Name&nbsp;
      <Tooltip placement='bottom' title="QRCode">
      <InfoCircleOutlined />
      </Tooltip>
    </div>,
      dataIndex: 'model',
      key: 'model',
      //width: '30%',
      ...getColumnSearchProps('model'),
      sorter: (a, b) => a.model.localeCompare(b.model),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: 'Warranty',
      dataIndex: 'warranty',
      key: 'warranty',
      //width: '30%',
      ...getColumnSearchProps('warranty'),
      sorter: (a, b) => a.warranty.toUpperCase() > b.warranty.toUpperCase(),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: <div> Subscription&nbsp;
      <Tooltip placement='bottom' title="Cloud/RTK service subscription">
      <InfoCircleOutlined />
      </Tooltip>
    </div>,
      dataIndex: 'subscription',
      key: 'subscription',
      //width: '30%',
      ...getColumnSearchProps('subscription'),
      sorter: (a, b) => a.subscription.toUpperCase() > b.subscription.toUpperCase(),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: "SW Ver",
      dataIndex: 'swVer',
      key: 'swVer',
      //width: '30%',
      //...getColumnSearchProps('swVer'),
      sorter: (a, b) => semver.lt((semver.clean(a.swVer)===null)?"0.0.0":semver.clean(a.swVer), (semver.clean(b.swVer)===null)?"0.0.0":semver.clean(b.swVer))?-1:1,//console.log(a.swVer, semver.clean(a.swVer)),//semver.lt(semver.clean(a.swVer), semver.clean(b.swVer))?1:-1,//a.swVer.toUpperCase() > b.swVer.toUpperCase(),
      sortDirections: ['ascend', 'descend', 'ascend'],
      filters: swVerFilter,
      onFilter: (value, record) => record.swVer===value,
    },
    {
      title: <div> Company&nbsp;
      <Tooltip placement='bottom' title="Owner Company">
      <InfoCircleOutlined />
      </Tooltip>
      </div>,
      dataIndex: 'company',
      key: 'company',
      //width: '30%',
      //...getColumnSearchProps('company'),
      sorter: (a, b) => a.company.localeCompare(b.company),
      sortDirections: ['ascend', 'descend', 'ascend'],
      filters: compFilter,
      onFilter: (value, record) => record.company===value,
    },
    {
      title: "Repair Log",
      dataIndex: 'memo',
      key: 'memo',
      sorter: (a, b) => (a.memo.timestamp.toDate().getTime()>b.memo.timestamp.toDate().getTime())?1:-1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (_, { memo }) => (
        <>
          <Popover content={<p>{memo.text}</p>} overlayStyle={{width: "20vw"}}title="Memo">
            {<a href="#">{(memo.timestamp.seconds!==0)?timeShortStr(memo.timestamp.toDate()):""}</a>}
          </Popover>
        </>
      ),
    },
  ];
  
  useEffect(()=>{
    //console.log("navbar:", userInfo[1].claims.role);
    if(userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1" || userInfo[1].claims.role === "L2"){
      setVisible(true);  //show activate and transfer button
    }else{
      setVisible(false);
    }   
  },[]);

  function timeStr(date){
    return new Intl.DateTimeFormat('en-us', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }).format(date);
  }
  function timeShortStr(date){
    return new Intl.DateTimeFormat('en-us', {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

  useEffect(() => {
    var company = userInfo[1].claims.company;
    var role = userInfo[1].claims.role;
    let robots = [];
    const robotsRef = firebase.firestore().collection('robots').where("dealer", "array-contains", {role:role, company:company})
      robotsRef.get().then((querySnapshot) => {
        querySnapshot.forEach((robot) => {
          console.log("robot", robot.data());
          var war_string = "In stock"
          if(_.has(robot.data(),'warranty.activate') && robot.data().warranty.activate===true){
            //console.log("robot activated");
            //var beginTime = (_.has(robot.data(),'warranty.selling_date') && robot.data().warranty.selling_date instanceof firebase.firestore.Timestamp)?timeStr(robot.data().warranty.selling_date.toDate()):"";
            var endTime = (_.has(robot.data(),'warranty.warranty_end_date') && robot.data().warranty.warranty_end_date instanceof firebase.firestore.Timestamp)?timeStr(robot.data().warranty.warranty_end_date.toDate()):"n/a";
            //var war_string = `Warranty from ${doc.data().warranty.warranty_start_date.toDate().toDateString()} - ${doc.data().warranty.warranty_end_date.toDate().toDateString()}`
            //war_string = `Warranty  ${beginTime} - ${endTime}`
            war_string = `${endTime}`
          }
          //robots.push({psn : robot.data().warranty.psn, model: robot.data().model, warranty: war_string, company: robot.data().company, id: robot.id});
          let psn = (_.has(robot.data(),'warranty.psn'))?robot.data().warranty.psn:"";
          let model = (_.has(robot.data(),'model'))?robot.data().model:"";
          let company = (_.has(robot.data(),'company'))?robot.data().company:"";
          let subscription = (_.has(robot.data(),'bs.pay_exp') && robot.data().bs.pay_exp instanceof firebase.firestore.Timestamp && typeof robot.data().bs.pay_exp!=="string")?timeStr(robot.data().bs.pay_exp.toDate()):"n/a";
          let imsi = (_.has(robot.data(),'sim_info.IMSI'))?robot.data().sim_info.IMSI:"no sim";
          let liftTimes = (_.has(robot.data(),'usage_data.Lifetime.blade_adj_count.Total'))?robot.data().usage_data.Lifetime.blade_adj_count.Total:"n/a";
          let miles =  (_.has(robot.data(),'usage_data.Lifetime.wheel_odometry'))?(robot.data().usage_data.Lifetime.wheel_odometry/1.6).toFixed(2):"n/a";
          let runningTime =  (_.has(robot.data(),'usage_data.Lifetime.wheel_running_time'))?robot.data().usage_data.Lifetime.wheel_running_time:"n/a";
          let lastOnline = (_.has(robot.data(),'updated_time') && robot.data().updated_time instanceof firebase.firestore.Timestamp)?timeStr(robot.data().updated_time.toDate()):"n/a";
          let swVer = (_.has(robot.data(),'config.sw_hw_version'))?robot.data().config.sw_hw_version[0]:"n/a";
          let loc = (_.has(robot.data(),'config.dev_status.latlng_info') && robot.data().config.dev_status.latlng_info.length>0)?robot.data().config.dev_status.latlng_info:[0,0,0];
          let id =  robot.id;
          let memo = (_.has(robot.data(),'memo'))?robot.data().memo:{text:"", timestamp: firebase.firestore.Timestamp.fromDate(new Date(null))};

          robots.push({psn: psn, line: model.split("-")[0], model: model, warranty: war_string, company: company, id: id, subscription: subscription, imsi: imsi, liftTimes: liftTimes, miles: miles, runningTime: runningTime, lastOnline: lastOnline, swVer: swVer, loc:loc, memo:memo});
          
          //console.log("robot length: => ", robots);
        });
        //setGrobots(JSON.parse(JSON.stringify(robots)));
        //var sorte_robots = robots;
        // robots.filter(i => i.psn === undefined);
        //console.log("r: => ", r);
        setData(robots);
        setCurrentDataSource(robots);
        setIsLoading(false);

        let companies = [...new Set(robots.map(a => a.company))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?1:-1).map(x => ({text: x, value: x}));
        setCompFilter(companies);
        let swVers = [...new Set(robots.map(a => a.swVer))].sort((a,b) => (a.toLowerCase()>b.toLowerCase())?-1:1).map(x => ({text: x, value: x}));
        setSwVerFilter(swVers);

      });
  }, []);

  useEffect(() => {
  
    let map = mapObj[0];
    let maps = mapObj[1];

    if(prevMaker !== undefined){
      console.log("Remove marker");
      prevMaker.setMap(null);
    }

    if(map !== undefined && maps !== undefined){
      console.log("Create marker:", robot.model);
      
      //map.clearOverlays();
      let marker = new maps.Marker({
        position: location,//{ lat: -25.363, lng: 131.044 },
        map,
        label: {text: `${robot.model}`, color: "blue"}
        });
        setMarker(marker);  
    }
  }, [robot, location]); 

  return (
    <div className="layout_limit">
       <Drawer title="Report Detail" placement={'right'} 
      onClose={onClose} open={open} visible={open} size='large' width={"50%"}
      extra={
          !isL0L1()?null:
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onSave} loading={isSaving}>Save</Button>
          </Space>
      }
      >
        <div className="list-wrapper">
          <div className="report_info_wrapper">
          <Descriptions title= "Robot Info" layout="vertical">
            
            <Descriptions.Item label={<span style={{color:'grey'}}>PSN</span>} >{<span style={{paddingLeft:'10px'}}>{robot.psn}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Company</span>}>{<span style={{paddingLeft:'10px'}}>{robot.company}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>IMSI</span>}>{<span style={{paddingLeft:'10px'}}>{robot.imsi}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>SW Version</span>}>{<span style={{paddingLeft:'10px'}}>{robot.swVer}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Last online time</span>}>{<span style={{paddingLeft:'10px'}}>{robot.lastOnline}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Location</span>}>{<span style={{paddingLeft:'10px'}}>{(_.has(robot,'loc'))?`${robot.loc[0]},${robot.loc[1]}`:'n/a'}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Usage miles</span>}>{<span style={{paddingLeft:'10px'}}>{robot.miles}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Usage time(Hour)</span>}>{<span style={{paddingLeft:'10px'}}>{robot.runningTime}</span>}</Descriptions.Item>
            <Descriptions.Item label={<span style={{color:'grey'}}>Lift belt lifetime</span>}>{<span style={{paddingLeft:'10px'}}>{robot.liftTimes}</span>}</Descriptions.Item>
          </Descriptions>
          </div>
          <RobotDetailItem props={{robot:robot , userInfo:userInfo}} ref = {ref}/>
          <div style={{ height: '100vh', width: '100%'}}>
          <p>{<span style={{color:'grey'}}>Map:</span>}{<span style={{paddingLeft:'10px'}}></span>}</p>
            <GoogleMap
            bootstrapURLKeys={{ key: "AIzaSyC1fM2zabVh0EXACjKZb7kR5FEXF3RdRys" }}
            //defaultCenter={{lat: (_.has(robot,'loc'))?`${robot.loc[0]}`:0,lng: (_.has(robot,'loc'))?`${robot.loc[1]}`:0}}
            center={location}
            language= "en"
            region= 'en'
            //center = { {lat: -25.363, lng: 131.044} }
            defaultZoom={16}
            options={createMapOptions}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          >
          </GoogleMap>
        </div>
        </div>
      </Drawer>
      <div className="accform-wrapper">
        <p className="main-title">Robots list({data.length})</p> 
          {visible?
            <Button
              htmlType="submit"className="robot_rent_button" onClick={Activate}
            >
              Activate
            </Button>:null}
          {visible?
            <Button
              htmlType="submit"className="update_btn space_btn" onClick={Transfer}
            >
              Transfer
            </Button>:null}
            <CSVLink
          data={data}
          filename={`robots-by-Nexmow-${moment(new Date()).format('YYYY-MM-DD')}.csv`}
          className="update_btn space_btn exportcsv__button2"
          target="_blank"
          asyncOnClick={true}
          headers = {[
            { label: "PSN", key: "psn" },
            { label: "Model", key: "line" },
            { label: "Id", key: "id" },
            { label: "Name", key: "model" },
            { label: "Warranty", key: "warranty" },
            { label: "Subscription", key: "subscription" },
            { label: "SW Ver", key: "swVer" },
            { label: "Company", key: "company" },
            { label: "IMSI", key: "imsi" },
            { label: "Last online", key: "lastOnline" },
            { label: "Usage miles", key: "miles" },
            { label: "Usage time", key: "runningTime" },
            { label: "Lift belt liftime", key: "liftTimes" },
            (isL0L1())?{ label: "Repair Log", key: "memo.text" }:{lable:"",key:""}
          ]}
          onClick={(event, done) => {
            if(data.length ===0){
              alert("Data downloading or no data, try again later");
              done(false);
            }
            done(true);
          }}
        >
        Export CSV
      </CSVLink>
      </div>
      <hr className="hr-line" />
      
      <div style={{ padding: '20px' }}>
      <Table 
        className="generaltable"
        showHeader = "true"
        title = {(currentPageData) => {
          return `Number of filtered data: ${currentDataSource.length}`;
          //return `<p className="main-title">Robots list()</p>`
        }}
        size="large" 
        loading={isLoading}
        onRow={(record, rowIndex) => {
              return {
                onDoubleClick: (event) => {setLocalRobot(record);setLocation({lat:parseFloat(record.loc[0]),lng:parseFloat(record.loc[1])});showDrawer();}, // click row
              };
        }}
        onChange={(pagination, filters, sorter, extra) => setCurrentDataSource(extra.currentDataSource)}
        dataSource={data} 
        columns={isL0L1()?columns:columns.slice(0, -1)} 
        pagination={{ defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['25', '50', '100', '500']}}
        //scroll={{ x: 350, y: 500 }}
        />
      </div>  
    </div>
    
    )
};

const RobotDetailItem = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showAlert() {
      alert("Hello from Child Component")
    },
    reset(){
      setMemoText(robot.memo.text);
      //console.log("reset");
    },
    value: memoText
  }))

  let robot = props.props.robot;
  let userInfo = props.props.userInfo;
  //console.log("ReportDetailItem report:", props);
  const [memoText, setMemoText] = useState(robot.memo.text);
  const { TextArea } = Input;

  const isL0L1 = () => {
    return (userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1");
  }

  const onTextChange = (event) => {
    setMemoText(event.target.value);
    //setLocalReport({...report, memo:{...memo, text:event.target.value.trim()}});
    //console.log("onTextChange:", memoText);
  }

  useEffect(() => {
    setMemoText(robot.memo.text);
    //console.log("useEffect:", report.memo.text);
  }, [robot]);

  return (
    <>
    { !isL0L1()?null:
      <div>
      <p>{<span style={{color:'grey'}}>Repair Log:</span>}{<span style={{paddingLeft:'10px'}}></span>}</p>
      <TextArea
            showCount
            maxLength={1000}
            style={{
              height: 120,
              resize: 'none',
            }}
            name="memo"
            value = {memoText}
            //defaultValue = {report.memo.text}//{_.has(report,"memo.txt")?report.memo.text:""}
            onChange={onTextChange}
            placeholder="Please add repair log in following format. (time/who/how)"
          />
      </div>   
    } 
    </>
  );
})