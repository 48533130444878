import React, { useState } from "react";
import { Form, Input, Button, Checkbox } from 'antd';
import { useHistory, Link } from "react-router-dom";
import Lottie from 'react-lottie';
import animationData from '../../animations/sending_anim.json';

export default function RegisterFeedbackCard() {
    const [form] = Form.useForm();
    const history = useHistory();
    const RegisterCheck = () => {
      history.push("/registercheck");
    };
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  return (
    <div className="main-layout">
      <div className="addcustomer-wrapper">
        <img src="/images/ad_2_img.png" className="ad_1"/>
        <img src="/images/back_btn.png" className="back_btn" onClick={RegisterCheck}/>
      </div>
      <div className="addcustomer-form">
        <div className="nexmowlogo">
          <img src="/images/brand_logo_img.png" />
        </div>
        <p className="customerckeck-title">Thanks for the registration!</p> 
        <p className="register_word">Your request has been submitted. This may take a few days. 
          Please check your email box to find your account and password number. 
          If you still not receive the email, please try again.</p>
          <Lottie options={defaultOptions} height={400} width={400}/>
        <Button
            htmlType="submit"
            className="customersent_btn"
        >
            Got it!
        </Button>
      </div>
    </div>
  );
};