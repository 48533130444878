import { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import firebase from 'firebase/app'
import ModalImage from "react-modal-image";
import {storageRef} from '../../Api/index.js'

export default function ErrorItem({ error }) {
    //const { dispatch } = useContext(StoreContext);
    const [dataToShow, setData] = useState([]);
    const [downloadUrl, setURL] = useState("");
    
    //console.log("ErrorItem => ", error);
    //let downloadUrl = "";
    useEffect(() => {
        // console.log("useEffect1 => ", error);
        // console.log("useEffect2 => ", error.payload);
        // console.log("useEffect3 => ", error.payload.err_event);
        // console.log("useEffect4 => ", error.payload.err_event.photo2cloud);
        //console.log("useEffect5 => ", error.payload.err_event.photo2cloud[0]);
        if(!error.payload || !error.payload.err_event){
            console.log("No URL => ", error.notification_slug);
            return;
        }
        storageRef.child(error.payload.err_event.photo2cloud[0]).getDownloadURL()
        .then((url) => {
            //console.log("url => ", url);
            setURL(url);
        });
    }, []);

    return (
        <div className="list_item">
            <p className="account_job">{(error.notification_slug.startsWith('RL') || error.notification_type !== "dev_events")?"Info":"Warning"}</p>
            <p className="report_time">{error.time}</p>
            <p className="account_job">{error.notification_slug}</p>
            <p className="account_job">{error.notification_subject}</p>
            {(downloadUrl!=="")?(<ModalImage
                medium={downloadUrl}
                alt="Image"
                />):(<p className="account_job">No Image</p>)}
        </div>
    );
}