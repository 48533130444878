import { Layout } from "antd";
import * as QueryString from "query-string";
import RegisterCard from "../../components/register/RegisterCard";
const { Content } = Layout;

function Register(props) {
  console.log(QueryString.parse(props.location.search))
  const { redirect } = QueryString.parse(props.location.search);
  console.log(redirect)
  return (
    <Layout className="container main-layout">
      <Layout className="bg-gray main-area">
        <Content className="layout-content">
          <RegisterCard redirect={redirect}/>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Register;
