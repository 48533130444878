import { Layout } from 'antd';
import NavBar from "../../components/NavBar";
import AccountItemDetail from "../../components/account/AccountItemDetail";

const { Content } = Layout;

function AccountItem() {
   return (
      <Layout className="container main-layout">
         <NavBar />
         <Layout className="bg-gray">  
            <Content className="layout-content">
               <AccountItemDetail/>
            </Content>
         </Layout>
      </Layout>
   );
}

export default AccountItem;
