import { Layout } from "antd";
import AddAccountCard from "../../components/account/AddAccountCard";
const { Content } = Layout;

function AddAccount(props) {
  return (
    <Layout className="container">
      <Content>
        <AddAccountCard />
      </Content>
    </Layout>
  );
}

export default AddAccount;
