import { Layout } from 'antd';
import { useContext , useEffect} from "react"; 
import NavBar from "../../components/NavBar";
import CustomerItemDetail from "../../components/customer/CustomerItemDetail";
import { StoreContext } from "../../store";
import { setCustomerDetail } from "../../actions";

const { Content } = Layout;

function CustomerItem({ match }) {
   const { dispatch } = useContext(StoreContext);

   // useEffect(() => {
   //    setCustomerDetail(dispatch, match.params.productId, 0, match.params.category)
   // }, [])
   return (
      <Layout className="container main-layout">
         <NavBar />
         <Layout className="bg-gray">  
            <Content className="layout-content">
               <CustomerItemDetail/>
            </Content>
         </Layout>
      </Layout>
   );
}

export default CustomerItem;
