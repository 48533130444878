import { Layout } from 'antd';
import NavBar from "../../components/NavBar";
import ActivateRobotDetail from "../../components/robot/ActivateRobotDetail";

const { Content } = Layout;

function ActivateRobot({ match }) {
   return (
      <Layout className="container main-layout">
         <NavBar />
         <Layout className="bg-gray">
            <Content className="layout-content">
               <ActivateRobotDetail />
            </Content>
         </Layout>
      </Layout>
   );
}

export default ActivateRobot;