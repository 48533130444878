import React, { useContext, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, KeyOutlined, WarningOutlined } from '@ant-design/icons';
import { checkLogin, loginToFirebase, rememberLoginUser } from '../actions'
import { StoreContext } from "../store"
import firebase from 'firebase/app'
import 'firebase/functions';
import {getProject, resetPassword} from "../Api/index.js"
import { logoutFromFirebase} from "../actions/index.js"
import {
  checkUserRole,
} from "../Api";

const LoginCard = ({redirect},props) => {
  const { state:{ userSignin: { userInfo, loading, error, remember } }, dispatch } = useContext(StoreContext);
  const [form] = Form.useForm();
  const history = useHistory();
  //const { state: {...all}, dispatch2 } = useContext(StoreContext);
  let packageJson = require('../../package.json');
  //console.log('LoginCard enter: ', all);
  
  const forgetPassword = (values) => {
    console.log("forgetPassword");
    alert("Feature not ready, coming soon!");
    return;

    let login = form.getFieldsValue();
    var emailRegxp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if(!emailRegxp.test(login.email)){
      alert("Please check your input, the email address is invalid!");
      return
    }
    if(window.confirm(`Are you sure to reset password for "${login.email}"?`)){
      resetPassword(login.email);
      return;
    }
  }
 
  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    const login = await loginToFirebase(dispatch, values);
    //var role = await checkUserRole(login.user["user"]["uid"]);
    console.log('user ->', login.user);
    console.log('user token ->', login.token.token);
    //console.log('user role->', role);

    if(!login.token.claims.admin){
      alert("Sorry, you don't have the admin priviledge to login!");
      logoutFromFirebase(dispatch);
      return;
    }
    history.push("/robotmag"); 
  };

  const onChange = e => {
    rememberLoginUser(dispatch, e.target.checked);
  }

  useEffect(() => {    
    if( userInfo && checkLogin(dispatch) ) history.push(redirect);
  }, [ userInfo ]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="main-layout">
      <div className="login-form-wrapper">
        <img src="/images/ad_1_img.png" className="ad_1"/>
      </div>
      <div className="login-form">
        <img src="/images/company logo.png" className="logo"/>
        <p className="site_banner">Nexmow Portal</p>
        <Form
          name="normal_login"
          className="login-form_list"
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
        <Form.Item
          name="email"
          className="login-email"
          rules={[
            {
              required: true,
            },
          ]}
          hasFeedback
        >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Account"
          style={{width:400,color:"#000"}}
        />
        </Form.Item>
        <Form.Item
          name="password"
          className="login-password"
          rules={[
            {
              required: true,
              color:'#fff500',
              message: "Incorrect password,please try again.",
            }
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<KeyOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            style={{width:400,color:"#000"}}
          />
        </Form.Item>
        <Form.Item className="login-enter">
          
            <Button
              htmlType="submit"
              className="login-form__button"
             
            >
              Login
            </Button>
      
          {error === "" ? (
            <></>
          ) : (
            <div className="login-form__error-wrap">
              <h3 className="login-form__error-title">
                <WarningOutlined />
                {"  "}There was a problem...
              </h3>
              <p className="login-form__error-message">{error}</p>
            </div>
          )}
        </Form.Item>
        <Form.Item>
          <Form.Item
            className="remember"
            noStyle
          >
            <Checkbox className="remember" onChange={onChange} checked={remember}>Remember me</Checkbox>
          </Form.Item>
          <Form.Item className="login-form__forgot">
            <Link  to={""} onClick={forgetPassword}>
              Forgot password
            </Link>
          </Form.Item>
        </Form.Item>
        </Form>
        <div>
          <div className="login_info">Please read the Privacy policy and terms 
          Copyright by URSROBOT Inc.</div>
          <div className="login_info">Cloud: {getProject()}</div>
          <div className="login_info">Ver: {packageJson.version}</div>
        </div>
      </div>
    </div>
  );
};
export default LoginCard;