/*
 * @Author: henry.chen henry.chen@ursrobot.ai
 * @Date: 2024-01-31 17:01:41
 * @LastEditors: henry.chen henry.chen@ursrobot.ai
 * @LastEditTime: 2024-04-25 20:28:06
 * @FilePath: /dealer_portal/dealer_portal/src/components/map/MapItem.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import { setMapDetail } from "../../actions";
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Radio, Space, Divider } from 'antd';
import { concave_CCPP_funcs } from "../../Api"

export default function MapItem(props) {
    const { dispatch } = useContext(StoreContext);
    const { state:{ userSignin: { userInfo } } } = useContext(StoreContext);

    const map = props.map.map;
    const callback = props.map.callback;
    const [checkedState, setData] = useState(map.lock);
    const [isLoading, setIsLoading] = useState(false);

    const isL0L1 = () => {
      return (userInfo[1].claims.role === "L0" || userInfo[1].claims.role === "L1" || "monarch@nexmow.com" === userInfo[1].claims.email || "drgolf@nexmow.com" === userInfo[1].claims.email || "stanford@nexmow.com" === userInfo[1].claims.email);
    }

    const handleOnChange = (event) => {
      console.log("MapItem handleOnChange => ", event.target.checked);
      setData(event.target.checked);
      callback(event);
    };

    const handleOnClick = async () => {
      console.log("MapItem handleOnClick => ", map);

      var answer = window.confirm("It may need 10 minutes to generate the map, proceed?");
      if(!answer){
        return;
      }
      setIsLoading(true);
      try{
        const result = await concave_CCPP_funcs(map.id);
        console.log("result mapItem:", result);
        setIsLoading(false);
        // if(result.data[0].result.startsWith('Successfully')){
        //   alert("Path successfully generated!");
        // }else{
        //   alert("Failed to generate!");
        // }
        alert(result);
      } catch (e){
        setIsLoading(false);
        alert("Failed to generate!", e);
      }

    }

    return (
        // <Link to={`/mapitem/${map.name}`}
        //   onClick={() => {
        //     setMapDetail(dispatch, map);
        //   }}
        // > 
          <div className="list_item">
            {/* <p className="account_company">{map.company}</p>  */}
            <p className="acc_name">{map.name}</p> 
            <p className="map_mowing">{map.travel_time}</p> 
            <p className="map_mowing">{map.zone_area.toFixed(5)}/{(map.zone_area*43560).toFixed(1)}</p> 
            <p className="map_homebase map_detail">{map.home_base}</p> 
            <p className="map_email">{map.acc_name}</p> 
            <input
                className="robot_select"
                type="checkbox"
                id={`custom-checkbox-${map.id}`}
                name={map.id}
                value={map.id}
                checked={checkedState}
                onChange={handleOnChange}
            />

            { !isL0L1()?null:
            <Button
            className="map_mowing" size="small" onClick={() => console.log(handleOnClick())} loading={isLoading}
            >
              Generate
            </Button>
            }
          </div>
        // </Link>
    );
}
