import { useContext, useState, useEffect } from "react";
import { Card, Checkbox } from "antd"
import { Link } from 'react-router-dom';
import { StoreContext } from "../../store"
import { setCustomerDetail } from "../../actions";
import firebase from 'firebase/app'

export default function TransferRobotItem(props) {
    //const { dispatch } = useContext(StoreContext);
    //const { state:{ userSignin: { userInfo, loading, error, remember } }, dispatch } = useContext(StoreContext);
    //console.log("TransferRobotItem => props:", props);
    const robot = props.robot.rob;
    const callback = props.robot.callback;

    const [checkedState, setData] = useState(false);
    const [robotInUse, setRobotInUse] = useState(false);
    
    //var checkedState = false;
    /*
    const [checkedState, setCheckedState] = useState(
        new Array(robot.length).fill(false)
      );
    
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };*/
    const handleOnChange = async (event) => {
        
        //console.log("TransferRobotItem handleOnChange => ", event.target.checked);
        //console.log("TransferRobotItem handleOnChange => ", robot);
        let eventBK = {target:{name: event.target.name, checked:event.target.checked}};

        let robotInUse = false;
        const docRef = firebase.firestore().collection('users').doc(robot.uid).collection("tasks");
        //console.log("set data => ", eventBK);
        var querySnapshot = await docRef.get();
        //console.log("set data => ", eventBK);
        try{
            const robots = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                let robs = (doc.data().robots_id)?doc.data().robots_id:[];
                robots.push(...robs);
            });
            robotInUse = (robots.find(element => element === robot.id))?true:false;
            //console.log(" robotInUse=> ", robotInUse, robot.id, robots);
        } catch(error){
            console.log("Error getting documents: ", error);
        }
        if(robotInUse === true){
            alert(`Robot ${robot.model} is in use, could not be transferred!`);
            setData(false);
            setRobotInUse(robotInUse);
            return;
        }
        //console.log("set data => ", eventBK.target.checked);
        setData(eventBK.target.checked);
        callback(eventBK);
        
    };
    //console.log("TransferRobotItem => ", robot);
    //console.log("TransferRobotItem => ", userInfo);
    
    useEffect(() => {
        /* const docRef = firebase.firestore().collection('robots').where("company", "==", userInfo[1].claims.company)
          docRef.get().then((querySnapshot) => {
            const robot = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                robot.push({name : doc.data().model,id : doc.data().updated_time})
            });
            
          })
          .catch((error) => {
              console.log("Error getting documents: ", error);
          });
          setData(robot); */
    }, []);

    return (
        <div className="list_item ">
            <p className="robot_psn">{robot.psn}</p> 
            <p className="robot_name">{robot.model}</p> 
            <p className="robot_company">{robot.company}</p> 
            <input
                className="robot_select"
                type="checkbox"
                id={`custom-checkbox-${robot.id}`}
                name={robot.id}
                value={robot.id}
                checked={checkedState}
                
                onChange={handleOnChange}
            />
        </div>
    );
}
